import {BaseProduct} from "./BaseProduct"
import {ScalarProductBuilder} from "../../productbuilders/ScalarProductBuilder"

export class BaseScalarProduct extends BaseProduct{
    field = "scalar"

    getBuilder(file) {
        return new ScalarProductBuilder(file, this.bilinearInterpolateScalar);
    }

    bilinearInterpolateScalar(x, y, g00, g10, g01, g11) {
        var rx = (1 - x);
        var ry = (1 - y);
        return g00 * rx * ry + g10 * x * ry + g01 * rx * y + g11 * x * y;
    }
}