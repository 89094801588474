/*
Used as a low level container for all items in given group. For example: Total Wind, Kelvin Wind, MRG Wind.
It is represented as SplitButton in menu if items property contains more than 1 item or SelectButton if items property contains only one item

name - name of the group. For example: Wind, Rainfall 
items - single items in given group. For example: Total Wind (for Wind group), Kelvin Wind (for Wind group), Total Rainfall (for Rainfall group), Kelvin Rainfall (for Rainfall group)
subGroups - name of the sub groups (aggregates). Used to determine data source
*/
export class GroupItem {
    name = null;
    items = null;
    subGroups = null;

    constructor (name, items, subGroups){
        this.name = name;
        this.items = items;
        this.subGroups = subGroups;
    }
}