import _ from 'lodash'
import {geoPath, geoGraticule} from "d3-geo";
import {Micro} from "@spectralweather/common/utils/Micro"
import {GlobeManipulator} from './GlobeManipulator'

/**
 * Returns a globe object with standard behavior. At least the newProjection method must be overridden to
 * be functional.
 */
export class Globe{

    /**
     * This globe's current D3 projection.
     */
    projection = null;
    /**
     * the set of helpfull functions.
     */
    Micro = null;

    constructor(micro){
        this.Micro = micro || new Micro()
    }


    /**
    * @param view the size of the view as {width:, height:}.
    * @returns {Object} a new D3 projection of this globe appropriate for the specified view port.
    */
    newProjection(view) {
        throw new Error("method must be overridden");
    }

    /**
    * @param view the size of the view as {width:, height:}.
    * @returns {{x: Number, y: Number, xMax: Number, yMax: Number, width: Number, height: Number}}
    *          the bounds of the current projection clamped to the specified view.
    */
    bounds(view) {
        return this.clampedBounds(geoPath().projection(this.projection).bounds({type: "Sphere"}), view);
    }

    /**
     * @param bounds the projection bounds: [[x0, y0], [x1, y1]]
     * @param view the view bounds {width:, height:}
     * @returns {Object} the projection bounds clamped to the specified view.
     */
    clampedBounds(bounds, view) {
        var upperLeft = bounds[0];
        var lowerRight = bounds[1];
        var x = Math.max(Math.floor(this.ensureNumber(upperLeft[0], 0)), 0);
        var y = Math.max(Math.floor(this.ensureNumber(upperLeft[1], 0)), 0);
        var xMax = Math.min(Math.ceil(this.ensureNumber(lowerRight[0], view.width)), view.width - 1);
        var yMax = Math.min(Math.ceil(this.ensureNumber(lowerRight[1], view.height)), view.height - 1);
        return {x: x, y: y, xMax: xMax, yMax: yMax, width: xMax - x + 1, height: yMax - y + 1};
    }    

    ensureNumber(num, fallback) {
        return _.isFinite(num) || num === Infinity || num === -Infinity ? num : fallback;
    }

    /**
     * @param view the size of the view as {width:, height:}.
     * @returns {Number} the projection scale at which the entire globe fits within the specified view.
     */
    fit(view) {
        var defaultProjection = this.newProjection(view);
        var bounds = geoPath().projection(defaultProjection).bounds({type: "Sphere"});
        var hScale = (bounds[1][0] - bounds[0][0]) / defaultProjection.scale();
        var vScale = (bounds[1][1] - bounds[0][1]) / defaultProjection.scale();
        return Math.min(view.width / hScale, view.height / vScale) * 0.9;
    }

    /**
     * @param view the size of the view as {width:, height:}.
     * @returns {Array} the projection transform at which the globe is centered within the specified view.
     */
    center(view) {
        return [view.width / 2, view.height / 2];
    }

    /**
     * @returns {Array} the range at which this globe can be zoomed.
     */
    scaleExtent() {
        return [25, 3000];
    }

    /**
     * Returns the current orientation of this globe as a string. If the arguments are specified,
     * mutates this globe to match the specified orientation string, usually in the form "lat,lon,scale".
     *
     * @param [o] the orientation string
     * @param [view] the size of the view as {width:, height:}.
     */
    orientation(o, view) {
        var projection = this.projection, rotate = projection.rotate();
        if (this.Micro.isValue(o)) {
            var parts = o.split(","), λ = +parts[0], φ = +parts[1], scale = +parts[2];
            var extent = this.scaleExtent();
            projection.rotate(_.isFinite(λ) && _.isFinite(φ) ?
                [-λ, -φ, rotate[2]] :
                this.newProjection(view).rotate());
            projection.scale(_.isFinite(scale) ? this.Micro.clamp(scale, extent[0], extent[1]) : this.fit(view));
            projection.translate(this.center(view));
            return this;
        }
        return [(-rotate[0]).toFixed(2), (-rotate[1]).toFixed(2), Math.round(projection.scale())].join(",");
    }

    /**
     * Returns an object that mutates this globe's current projection during a drag/zoom operation.
     * Each drag/zoom event invokes the move() method, and when the move is complete, the end() method
     * is invoked.
     *
     * @param startMouse starting mouse position.
     * @param startScale starting scale.
     */
    manipulator(startMouse, startScale) {
        return new GlobeManipulator(this.projection, startMouse, startScale);
    }

    /**
     * @returns {Array} the transform to apply, if any, to orient this globe to the specified coordinates.
     */
    locate(coord) {
        return null;
    }

    /**
     * Draws a polygon on the specified context of this globe's boundary.
     * @param context a Canvas element's 2d context.
     * @returns the context
     */
    defineMask(context) {
        geoPath().projection(this.projection).context(context)({type: "Sphere"});
        return context;
    }

    /**
     * Appends the SVG elements that render this globe.
     * @param mapSvg the primary map SVG container.
     * @param foregroundSvg the foreground SVG container.
     * @param contoursSvg the contours SVG container.
     */
    defineMap(mapSvg, foregroundSvg, contoursSvg) {
        var path = geoPath().projection(this.projection);
        var defs = mapSvg.append("defs");
        defs.append("path")
            .attr("id", "sphere")
            .datum({type: "Sphere"})
            .attr("d", path);
        mapSvg.append("use")
            .attr("xlink:href", "#sphere")
            .attr("class", "background-sphere");
        mapSvg.append("path")
            .attr("class", "graticule")
            .datum(geoGraticule())
            .attr("d", path);
        mapSvg.append("path")
            .attr("class", "hemisphere")
            .datum(geoGraticule().minorStep([0, 90]).majorStep([0, 90]))
            .attr("d", path);
        mapSvg.append("path")
            .attr("class", "coastline");
        mapSvg.append("path")
            .attr("class", "lakes");
        foregroundSvg.append("use")
            .attr("xlink:href", "#sphere")
            .attr("class", "foreground-sphere");
        contoursSvg.append("use")
            .attr("xlink:href", "#sphere")
            .attr("class", "foreground-sphere");
    }
}
    