import _ from 'lodash'
import {GlobeMask} from '../../utils/GlobeMask'


export class ProductDataField {
    IsCanceled = false;
    Columns = [];
    Micro = null
    Mask = null;
    Bounds = null;
    Overlay = null;
    Projection = null;
    HasDistinctOverlay = false;
    ScalarInterpolation = null;
    VectorInterpolation = null;
    ParticlesScale = null;
    Scale = null;
    X = 0;
    NULL_WIND_VECTOR = [NaN, NaN, null];  // singleton for undefined location outside the vector field [u, v, mag]
    TRANSPARENT_BLACK = [0, 0, 0, 0];     // singleton 0 rgba
    OVERLAY_ALPHA = Math.floor(0.4*255);  // overlay transparency (on scale [0, 255])
    HOLE_VECTOR = [NaN, NaN, null];       // singleton that signifies a hole in the vector field
    MAX_TASK_TIME = 100;                  // amount of time before a task yields control (millis)
    MIN_SLEEP_TIME = 25;                  // amount of time a task waits before resuming (millis)

    constructor (micro,globe, vectorDataBuilder, scalarDataBuilder, particlesScale, scale){
        this.Micro = micro;
        this.Mask = new GlobeMask(globe);
        this.Bounds = globe.bounds(this.Micro.View);
        this.X = this.Bounds.x;
        this.Overlay = this.Mask.ImageData;
        this.Projection = globe.projection;
        this.HasDistinctOverlay =  vectorDataBuilder !== scalarDataBuilder;
        this.ScalarInterpolation = scalarDataBuilder.interpolate.bind(scalarDataBuilder);
        this.VectorInterpolation = vectorDataBuilder.interpolate.bind(vectorDataBuilder);
        this.ParticlesScale = this.Bounds.height * particlesScale / 600000;
        this.Scale = scale;
    }

    stopInterpolation(){
        this.IsCanceled = true;
    }

    interpolateField() {
        try {
            if (this.IsCanceled) {
                return null;
            }
            //var start = Date.now();
            while (this.X < this.Bounds.xMax) {
                this.interpolateColumn(this.X);
                this.X += 2;
                // if ((Date.now() - start) > this.MAX_TASK_TIME) {
                //     // Interpolation is taking too long. Schedule the next batch for later and yield.
                //     setTimeout(() => { this.interpolateField() }, this.MIN_SLEEP_TIME);
                //     return;
                // }
            }
            return this;
        }
        catch (e) {
            console.log(e);
            return null;
        }
    }

    /**
    * fill Columns array with interpolated values 
    *    
    */   
    interpolateColumn(x) {
        var column = [];
        var point = [];
        for (var y = this.Bounds.y; y <= this.Bounds.yMax; y += 2) {
            if (this.Mask.isVisible(x, y)) {
                point[0] = x; point[1] = y;
                var coord = this.Projection.invert(point);
                var color = this.TRANSPARENT_BLACK;
                var wind = null;
                if (coord) {
                    var λ = coord[0], φ = coord[1];
                    if (isFinite(λ)) {
                        wind = this.VectorInterpolation(λ, φ);
                        var scalar = null;
                        if (wind) {
                            wind = this.distort(this.Projection, λ, φ, x, y, this.ParticlesScale, wind);
                            scalar = wind[2];
                        }
                        if (this.HasDistinctOverlay) {
                            scalar = this.ScalarInterpolation(λ, φ);
                            //Its SCALAR interpolation because its for HEATMAP data but it still could be vector data
                            if(Array.isArray(scalar)){
                                scalar = scalar[2];
                            }
                        }
                        if (this.Micro.isValue(scalar)) {
                            color = this.Scale.gradient(scalar, this.OVERLAY_ALPHA);
                        }
                    }
                }
                column[y+1] = column[y] = wind || this.HOLE_VECTOR;
                this.Mask.set(x, y, color).set(x+1, y, color).set(x, y+1, color).set(x+1, y+1, color);
            }
        }
        this.Columns[x+1] = this.Columns[x] = column;
    }
    
    /**
    * Calculate distortion of the wind vector caused by the shape of the projection at point (x, y). The wind
    * vector is modified in place and returned by this function.
    */
    distort(projection, λ, φ, x, y, scale, wind) {
        const d = this.Micro.distortion(projection, λ, φ, x, y);   
        let u = wind[0];
        let v = wind[1];
        
        wind[0] = (d[0] * u + d[2] * v) * scale;
        wind[1] = (d[1] * u + d[3] * v) * scale;
        return wind;
    }


    /**
     * @returns {Array} wind vector [u, v, magnitude] at the point (x, y), or [NaN, NaN, null] if wind
     *          is undefined at that point.
     */
    field(x, y) {
        var column = this.Columns[Math.round(x)];
        return (column && column[Math.round(y)]) || this.NULL_WIND_VECTOR;
    }

    /**
     * @returns {boolean} true if the field is valid at the point (x, y)
     */
    isDefined(x, y) {
        return this.field(x, y)[2] !== null;
    }
      
    /**
     * @returns {boolean} true if the point (x, y) lies inside the outer boundary of the vector field, even if
     *          the vector field has a hole (is undefined) at that point, such as at an island in a field of
     *          ocean currents.
     */
    isInsideBoundary(x, y) {
        return this.field(x, y) !== this.NULL_WIND_VECTOR;
    } 
    
    // Frees the massive "columns" array for GC. Without this, the array is leaked (in Chrome) each time a new
    // field is interpolated because the field closure's context is leaked, for reasons that defy explanation.
    release(){
        this.Columns = [];
    }    

    randomize(o){  // UNDONE: this method is terrible
        var x, y;
        var safetyNet = 0;
        do {
            x = Math.round(_.random(this.Bounds.x, this.Bounds.xMax));
            y = Math.round(_.random(this.Bounds.y, this.Bounds.yMax));
        } while (!this.isDefined(x, y) && safetyNet++ < 30);
        o.x = x;
        o.y = y;
        return o;
    };    
}