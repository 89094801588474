export function dateToString(dateVal) {
  return (
    dateVal.getFullYear() +
    "-" +
    String(dateVal.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(dateVal.getDate()).padStart(2, "0") +
    "T" +
    String(dateVal.getHours()).padStart(2, "0") +
    ":00:00"
  );
}

export function dateNoTimeToString(dateVal) {
  return (
    dateVal.getFullYear() +
    "-" +
    String(dateVal.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(dateVal.getDate()).padStart(2, "0") +
    "T" +
    "00:00:00"
  );
}
