import {BaseScalarProduct} from "./BaseScalarProduct"

export class BaseSeaSurfaceHeightProduct extends BaseScalarProduct{

    constructor(micro, attr){
        super(micro);
        this.setProductAttributes(attr.date, attr.dataType, attr.level);

        this.type = "SSH";
        this.description = this.localize({
            name: {en: "Sea Surface Height"},
        });
        this.endpoint = this.apiEndpoint("scalar", 1013);
        this.units = [
            {label: "cm", conversion: function(x) { return x ; }, precision: 1},
        ];
    }
}