export const InitialState = {
    globalTime: new Date('2019-12-30T00:00:00'),
    globalPressure: "850",
}

export default function GlobalDataReducer(state = InitialState, action) {
    switch (action.type) {
        case 'global/incrementDate': {
            let timeSpanIncrement = parseInt(action.value) || 8
            const globalTime = new Date(state.globalTime);
            globalTime.setHours(globalTime.getHours() + timeSpanIncrement);
            return {
                ...state,
                globalTime: globalTime
            }
        }
        case 'global/decrementDate': {
            let timeSpanDecrement = parseInt(action.value) || 8
            const globalTime = new Date(state.globalTime);
            globalTime.setHours(globalTime.getHours() - timeSpanDecrement);
            return {
                ...state,
                globalTime: globalTime
            }
        }
        case 'global/chooseDate': {
            return {
                ...state,
                globalTime: new Date(action.value)
            }
        }
        case 'global/setPressure': {
            return {
                ...state,
                globalPressure: action.value
            }
        }
        default:
            return state
    }
}
