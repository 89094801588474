import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import ColumnLabel from "../Menu/ColumnLabel";
import styles from "./SideMenuStyles";

export default function SettingsRow(props) {
  return (
    <Grid container item xs={12}>
      <Grid item xs={4}>
        <ColumnLabel
          autoColumn={true}
          menuLabel={
            props.menuLabel + (props.units ? " (" + props.units + "):" : ":")
          }
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          sx={styles.menuTextField}
          value={props.settingsVal}
          onChange={props.handleChange}
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: props.endAdornment,
          }}
        />
      </Grid>
    </Grid>
  );
}
