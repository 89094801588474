import _ from 'lodash'
import {Micro} from "@spectralweather/common/utils/Micro"


export class BaseProduct{
    API_PATH = "https://spectralweather.ucsd.edu/data";
    description = "";
    endpoint = null;
    productAttributes = {
        date: null,
        dataType: null,
        level: null,
    }
    µ = null;

    constructor(micro){
        this.µ = micro || new Micro();
    }

    navigate(step){
        return this.gfsStep(this.date, step);
    }

    getBuilder(file){
        throw new Error("Not Implemented");
    }

    /**
     * @param {String} fieldType
     * @param {String?} level
     * @returns {String}
     */
    apiEndpoint(fieldType, level) {
        var date = [this.productAttributes.date.getFullYear(), this.productAttributes.date.getMonth() + 1, this.productAttributes.date.getDate()].join("-")
        var hour = String(this.productAttributes.date.getHours()).padStart(2, '0') + "00";

        var dateTime = this.productAttributes.date === "current" ? "current" : date + "/" + hour;
        return [this.API_PATH, fieldType, dateTime, this.productAttributes.dataType, level].join("/");
    }

    setProductAttributes(date, dataType, level){
        this.productAttributes.date = date;
        this.productAttributes.dataType = dataType;
        this.productAttributes.level = level;
    }

    gfsDate(attrDate) {
        if (attrDate === "current") {
            // Construct the date from the current time, rounding down to the nearest three-hour block.
            var now = new Date(Date.now()), hour = Math.floor(now.getUTCHours() / 3);
            return new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), hour));
        }
        var parts = attrDate.split("-");
        return new Date(parts[0], parts[1] - 1, +parts[2], + parts[3].substr(0, 2));
    }

    /**
     * Returns a date for the chronologically next or previous GFS data layer. How far forward or backward in time
     * to jump is determined by the step. Steps of ±1 move in 3-hour jumps, and steps of ±10 move in 24-hour jumps.
     */
    gfsStep(date, step) {
        var offset = (step > 1 ? 8 : step < -1 ? -8 : step) * 3, adjusted = new Date(date);
        adjusted.setHours(adjusted.getHours() + offset);
        return adjusted;
    }

    /**
     * Returns a function f(langCode) that, given table:
     *     {foo: {en: "A", ja: "あ"}, bar: {en: "I", ja: "い"}}
     * will return the following when called with "en":
     *     {foo: "A", bar: "I"}
     * or when called with "ja":
     *     {foo: "あ", bar: "い"}
     */
    localize(table) {
        return function(langCode) {
            var result = {};
            _.each(table, function(value, key) {
                result[key] = value[langCode] || value.en || value;
            });
            return result;
        }
    }    
}