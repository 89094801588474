import {BaseVertIntWaterVapourProduct} from "./baseProducts/BaseVertIntWaterVapourProduct"
import {getColor, getCurrentGradientOffsets} from '@spectralweather/common/services/PaletesService'

export class VertIntWaterVapourProduct extends BaseVertIntWaterVapourProduct{

    constructor(micro, attr, boundsMin, boundsMax, velocityScale, maxIntensity){
        super(micro, attr);

        this.scale ={
            getColors: () =>{return getCurrentGradientOffsets(this.productAttributes.dataType)},
            bounds: [boundsMin,boundsMax],
            gradient: function(v, a) {                
                return getColor(this.µ.scaleToZeroOne(this.scale.bounds[0], this.scale.bounds[1], this.µ.getValueFromRange(this.scale.bounds[0], this.scale.bounds[1],v)),
                                a, 
                                this.productAttributes.dataType
                                );
            }.bind(this)
        };
        this.particles ={
             velocityScale: velocityScale,
             maxIntensity: maxIntensity
        }
    }
}