import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import MenuSelectButton from '../MenuSelectButton'
import {makeStyles} from '@spectralweather/common/theme/theme';
import IconButton from '@spectralweather/common/components/Buttons/IconButton'


const useStyles = makeStyles()(theme => ({
  div: {
    padding: 0, 
    '&:hover': {
      color: theme.palette.sw.primaryText,
      backgroundColor: theme.palette.sw.secondaryBackground,
    },
  },
  selectedDiv: {
    padding: 0,
    outlineStyle: "solid",
    outlineColor: theme.palette.sw.primaryBorder,
    outlineWidth: "1px",
    '&:hover': {
      color: theme.palette.sw.primaryText,
      backgroundColor: theme.palette.sw.secondaryBackground,
    },
  },
  popper: {
    zIndex: 5,
  },
  paper: {
    border: '1px solid white',
    backgroundColor: theme.palette.sw.primaryBackground,
    borderRadius:0
  },
  menuList: {
    overflow: "auto",
    maxHeight: 250, 
  },
  whiteText: {
    color: theme.palette.sw.primaryText + " !important"
  }
}));

export default function SplitButton(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const {classes} = useStyles();



  let index = props.Items.findIndex(item => item.isSelected);
  index = index < 0 ? 0 : index;
  
  const [selectedIndex, setSelectedIndex] = React.useState(index);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    if(props.onSelectedItemChanged){
      props.onSelectedItemChanged(props.Items[index]);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  let selectedItem = props.Items[selectedIndex >= 0 && selectedIndex < props.Items.length ? selectedIndex : 0];
  let selectedItemStyle = selectedItem.isSelected? {outline: "1px solid white"} : null;
  let selectButtonStyle = selectedItem.isSelected? classes.whiteText : "";

  return (
    <React.Fragment>
        <div ref={anchorRef} style={selectedItemStyle} className={props.className ?  `${classes.div} ${props.className}` : classes.div}>
          <MenuSelectButton className={selectButtonStyle}
                        actionType={props.actionType}
                        onClick={props.onClick}
                        itemCode={selectedItem.code}>
                        {selectedItem.name}
          </MenuSelectButton>
          <IconButton
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}>
            arrow_drop_down
          </IconButton>
        </div>
        <Popper placement="top-end" className={classes.popper} open={open} anchorEl={anchorRef.current} disablePortal={true}>
              <Paper className={classes.paper} varian="outlined" >
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList className={classes.menuList} id="split-button-menu">
                    {props.Items.map((option, index) => (
                      <MenuItem key={option.name} onClick={(event) => handleMenuItemClick(event, index)}>
                        <MenuSelectButton actionType={props.actionType} itemCode={option.code} onClick={props.onClick}>{option.name}</MenuSelectButton>
                      </MenuItem>         
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
        </Popper>
    </React.Fragment>
  );
}