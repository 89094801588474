const STOP_HEIGHT = 20;

export const HALF_STOP_HEIGHT = STOP_HEIGHT / 2;

export const DEFAULT_STOP_REMOVAL_DROP = 50;

export const DEFAULT_WIDTH = 26;

export const DEFAULT_HEIGHT = 220;

export const DEFAULT_MAX_STOPS = 5;

export const DEFAULT_MIN_STOPS = 2;