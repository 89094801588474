export const InitialState = {
    vectorTime: new Date('2019-12-30T00:00:00'),
    vectorData: "wind",
    vectorPressure: "850"
}

export default function VectorDataReducer(state = InitialState, action) {
    switch (action.type) {
        case 'vector/setData': {
            return {
                ...state,
                vectorData: action.value
            }
        }
        case 'vector/incrementDate': {
            let timeSpanIncrement = parseInt(action.value) || 8
            const vectorTime = new Date(state.vectorTime);
            vectorTime.setHours(vectorTime.getHours() + timeSpanIncrement);
            return {
                ...state,
                vectorTime: vectorTime
            }
        }
        case 'vector/decrementDate': {
            let timeSpanDecrement = parseInt(action.value) || 8;
            const vectorTime = new Date(state.vectorTime);
            vectorTime.setHours(vectorTime.getHours() - timeSpanDecrement);
            return {
                ...state,
                vectorTime: vectorTime
            }
        }
        case 'vector/chooseDate': {
            return {
                ...state,
                vectorTime: new Date(action.value)
            }
        }
        case 'vector/setPressure': {
            return {
                ...state,
                vectorPressure: action.value
            }
        }
        default:
            return state
    }
}
