import {Subject, BehaviorSubject, combineLatest } from 'rxjs';


export const GlobeSubject = new BehaviorSubject();
export const MeshSubject = new BehaviorSubject();
export const ProductDataSubject  = new BehaviorSubject();
export const ProductDataFieldSubject  = new BehaviorSubject();
export const ProductDataDrawerSubject = new BehaviorSubject();
export const ContoursSettingsSubject = new BehaviorSubject()
/*
Observables where first item is Globe and second item is Mesh
*/
export const GlobeMeshCombinedObservable = combineLatest([MeshSubject, GlobeSubject]);

/*
Observables where first item is ProductData and second item is ContoursSettings
*/
export const ProductDataCountourSettingsCombinedObservable = combineLatest([ProductDataSubject, ContoursSettingsSubject]);

/*
Subject which takes string as an parameter to use it later for displaying various user indications
*/
export const ReportStatusSubject = new Subject();
/*
Subject which takes number as an parameter to use it later for displaying progress of data processing
*/
export const ReportProgressSubject = new Subject();
/*
Subject which takes bool as an parameter to define if data proessing should be canceled
*/
export const CancelSubject = new BehaviorSubject(false);
/*
Subject which takes bool as an parameter to define if map shuld be cleared
*/
export const ClearMapSubject = new BehaviorSubject(false);
/*
Subject which takes error as an parameter to use it later for displaying errors for users
*/
export const ReportErrorSubject = new Subject();
/*
Subject which takes color as an parameter
*/
export const SelectedColorStopSubject = new BehaviorSubject("#FFFFFF");
/*
Subject which takes color stop as an parameter
*/
export const SelectedStopColorGradientSubject = new BehaviorSubject({color:"#FFFFFF"});
/*
Subject which takes color stop as an parameter
*/
export const CurrentPaletteSubject = new BehaviorSubject(null);