import { Item } from "@spectralweather/common/models/menu/Item"
import { GroupItem } from "@spectralweather/common/models/menu/GroupItem"
import { GroupContainer } from "@spectralweather/common/models/menu/GroupContainer"


export const getContoursItems = (contoursEnabled) => {
    var ContourItems = new GroupContainer("Contours", "contours/setState",[
        new GroupItem("Contours", [new Item("Show", "show")]),
        new GroupItem("Contours", [new Item("Hide", "hide")])
    ]);

    for(var i = 0; i  < ContourItems.items.length; i++){
        for(var j = 0; j  < ContourItems.items[i].items.length; j++){
            ContourItems.items[i].items[j].isSelected = contoursEnabled === ContourItems.items[i].items[j].code
        }
    }
    return ContourItems;
}


export const getPressureItems = (selectedPressure, actionType) => {
    var PressureItems = new GroupContainer("Pressure", actionType,[
        new GroupItem("Pressure", [new Item("Surface", "1013")]),
        new GroupItem("Pressure", [new Item("850 hPa", "850")]),
        new GroupItem("Pressure", [new Item("500 hPa", "500")]),
        new GroupItem("Pressure", [new Item("200 hPa", "200")]),
    ]);

    for(var i = 0; i  < PressureItems.items.length; i++){
        for(var j = 0; j  < PressureItems.items[i].items.length; j++){
            PressureItems.items[i].items[j].isSelected = selectedPressure === PressureItems.items[i].items[j].code
        }
    }
    return PressureItems;
}

export const getVectorItems = (selectedVector) => {
    var vectorItems = new GroupContainer("Vector", "vector/setData",[
        new GroupItem("Wind", [
            new Item("Total Wind", "wind"), new Item("Kelvin Wind", "kelvin_wind"),
            new Item("MRG Wind", "mrg_wind"), new Item("MJO Wind", "mjo_wind"),
            new Item("ER Wind", "er_wind")
        ], ["ERA5"]),
        new GroupItem("Currents", [
            new Item("Total Currents", "surface_currents"),
            new Item("Kelvin Currents", "kelvin_surface_currents"),
            new Item("Rossby Currents", "rossby_surface_currents")
        ]),
        new GroupItem("VIWVF", [
            new Item("Total VIWVF", "viwvf"), new Item("Kelvin VIWVF", "kelvin_viwvf"),
            new Item("MRG VIWVF", "mrg_viwvf"), new Item("MJO VIWVF", "mjo_viwvf"),
            new Item("ER VIWVF", "er_viwvf")
        ])
    ]);
    for(var i = 0; i  < vectorItems.items.length; i++){
        for(var j = 0; j  < vectorItems.items[i].items.length; j++){
            vectorItems.items[i].items[j].isSelected = selectedVector === vectorItems.items[i].items[j].code
        }
    }
    return vectorItems;
}


export const getScalarItems = (selectedScalar) => {
    var scalarItems = new GroupContainer("Scalar", "scalar/setData",[
        new GroupItem("Default", [new Item("Default", "default")]),
        new GroupItem("Rainfall", [
            new Item("Total Rainfall", "rainfall"), new Item("Kelvin Rainfall", "kelvin_rainfall"),
            new Item("MRG Rainfall", "mrg_rainfall"), new Item("MJO Rainfall", "mjo_rainfall"),
            new Item("ER Rainfall", "er_rainfall")
        ]),
        new GroupItem("Omiolr", [new Item("OMIOLR", "omiolr")]),
        new GroupItem("SSH", [
            new Item("Total SSH", "sea_surface_heigh"), new Item("Kelvin SSH", "kelvin_sea_surface_heigh"),
            new Item("Rossby SSH", "rossby_sea_surface_heigh")
        ]),
        new GroupItem("Vorticity", [
            new Item("Total Vorticity", "vorticity"), new Item("Kelvin Vorticity", "kelvin_vorticity"),
            new Item("MRG Vorticity", "mrg_vorticity"), new Item("MJO Vorticity", "mjo_vorticity"),
            new Item("ER Vorticity", "er_vorticity")
        ]),
        new GroupItem("Divergence", [
            new Item("Total Divergence", "divergency"), new Item("Kelvin Divergency", "kelvin_divergency"),
            new Item("MRG Divergence", "mrg_divergency"), new Item("MJO Divergency", "mjo_divergency"),
            new Item("ER Divergence", "er_divergency")
        ]),
        new GroupItem("MSLP", [new Item("MSLP", "mslp")]),
        new GroupItem("SST", [new Item("SST", "sst")]),
        new GroupItem("TCWV", [new Item("TCWV", "tcwv")]),
        new GroupItem("VIMD", [new Item("VIMD", "vimd")]),
        new GroupItem("Velocity Potential", [
            new Item("Total Velocity Potential", "velocity_potential"), new Item("Kelvin Velocity Potential", "kelvin_velocity_potential"),
            new Item("MRG Velocity Potential", "mrg_velocity_potential"), new Item("MJO Velocity Potential", "mjo_velocity_potential"),
            new Item("ER Velocity Potential", "er_velocity_potential")
        ]),
        new GroupItem("Stream Function", [
            new Item("Total Stream Function", "stream_function"), new Item("Kelvin Stream Function", "kelvin_stream_function"),
            new Item("MRG Stream Function", "mrg_stream_function"), new Item("MJO Stream Function", "mjo_stream_function"),
            new Item("ER Stream Function", "er_stream_function")
        ]),
    ]);

    for(var i = 0; i  < scalarItems.items.length; i++){
        for(var j = 0; j  < scalarItems.items[i].items.length; j++){
            scalarItems.items[i].items[j].isSelected = selectedScalar === scalarItems.items[i].items[j].code
        }
    }
    return scalarItems;
}

export const getAllDataItems = (selectedItem, actionType) => {
    var allDataItems = new GroupContainer("AllData", actionType,[
        new GroupItem("Wind", [
            new Item("Total Wind", "wind"), new Item("Kelvin Wind", "kelvin_wind"),
            new Item("MRG Wind", "mrg_wind"), new Item("MJO Wind", "mjo_wind"),
            new Item("ER Wind", "er_wind")
        ]),
        new GroupItem("Currents", [
            new Item("Total Currents", "surface_currents"),
            new Item("Kelvin Currents", "kelvin_surface_currents"),
            new Item("Rossby Currents", "rossby_surface_currents")
        ]),
        new GroupItem("VIWVF", [
            new Item("Total VIWVF", "viwvf"), new Item("Kelvin VIWVF", "kelvin_viwvf"),
            new Item("MRG VIWVF", "mrg_viwvf"), new Item("MJO VIWVF", "mjo_viwvf"),
            new Item("ER VIWVF", "er_viwvf")
        ]),
        new GroupItem("Rainfall", [
            new Item("Total Rainfall", "rainfall"), new Item("Kelvin Rainfall", "kelvin_rainfall"),
            new Item("MRG Rainfall", "mrg_rainfall"), new Item("MJO Rainfall", "mjo_rainfall"),
            new Item("ER Rainfall", "er_rainfall")
        ]),
        new GroupItem("Omiolr", [new Item("OMIOLR", "omiolr")]),
        new GroupItem("SSH", [
            new Item("Total SSH", "sea_surface_heigh"), new Item("Kelvin SSH", "kelvin_sea_surface_heigh"),
            new Item("Rossby SSH", "rossby_sea_surface_heigh")
        ]),
        new GroupItem("Vorticity", [
            new Item("Total Vorticity", "vorticity"), new Item("Kelvin Vorticity", "kelvin_vorticity"),
            new Item("MRG Vorticity", "mrg_vorticity"), new Item("MJO Vorticity", "mjo_vorticity"),
            new Item("ER Vorticity", "er_vorticity")
        ]),
        new GroupItem("Divergence", [
            new Item("Total Divergence", "divergency"), new Item("Kelvin Divergency", "kelvin_divergency"),
            new Item("MRG Divergence", "mrg_divergency"), new Item("MJO Divergency", "mjo_divergency"),
            new Item("ER Divergence", "er_divergency")
        ]),
        new GroupItem("MSLP", [new Item("MSLP", "mslp")]),
        new GroupItem("SST", [new Item("SST", "sst")]),
        new GroupItem("TCWV", [new Item("TCWV", "tcwv")]),
        new GroupItem("VIMD", [new Item("VIMD", "vimd")]),
        new GroupItem("Velocity Potential", [
            new Item("Total Velocity Potential", "velocity_potential"), new Item("Kelvin Velocity Potential", "kelvin_velocity_potential"),
            new Item("MRG Velocity Potential", "mrg_velocity_potential"), new Item("MJO Velocity Potential", "mjo_velocity_potential"),
            new Item("ER Velocity Potential", "er_velocity_potential")
        ]),
        new GroupItem("Stream Function", [
            new Item("Total Stream Function", "stream_function"), new Item("Kelvin Stream Function", "kelvin_stream_function"),
            new Item("MRG Stream Function", "mrg_stream_function"), new Item("MJO Stream Function", "mjo_stream_function"),
            new Item("ER Stream Function", "er_stream_function")
        ]),
    ]);

    for(var i = 0; i  < allDataItems.items.length; i++){
        for(var j = 0; j  < allDataItems.items[i].items.length; j++){
            allDataItems.items[i].items[j].isSelected = selectedItem === allDataItems.items[i].items[j].code
        }
    }
    return allDataItems;
}

export const getMenuTypeItems = () => {
    var MenuTypeItems = [new Item("Simple Menu", "simple"), new Item("Advanced Menu", "advanced")];
    return MenuTypeItems;
}