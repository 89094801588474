import React from 'react';
import store from "../../../store/Store";
import SelectButton from '@spectralweather/common/components/Buttons/SelectButton';

export default function MenuSelectButton({itemCode, actionType, onClick, ...rest}) {
  const handleClick = (e) => {
    store.dispatch({
      type: actionType,
      value: itemCode
    })
  };
  return <SelectButton {...rest} onClick={onClick || handleClick} />
}

