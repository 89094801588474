/*
Used as a lowest level container for data representation in menu. It represents single SelectButton

name - name visible on button
code - unique string representing given data
*/
export class Item {
    name = null;
    code = null;
    subGroups = null;
    isSelected = false;

    constructor (name, code, subGroups){
        this.name = name;
        this.code = code;
        this.subGroups = subGroups;
    }
}