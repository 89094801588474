export class ProductData {
    vectorDataBuilder = null;
    scalarDataBuilder = null;
    contoursDataBuilder = null;

    constructor (vector, scalar, contours){
        this.vectorDataBuilder = vector;
        this.scalarDataBuilder = scalar;
        this.contoursDataBuilder = contours;
    }

    getDataBuilders(){
        return [this.vectorDataBuilder, this.scalarDataBuilder, this.contoursDataBuilder];
    }
}