import React, { useState, useMemo, useRef, useEffect} from 'react';
import ColorStopsHolder from '../ColorStopsHolder/index';
import Palette from '../Palette/index';
import { GRADIENT_PICKER_PROP_TYPES } from '../propTypes/index';
import { sortPalette } from '../../lib/index';
import {
	HALF_STOP_HEIGHT,
	DEFAULT_HEIGHT,
	DEFAULT_WIDTH,
	DEFAULT_STOP_REMOVAL_DROP,
	DEFAULT_MAX_STOPS,
	DEFAULT_MIN_STOPS
} from './constants';
import './index.css';
import {SelectedStopColorGradientSubject, CurrentPaletteSubject} from '@spectralweather/common/services/SubjectsService'


const nextColorId = (palette) => Math.max(...palette.map(({ id }) => id)) + 1;

const mapIdToPalette = (palette) => palette.map((color, index) => ({
	...color,
	id: color.id || index + 1
}));

const mapPaletteToStops = ({ palette, activeId, height}) => {
	// if(activeId !== undefined){
	// 	var colorStop = palette.find((el) => el.id === activeId)	
    //     SelectedStopColorGradientSubject.next({color: colorStop.color});
	// }
	return palette.map((color) => ({
		...color,
		id: color.id,
		offset: height * (1-color.offset) - HALF_STOP_HEIGHT,
		isActive: color.id === activeId
	}));
}


const getPaletteColor = (palette, id) => {
	const color = palette.find(color => color.id === id);
	return { ...color, offset: Number(color.offset) };
};

const GradientPicker = ({
	palette,
	scaleBounds,
	height = DEFAULT_HEIGHT,
	width = DEFAULT_WIDTH,
	stopRemovalDrop = DEFAULT_STOP_REMOVAL_DROP,
	minStops = DEFAULT_MIN_STOPS,
	maxStops = DEFAULT_MAX_STOPS,
	colorStopsVisible = false,
	selectedStopColor
}) => {
	const [gradientPalette, setGradientPalette] = useState(mapIdToPalette(palette));
	const colorStopsHoldref = useRef(null);
	const [defaultActiveColor] = palette;
	const [activeColorId, setActiveColorId] = useState(defaultActiveColor.id);
	const [computedHeight, setComputedHeight] = useState(DEFAULT_HEIGHT);

 	useEffect(() => {
		if(colorStopsHoldref.current){
			setComputedHeight(colorStopsHoldref.current.clientHeight);
        }
		return () => {};
	}, []);

	useEffect(() => {
		setGradientPalette(mapIdToPalette(palette));
		setColor(1);
	}, [palette]);

	useEffect(() => {
		if(selectedStopColor && Object.keys(selectedStopColor).length !== 0){
			handleColorSelect(selectedStopColor);
		}
	}, [selectedStopColor]);

	const limits = useMemo(() => {
		const min = -HALF_STOP_HEIGHT;
		const max = computedHeight - HALF_STOP_HEIGHT;

		return { min, max, drop: stopRemovalDrop };
	}, [computedHeight]);

	const handleColorAdd = ({ offset }) => {
		if (gradientPalette.length >= maxStops) return;

		const { color } = getPaletteColor(gradientPalette, activeColorId);
		const entry = { id: nextColorId(gradientPalette), offset: offset / width, color };

		const updatedPalette = [...gradientPalette, entry];

		setColor(entry.id);
		handlePaletteChange(updatedPalette);
	};

	const handleColorDelete = (id) => {
		if (gradientPalette.length <= minStops) return;

		const updatedPalette = gradientPalette.filter(c => c.id !== id);
		const activeId = updatedPalette.reduce((a, x) => x.offset < a.offset ? x : a, updatedPalette[0]).id;

		setColor(activeId);
		handlePaletteChange(updatedPalette);
	};

	const onStopDragStart = (id) => {
		setColor(id);
	};

	const setColor = (id) => {
		var colorStop = gradientPalette.find((el) => el.id === id)	
    	SelectedStopColorGradientSubject.next({color: colorStop.color});
		setActiveColorId(id);
	};

	const handleColorSelect = (color, opacity = 1) => {
		palette = gradientPalette.map(c =>
			activeColorId === c.id ? { ...c, color: color.hex } : c
		);
		setColor(activeColorId);
		handlePaletteChange(palette);
	};

	const handlePaletteChange = (palette) => {
		const sortedPalette = sortPalette(palette)
			.map(({ offset, ...rest }) => ({ offset: Number(offset), ...rest }));
		CurrentPaletteSubject.next(sortedPalette.map(el => el.color +":"+ el.offset));
		setGradientPalette(sortedPalette);
	};

	const handleStopPosChange = ({ id, offset }) => {
		const updatedPalette = gradientPalette.map(c =>
			id === c.id ? { ...c, offset: (computedHeight-offset - HALF_STOP_HEIGHT) / computedHeight } : c
		);

		handlePaletteChange(updatedPalette);
	};

	const stopsHolderDisabled = gradientPalette.length >= maxStops;

	return (
		<div className="gp" ref={colorStopsHoldref} style={{height:height}}>
          	{colorStopsVisible?
				<ColorStopsHolder			
					height={computedHeight}
					disabled={stopsHolderDisabled}
					stops={mapPaletteToStops({
						palette: gradientPalette,
						height: computedHeight,
						activeId: activeColorId
					})}
					limits={limits}

					onPosChange={handleStopPosChange}
					onAddColor={handleColorAdd}
					onDeleteColor={handleColorDelete}
					onDragStart={onStopDragStart}
				/>  
				: <></>       
		  	}
			<Palette width={width} height={height} palette={gradientPalette} scaleBounds={scaleBounds}/>
		</div>
	);
};

GradientPicker.propTypes = GRADIENT_PICKER_PROP_TYPES;

export default GradientPicker;