import React, { useEffect, useState} from 'react';
import { filter } from 'rxjs/operators';
import {ProductDataSubject} from '@spectralweather/common/services/SubjectsService'
import { GradientPicker } from "@spectralweather/common/components/GradientPicker";


function Scale (props) {
    const [palette, setPalette] = useState([
        { offset: 0.00, color: '#0000FF' },
        { offset: 0.50, color: '#00FF00' },
        { offset: 1.00, color: '#FF0000' }
    ]);
    const [scaleBounds, setscaleBounds] = useState({});
    
    useEffect(() => {
        var PruductSubscription = ProductDataSubject.asObservable().pipe(filter(val => val)).subscribe(value => updateScale(value)); 
        return () => {
            PruductSubscription.unsubscribe();
        };
    }, []);

    const updateScale = (productData) =>{
        // Draw color bar for reference
        var scalarBuilder = productData.scalarDataBuilder.product;
        var scale = scalarBuilder.scale; 
        setscaleBounds({min: scale.bounds[0], max:scale.bounds[1], unit: scalarBuilder.units[0]});
        setPalette(scale.getColors());
    }

    return (
        <div className={props.className} style={{height:"100%", width: "30px"}}>
            <GradientPicker {...{
                height: "100%",
                width:26,
                palette,
                scaleBounds,
                colorStopsVisible: props.colorStopsVisible,
                selectedStopColor: props.selectedStopColor,
            }}>
            </GradientPicker>
        </div>
    );
}

export default Scale;
