import {BaseProduct} from "./BaseProduct"
import {VectorProductBuilder} from "../../productbuilders/VectorProductBuilder"

export class BaseVectorProduct extends BaseProduct{
    field = "vector"

    getBuilder(file){
        return new VectorProductBuilder(file, this.bilinearInterpolateVector);
    }

    bilinearInterpolateVector(x, y, g00, g10, g01, g11) {
        var rx = (1 - x);
        var ry = (1 - y);
        var a = rx * ry,  b = x * ry,  c = rx * y,  d = x * y;
        var u = g00[0] * a + g10[0] * b + g01[0] * c + g11[0] * d;
        var v = g00[1] * a + g10[1] * b + g01[1] * c + g11[1] * d;
        return [u, v, Math.sqrt(u * u + v * v)];
    }
}