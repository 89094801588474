import {Globe} from './Globe'
import {geoPath, geoGraticule, geoOrthographic} from "d3-geo";

export class OrthographicGlobe extends Globe{

    constructor(micro){
        super(micro);
        this.projection = this.newProjection();
    }

    newProjection(){
        return geoOrthographic().rotate(this.Micro.currentPosition()).precision(0.1).clipAngle(87);
    }

    defineMap(mapSvg, foregroundSvg, contoursSvg) {
        var path = geoPath().projection(this.projection);
        var defs = mapSvg.append("defs");
        var gradientFill = defs.append("radialGradient")
            .attr("id", "orthographic-fill")
            .attr("gradientUnits", "objectBoundingBox")
            .attr("cx", "50%").attr("cy", "49%").attr("r", "50%");
        gradientFill.append("stop").attr("stop-color", "#303030").attr("offset", "69%");
        gradientFill.append("stop").attr("stop-color", "#202020").attr("offset", "91%");
        gradientFill.append("stop").attr("stop-color", "#000005").attr("offset", "96%");
        defs.append("path")
            .attr("id", "sphere")
            .datum({type: "Sphere"})
            .attr("d", path);
        mapSvg.append("use")
            .attr("xlink:href", "#sphere")
            .attr("fill", "url(#orthographic-fill)");
        mapSvg.append("path")
            .attr("class", "graticule")
            .datum(geoGraticule())
            .attr("d", path);
        mapSvg.append("path")
            .attr("class", "hemisphere")
            .datum(geoGraticule().stepMinor([0, 90]).stepMajor([0, 90]))
            .attr("d", path);
        mapSvg.append("path")
            .attr("class", "coastline");
        mapSvg.append("path")
            .attr("class", "lakes");
        foregroundSvg.append("use")
            .attr("xlink:href", "#sphere")
            .attr("class", "foreground-sphere");
        contoursSvg.append("use")
            .attr("xlink:href", "#sphere")
            .attr("class", "foreground-sphere");
    }

    locate(coord) {
        return [-coord[0], -coord[1], this.projection.rotate()[2]];
    }
}