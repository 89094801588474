import { combineReducers } from 'redux';
import reduceReducers from 'reduce-reducers';
import GlobalDataReducer from "./GlobalDataReducer";
import VectorDataReducer from "./VectorDataReducer";
import ScalarDataReducer from "./ScalarDataReducer";
import ContoursDataReducer from "./ContoursDataReducer";
import GlobeDataReducer from "./GlobeDataReducer";
import LastActionReducer, {UtilityDataReducer} from "./UtilityReducer";
import MetaDataReducer from "@spectralweather/common/store/MetaDataReducer";

const combinedReducer = combineReducers({
    vector: VectorDataReducer,
    scalar: ScalarDataReducer,
    contours: ContoursDataReducer,
    global: GlobalDataReducer,
    globe: GlobeDataReducer,
    metadata: MetaDataReducer,
    utility: UtilityDataReducer,
    lastAction: LastActionReducer,
  })

/*
  This function is executed AFTER every reducer is called. Its responsibility is to keep in sync all available reducers.
  Its not possible to update VectorDataReducer from inside ScalarDataReducer, therefore special logic is needed.
  Use case: Change Date using calendar below globe. It is setting only globalTime but scalarTime, vectorTime and contoursTime should be changed as well
*/
function crossDataReducer(state, action) {
    switch (action.type) {
        case 'global/incrementDate': {
            return {
                ...state,
                scalar:   ScalarDataReducer(state.scalar, {...action, type:'scalar/incrementDate'}),
                vector:   VectorDataReducer(state.vector, {...action, type:'vector/incrementDate'}),
                contours: ContoursDataReducer(state.contours, {...action, type:'contours/incrementDate'}),
            }
        }
        case 'global/decrementDate': {
            return {
                ...state,
                scalar:   ScalarDataReducer(state.scalar, {...action, type:'scalar/decrementDate'}),
                vector:   VectorDataReducer(state.vector, {...action, type:'vector/decrementDate'}),
                contours: ContoursDataReducer(state.contours, {...action, type:'contours/decrementDate'}),            
            }
        }
        case 'global/chooseDate': {
            return {
                ...state,
                scalar:   ScalarDataReducer(state.scalar, {...action, type:'scalar/chooseDate'}),
                vector:   VectorDataReducer(state.vector, {...action, type:'vector/chooseDate'}),
                contours: ContoursDataReducer(state.contours, {...action, type:'contours/chooseDate'}),
            }
        }
        case 'global/setPressure': {
            return {
                ...state,
                scalar:   ScalarDataReducer(state.scalar, {...action, type:'scalar/setPressure'}),
                vector:   VectorDataReducer(state.vector, {...action, type:'vector/setPressure'}),
                contours: ContoursDataReducer(state.contours, {...action, type:'contours/setPressure'}),
            }
        }
        default:
            return state
    }
}
  
const rootReducer = reduceReducers(combinedReducer, crossDataReducer)
export default rootReducer;