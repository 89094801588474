import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@spectralweather/common/components/Buttons/IconButton";
import { makeStyles } from "@spectralweather/common/theme/theme";

export default function SpectralWeatherContainer(props) {
  const {
    className,
    additionalIcon,
    onCloseClick,
    maxWidth,
    maxHeight,
    height,
    ...rest
  } = props;

  return (
    <Box
      sx={{
        color: "sw.primaryText",
        borderStyle: "solid",
        borderColor: "sw.primaryBorder",
        borderWidth: "1px",
        position: "relative",
        backgroundColor: "sw.primaryBackground",
        height: height ?? "auto",
        maxHeight: maxHeight ?? "auto",
        width: "auto",
        maxWidth: maxWidth ?? "auto",
        ...props.containerStyle,
      }}
      {...rest}
    >
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },      
          position: "absolute",
          width: "auto",
          top: 0,
          right: 0,
          zIndex: 2,
        }}
      >
        {additionalIcon}
        <IconButton onClick={onCloseClick}>close</IconButton>
      </Box>
      {props.children}
    </Box>
  );
}
