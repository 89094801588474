import React, { useEffect, useState, useRef } from "react";
import BaseButton from "@spectralweather/common/components/Buttons/BaseButton/BaseButton";
import { filter } from "rxjs/operators";
import {
  SelectedColorStopSubject,
  SelectedStopColorGradientSubject,
} from "@spectralweather/common/services/SubjectsService";
import {applyScaleSettings} from '../../services/EngineService'
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import { ChromePicker } from "react-color";
import { makeStyles } from "@spectralweather/common/theme/theme";

const useStyles = makeStyles()((theme) => {
  return {
    button: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
    settingsContainer: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    datePicker: {
      marginTop: theme.spacing(1),
    },
  };
});


function ScaleSettings(props) {
  const { classes } = useStyles();
  const isFirstRun = useRef(true);
  const [open, setOpen] = React.useState(props.settingsOpened);
  const [color, setColor] = useState("#ffff00"); // define a state for the color prop

  useEffect(() => {
    var colorSubscription = SelectedStopColorGradientSubject.asObservable()
      .pipe(filter((val) => val))
      .subscribe((col) => {
        if(col.color !== color){
            setColor(col.color);
        }
      });
    return () => {
      colorSubscription.unsubscribe();
    };
  }, []);


  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (props.settingsOpened) {
      setOpen(false);
    }
  }, [props.settingsOpened]);


  const toggleGradientPicker = () => {
    if (props.onColorsStopsVisChange) {
      props.onColorsStopsVisChange(!open);
    }
    setOpen(!open);
  };


  const handleChange = (color) => {
    SelectedColorStopSubject.next(color);
    setColor(color.hex);
  };


  const GradientPicker = React.forwardRef((props, ref) => {
    return (
      <Grid item xs={12} {...props} ref={ref}>
        <ChromePicker    
          className={classes.datePicker}
          disableAlpha={true}
          width="100%"
          color={color}
          onChangeComplete={handleChange}
        />
        <BaseButton className={classes.button} onClick={applyScaleSettings}>
          APPLY
        </BaseButton>
      </Grid>
    );
  });

  return (
    <Grid container className={classes.settingsContainer}>
      <Grid item xs={12}>
        <BaseButton onClick={toggleGradientPicker} className={classes.button}>
        COLOR SCALE
        </BaseButton>
      </Grid>
      <Fade in={open} style={{display: open ? 'block' : 'none'}} >
          <GradientPicker/>
      </Fade>
    </Grid>
  );
}

export default ScaleSettings;
