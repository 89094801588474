export const InitialState = {
    scalarTime: new Date('2019-12-30T00:00:00'),
    scalarData: "wind",
    scalarPressure: "850"
}

export default function scalarDataReducer(state = InitialState, action) {
    switch (action.type) {
        case 'scalar/setData': {
            return {
                ...state,
                scalarData: action.value
            }
        }
        case 'scalar/incrementDate': {
            let timeSpanIncrement = parseInt(action.value) || 8
            const scalarTime = new Date(state.scalarTime);
            scalarTime.setHours(scalarTime.getHours() + timeSpanIncrement);
            return {
                ...state,
                scalarTime: scalarTime
            }
        }
        case 'scalar/decrementDate': {
            let timeSpanDecrement = parseInt(action.value) || 8
            const scalarTime = new Date(state.scalarTime);
            scalarTime.setHours(scalarTime.getHours() - timeSpanDecrement);
            return {
                ...state,
                scalarTime: scalarTime
            }
        }
        case 'scalar/chooseDate': {
            return {
                ...state,
                scalarTime: new Date(action.value)
            }
        }
        case 'scalar/setPressure': {
            return {
                ...state,
                scalarPressure: action.value
            }
        }
        default:
            return state
    }
}
