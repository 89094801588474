import React from "react";
import "./App.css";
import Layout from "./components/Layout";
import store from "./store/Store";
import { ReportErrorSubject } from "@spectralweather/common/services/SubjectsService";
import { withAlert } from "react-alert";
import { ErrorAlertContext } from "@spectralweather/common/components/Alerts/AlertTemplate";
import { getInputController } from "./services/EngineService";
import _ from "lodash";
import { connect } from "react-redux";
import { fetchMetaData } from "@spectralweather/common/store/MetaDataReducer";

class App extends React.Component {
  constructor() {
    super();
    this.debouncedResizeHandler = _.debounce(() => {
      const controller = getInputController();
      controller.recalculateScreenDimensions();
      controller.reorient();
    }, 250); // 250ms
  }

  componentDidMount() {
    // subscribe to home component messages
    this.errorSubscription = ReportErrorSubject.subscribe((message) => {
      this.props.alert.removeAll();
      this.props.alert.show(message, { type: "error" });
    });
    this.storeUnssubscribe = store.subscribe(() =>
      this.props.alert.removeAll()
    );
    window.addEventListener("resize", this.debouncedResizeHandler);
    this.props.dispatch(fetchMetaData());
  }

  componentWillUnmount() {
    this.errorSubscription.unsubscribe();
    this.storeUnssubscribe();
    window.removeEventListener("resize", this.debouncedResizeHandler);
  }

  render() {
    return <Layout />;
  }
}

export default connect()(withAlert(ErrorAlertContext)(App));
