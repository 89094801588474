import { Micro as M } from "@spectralweather/common/utils/Micro";
import { Products as P } from "../models/products/Products";
import { InputController as IC } from "../utils/InputController";
import { OrthographicGlobe as OG } from "../models/globes/OrthographicGlobe";
import {
  GlobeMeshCombinedObservable,
  GlobeSubject,
  ReportStatusSubject,
  ProductDataDrawerSubject,
  ProductDataSubject,
  ProductDataFieldSubject,
  CancelSubject,
  ProductDataCountourSettingsCombinedObservable,
  ReportErrorSubject,
  ClearMapSubject,
  CurrentPaletteSubject,
  ContoursSettingsSubject,
} from "@spectralweather/common/services/SubjectsService";
import {
  ContourBuilder,
  ContourDrawer,
  ContourSettings,
} from "./ContoursService";
import _ from "lodash";
import * as d3 from "d3";
import { geoPath } from "d3-geo";
import { filter } from "rxjs/operators";
import Backbone from "backbone";
import when from "when";
import { ProductData } from "../models/productData/ProductData";
import { getColor } from "@spectralweather/common/services/PaletesService";
import { ProductDataField } from "../models/productDataField/ProductDataField";
import { ProductDataBuilder } from "../models/productbuilders/ProductDataBuilder";
import { ProductDataAnimator } from "../models/productDataAnimators/ProductDataAnimator";
import store from "../store/Store";
import {
  ExampleGradients,
  setCurrentGradient,
} from "@spectralweather/common/services/PaletesService";

export const Micro = new M();
export const Products = new P(Micro);

var InputController = null;
export const getInputController = () => {
  InputController = InputController || new IC(GlobeSubject.value, Micro, store);
  return InputController;
};

var INTENSITY_SCALE_STEP = 10; // step size of particle intensity color scale
var PARTICLE_MULTIPLIER = 7; // particle count scalar (completely arbitrary--this values looks nice)
var PARTICLE_REDUCTION = 0.75; // reduce particle count to this much of normal for mobile devices
var FRAME_RATE = 40; // desired milliseconds per frame

ContoursSettingsSubject.next(new ContourSettings(2, 10, "#FFFFE0"));
GlobeSubject.next(new OG(Micro));
GlobeMeshCombinedObservable.pipe(filter((val) => val)).subscribe((value) =>
  buildRenderer(value[0], value[1])
);
store.subscribe(triggerRebuildIfNeeded);
ProductDataSubject.pipe(filter((val) => val)).subscribe((value) =>
  interpolateData(value)
);
ProductDataCountourSettingsCombinedObservable.pipe(
  filter((val) => val)
).subscribe((value) => drawContours(value[0], value[1]));
ProductDataFieldSubject.pipe(filter((val) => val)).subscribe((value) =>
  drawOverlay(value)
);
ProductDataFieldSubject.pipe(filter((val) => val)).subscribe((value) =>
  animate(value)
);
ClearMapSubject.pipe(filter((val) => val)).subscribe(clearAnimationAndHeatMap);
CancelSubject.subscribe(OnJobCancel);

var states = store.getState();
buildGrids(states);

function triggerRebuildIfNeeded() {
  var states = store.getState();
  if (
    [
      "scalar/setData",
      "scalar/incrementDate",
      "scalar/decrementDate",
      "scalar/chooseDate",
      "scalar/setPressure",
      "vector/setData",
      "vector/incrementDate",
      "vector/decrementDate",
      "vector/chooseDate",
      "vector/setPressure",
      "global/chooseDate",
      "global/incrementDate",
      "global/decrementDate",
      "global/setPressure",
      "contours/setState",
      "contours/setData",
      "contours/incrementDate",
      "contours/decrementDate",
      "contours/chooseDate",
      "contours/setPressure",
    ].includes(states.lastAction.type)
  ) {
    ClearMapSubject.next(true);
    buildGrids(states);
  }
}

function drawContours(productData, contoursSettings) {
  var states = store.getState();
  if (!productData || states.contours.contoursState === "hide") {
    return;
  }
  let nx = productData.contoursDataBuilder.productBuilder.header.nx;
  let ny = productData.contoursDataBuilder.productBuilder.header.ny;

  var magnitudeArray = new Array(ny);
  for (let i = 0; i < nx * ny; i++) {
    magnitudeArray[i] =
      productData.contoursDataBuilder.productBuilder.dataValue(i);
  }
  let dataMin = Math.min.apply(null, magnitudeArray);
  let dataMax = Math.max.apply(null, magnitudeArray);

  let metadata = {
    nx: nx,
    ny: ny,
    dataMin: dataMin,
    dataMax: dataMax,
  };

  var dataArray = new Array(ny);
  for (let i = 0; i < ny; i++) {
    dataArray[i] = magnitudeArray.splice(0, 360);
    dataArray[i][360] = dataArray[i][0]; // Its a trick for boundary conditions to be continous
  }

  let builder = new ContourBuilder(
    dataArray,
    GlobeSubject.value,
    metadata,
    contoursSettings
  );
  let drawer = new ContourDrawer(builder, GlobeSubject.value, Micro);
  drawer.drawContours();
}

function buildRenderer(mesh, globe) {
  if (!mesh || !globe) return null;

  ReportStatusSubject.next("Rendering Globe...");
  Micro.Log.time("rendering map");

  // UNDONE: better way to do the following?
  var dispatch = _.clone(Backbone.Events);
  //if (rendererAgent._previous) {
  //  rendererAgent._previous.stopListening();
  //}
  //rendererAgent._previous = dispatch;

  Micro.removeChildren(d3.select("#map").node());
  Micro.removeChildren(d3.select("#foreground").node());
  Micro.removeChildren(d3.select("#contours").node());
  globe.defineMap(
    d3.select("#map"),
    d3.select("#foreground"),
    d3.select("#contours")
  );

  var path = geoPath().projection(globe.projection).pointRadius(7);
  var coastline = d3.select(".coastline");
  var lakes = d3.select(".lakes");
  d3.selectAll("path").attr("d", path); // do an initial draw -- fixes issue with safari

  //function drawLocationMark(point, coord) {
  //// show the location on the map if defined
  //  if (fieldAgent.value() && !fieldAgent.value().isInsideBoundary(point[0], point[1])) {
  //      // UNDONE: Sometimes this is invoked on an old, released field, because new one has not been
  //      //         built yet, causing the mark to not get drawn.
  //      return;  // outside the field boundary, so ignore.
  //  }
  //  if (coord && _.isFinite(coord[0]) && _.isFinite(coord[1])) {
  //      var mark = d3.select(".location-mark");
  //      if (!mark.node()) {
  //          mark = d3.select("#foreground").append("path").attr("class", "location-mark");
  //      }
  //      mark.datum({type: "Point", coordinates: coord}).attr("d", path);
  //  }
  //}

  //// Draw the location mark if one is currently visible.
  //if (activeLocation.point && activeLocation.coord) {
  //  drawLocationMark(activeLocation.point, activeLocation.coord);
  //}
  var REDRAW_WAIT = 5; // milliseconds
  var doDraw_throttled = _.throttle(doDraw, REDRAW_WAIT, {
    leading: true,
    trailing: false,
  });

  function doDraw() {
    d3.selectAll("path").attr("d", path);
    //rendererAgent.trigger("redraw");
  }

  // Attach to map rendering events on input controller.
  dispatch.listenTo(InputController.Dispatch, {
    moveStart: function () {
      coastline.datum(mesh.coastLo);
      lakes.datum(mesh.lakesLo);
      CancelSubject.next(true);
      ClearMapSubject.next(true);
    },
    move: function () {
      doDraw_throttled();
    },
    moveEnd: function () {
      CancelSubject.next(false);
      coastline.datum(mesh.coastHi);
      lakes.datum(mesh.lakesHi);
      d3.selectAll("path").attr("d", path);
      ProductDataSubject.next(ProductDataSubject.value);
    },
    click: function (point, coord) {
      const [x,y] = point;
      var bounds = GlobeSubject.value.bounds(Micro.View);
      if (x <= bounds.xMax && x >= bounds.x && y <= bounds.yMax && y >= bounds.y) {
        store.dispatch({
          type: "globe/setCoordinates",
          value: coord,
        });
        store.dispatch({
          type: "utility/setInfoTooltip",
          value: true,
        });
        if(ProductDataSubject.value && ProductDataSubject.value.scalarDataBuilder){
          let scalarVal = ProductDataSubject.value.scalarDataBuilder.interpolate(coord[0], coord[1]);
          if(Array.isArray(scalarVal)){
            scalarVal = scalarVal[2];
          }
          store.dispatch({
            type: "utility/setClickedValue",
            value: scalarVal,
            units: ProductDataSubject.value.scalarDataBuilder.product.units[0]
          });
        }
      }
    },
  });

  // Finally, inject the globe model into the input controller. Do it on the next event turn to ensure
  // renderer is fully set up before events start flowing.
  when(true).then(function () {
    InputController.Dispatch.globe(GlobeSubject.value);
  });

  Micro.Log.timeEnd("rendering map");

  return;
}

function buildGrids(state) {
  ReportStatusSubject.next("Downloading...");
  Micro.Log.time("build grids");
  CancelSubject.next(true);
  // UNDONE: upon failure to load a product, the unloaded product should still be stored in the agent.
  //         this allows us to use the product for navigation and other state.
  //var cancel = this.cancel;

  var currentProductData =
    ProductDataSubject.value ?? new ProductData(null, null, null);
  var nextProducts = Products.productsFor(state);
  var productsToBuild = [];

  // This terrible method check if its necessary to download new data (for example when only vector data changed)
  _.values(nextProducts).forEach((nextProduct) => {
    if (
      !productsToBuild.some((x) =>
        _.isEqual(x.productAttributes, nextProduct.productAttributes)
      )
    ) {
      var currentDataBuilders = currentProductData
        .getDataBuilders()
        .filter((a) => a);
      if (
        !currentDataBuilders.some((x) =>
          _.isEqual(x.product.productAttributes, nextProduct.productAttributes)
        )
      ) {
        productsToBuild.push(nextProduct);
      }
    }
  });

  var loadedDataBuilders = when.map(productsToBuild, async function (product) {
    return new ProductDataBuilder(
      product,
      await Micro.loadJson(product.endpoint)
    );
  });

  return when
    .all(loadedDataBuilders)
    .then((dataBuilders) => {
      Micro.Log.timeEnd("build grids");
      // setting up data builders from previous databuilder or newly created databuilder. There should be less complex solution to do it :/
      var vectorDataBuilder = dataBuilders.some((x) =>
        _.isEqual(
          x.product.productAttributes,
          nextProducts.vector.productAttributes
        )
      )
        ? dataBuilders.find((x) =>
            _.isEqual(
              x.product.productAttributes,
              nextProducts.vector.productAttributes
            )
          )
        : currentProductData
            .getDataBuilders()
            .find((x) =>
              _.isEqual(
                x.product.productAttributes,
                nextProducts.vector.productAttributes
              )
            );

      var scalarDataBuilder = dataBuilders.some((x) =>
        _.isEqual(
          x.product.productAttributes,
          nextProducts.scalar.productAttributes
        )
      )
        ? dataBuilders.find((x) =>
            _.isEqual(
              x.product.productAttributes,
              nextProducts.scalar.productAttributes
            )
          )
        : currentProductData
            .getDataBuilders()
            .find((x) =>
              _.isEqual(
                x.product.productAttributes,
                nextProducts.scalar.productAttributes
              )
            );

      var contoursDataBuilder = dataBuilders.some((x) =>
        _.isEqual(
          x.product.productAttributes,
          nextProducts.contours.productAttributes
        )
      )
        ? dataBuilders.find((x) =>
            _.isEqual(
              x.product.productAttributes,
              nextProducts.contours.productAttributes
            )
          )
        : currentProductData
            .getDataBuilders()
            .find((x) =>
              _.isEqual(
                x.product.productAttributes,
                nextProducts.contours.productAttributes
              )
            );

      ProductDataSubject.next(
        new ProductData(
          vectorDataBuilder,
          scalarDataBuilder,
          contoursDataBuilder
        )
      );
    })
    .catch((reason) => {
      ReportErrorSubject.next("No data available for selected parameters");
    });
}

function interpolateData(productData) {
  var globe = GlobeSubject.value;
  var config = store.getState();

  if (!globe || !productData) return null;

  var vectorDataBuilder = productData.vectorDataBuilder;
  var scalarDataBuilder = productData.scalarDataBuilder;

  // How fast particles move on the screen (arbitrary value chosen for aesthetics).
  var particlesScale = config.settings
    ? config.settings.particles.velocityScale
    : vectorDataBuilder.product.particles.velocityScale;
  var scale = config.settings
    ? getSettings(config.settings).scale
    : scalarDataBuilder.product.scale;

  var dataField = new ProductDataField(
    Micro,
    globe,
    vectorDataBuilder,
    scalarDataBuilder,
    particlesScale,
    scale
  );
  ProductDataFieldSubject.next(dataField.interpolateField());
}

function drawOverlay(field) {
  if (!field) return;

  var ctx = d3.select("#overlay").node().getContext("2d");

  Micro.clearCanvas(d3.select("#overlay").node());
  ctx.putImageData(field.Overlay, 0, 0);
}

function animate(field) {
  var productData = ProductDataSubject.value;
  var globe = GlobeSubject.value;
  var config = store.getState();
  if (!globe || !field || !productData) return;

  var bounds = globe.bounds(Micro.View);
  // maxIntensity is the velocity at which particle color intensity is maximum
  var maxIntensity = config.settings
    ? config.settings.particles.maxIntensity
    : productData.vectorDataBuilder.product.particles.maxIntensity;
  var colorStyles = Micro.windIntensityColorScale(
    INTENSITY_SCALE_STEP,
    maxIntensity
  );
  var particleCount = Math.round(bounds.width * PARTICLE_MULTIPLIER);
  if (Micro.isMobile()) {
    particleCount *= PARTICLE_REDUCTION;
  }

  var drawer = new ProductDataAnimator(
    field,
    Micro,
    bounds,
    colorStyles,
    particleCount
  );
  if (ProductDataDrawerSubject.value) {
    ProductDataDrawerSubject.value.stopAnimation();
  }
  ProductDataDrawerSubject.next(drawer);

  (function frame() {
    try {
      if (drawer.IsCanceled) {
        field.stopInterpolation();
        field.release();
        return;
      }
      drawer.evolve();
      drawer.draw();
      setTimeout(frame, FRAME_RATE);
    } catch (e) {
      ReportErrorSubject.next("Drawing animation error");
    }
  })();
}

function OnJobCancel(isCanceled) {
  if (isCanceled) {
    //clearAnimationAndHeatMap();
    if (ProductDataFieldSubject.value) {
      ProductDataFieldSubject.value.stopInterpolation();
      ProductDataFieldSubject.next(null);
    }
    if (ProductDataDrawerSubject.value) {
      ProductDataDrawerSubject.value.stopAnimation();
      ProductDataDrawerSubject.next(null);
    }
  }
}

function clearAnimationAndHeatMap() {
  Micro.clearCanvas(d3.select("#animation").node());
  Micro.clearCanvas(d3.select("#overlay").node());
  Micro.removeChildren(d3.select("#contours").node());
}

function getSettings(store) {
  var param = store.vectorData.value;
  var overlay = store.scalarData.value;

  return {
    scale: {
      gradient: function (v, a) {
        var value = Micro.getValueFromRange(
          store.settings.scale.Min,
          store.settings.scale.Max,
          v
        );
        return getColor(
          Micro.scaleToZeroOne(
            store.settings.scale.Min,
            store.settings.scale.Max,
            value
          ),
          a,
          param,
          overlay
        );
      },
      bounds: [store.settings.scale.Min, store.settings.scale.Max],
    },
    particles: {
      velocityScale: store.settings.particles.velocityScale,
      maxIntensity: store.settings.particles.maxIntensity,
    },
  };
}

export const applyExampleGradient = (id) => {
  if (
    ProductDataSubject.value &&
    ProductDataSubject.value.scalarDataBuilder &&
    ProductDataSubject.value.scalarDataBuilder.product
  ) {
    var dataType = ProductDataSubject.value.scalarDataBuilder.product.type;
    var gradient = ExampleGradients[id];
    setCurrentGradient(dataType, gradient.Colors);
    CurrentPaletteSubject.next(gradient.Colors);
    CancelSubject.next(true);
    while (ProductDataFieldSubject.value != null) {}
    ProductDataSubject.next(ProductDataSubject.value);
  }
};

export const applyScaleSettings = () => {
  if (
    ProductDataSubject.value &&
    ProductDataSubject.value.scalarDataBuilder &&
    ProductDataSubject.value.scalarDataBuilder.product
  ) {
    var dataType = ProductDataSubject.value.scalarDataBuilder.product.type;

    setCurrentGradient(dataType, CurrentPaletteSubject.value);
    CancelSubject.next(true);
    while (ProductDataFieldSubject.value != null) {}
    ProductDataSubject.next(ProductDataSubject.value);
  }
};
