import {BaseVectorProduct} from "./BaseVectorProduct"

export class BaseWindProduct extends BaseVectorProduct{

    constructor(micro, attr){
        super(micro);
        this.setProductAttributes(attr.date, attr.dataType, attr.level);

        this.type = "wind";
        this.description = this.localize({
            name: {en: "Wind"},
        });
        this.endpoint = this.apiEndpoint("vector", this.productAttributes.level);
        this.units = [
            {label: "m/s",  conversion: function(x) { return x; },            precision: 1},
            {label: "km/h", conversion: function(x) { return x * 3.6; },      precision: 0}
        ];
    }

    

    

}