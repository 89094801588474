// ITs a hack around Redux, to know which action called invoked store subscription
export default function LastActionReducer(state = null, action) {
    return {type: action.type};
  }


export const InitialState = {
    showInfoTooltip: true,
    scalarValue: null,
    units: null
}

export function UtilityDataReducer(state = InitialState, action) {
    switch (action.type) {
        case 'utility/setInfoTooltip': {
            return {
                ...state,
                showInfoTooltip: action.value
            }
        }
        case 'utility/setClickedValue': {
            return {
                ...state,
                scalarValue: action.value,
                units: action.units
            }
        }
        default:
            return state
    }
}  
