const MenuDivider = {
  margin: 2,
  backgroundColor: "sw.divider",
};

const MenuTextField = {
  marginLeft: 3,
  color: "sw.primaryText",
  "& .MuiInputBase-root": {
    color: "sw.primaryText",
    
  },
  "& .MuiInputBase-input": {
    color: "sw.primaryText",
    backgroundColor: "sw.inputBackground",
    padding: 0.25,
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "sw.inputBackground",
    borderColor: "sw.primaryBorder",
    borderRadius: 0,
    paddingRight: 1,
    "&.Mui-focused fieldset": {
      borderColor: "sw.primaryBorder",
    },
  },
};

const styles = {
    menuDivider: MenuDivider,
    menuTextField: MenuTextField,
}

export default styles
