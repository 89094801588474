import {Gradient} from "@spectralweather/common/utils/Gradient"

const defaultwindColors = ['#0000FF','#00FF00','#FF0000'];
const defaultkelvin_windColors = ['#0000FF','#00FF00','#FF0000'];
const defaultmrg_windColors = ['#0000FF','#00FF00','#FF0000'];
const defaulter_windColors = ['#0000FF','#00FF00','#FF0000'];
const defaultmjo_windColors = ['#0000FF','#00FF00','#FF0000'];
const defaultsurface_currentsColor = ['#081030', '#002060', '#003492', '#005a94', '#007592', '#1a8c93', '#67a29b', '#9bb7ac', '#b6b6b6', '#b0ae98', '#a7936b', '#a37443', '#9f512c', '#8e2f39', '#6f1840', '#300818'];
const defaultkelvin_surface_currentsColor = ['#081030', '#002060', '#003492', '#005a94', '#007592', '#1a8c93', '#67a29b', '#9bb7ac', '#b6b6b6', '#b0ae98', '#a7936b', '#a37443', '#9f512c', '#8e2f39', '#6f1840', '#300818'];
const defaultrossby_surface_currentsColor = ['#081030', '#002060', '#003492', '#005a94', '#007592', '#1a8c93', '#67a29b', '#9bb7ac', '#b6b6b6', '#b0ae98', '#a7936b', '#a37443', '#9f512c', '#8e2f39', '#6f1840', '#300818'];
const defaultviwvfColors = ['#0000FF','#00FF00','#FF0000'];
const defaulter_viwvfColors = ['#0000FF','#00FF00','#FF0000'];
const defaultkelvin_viwvfColors = ['#0000FF','#00FF00','#FF0000'];
const defaultmjo_viwvfColors = ['#0000FF','#00FF00','#FF0000'];
const defaultmrg_viwvfColors = ['#0000FF','#00FF00','#FF0000'];
const defaultrainfallColors = ['#0000FF','#00FF00','#FF0000']; 
const defaultkelvin_rainfallColors = ['#081030', '#002060', '#003492', '#005a94', '#007592', '#1a8c93', '#67a29b', '#9bb7ac', '#b6b6b6', '#b0ae98', '#a7936b', '#a37443', '#9f512c', '#8e2f39', '#6f1840', '#300818'];
const defaultmrg_rainfallColors = ['#081030', '#002060', '#003492', '#005a94', '#007592', '#1a8c93', '#67a29b', '#9bb7ac', '#b6b6b6', '#b0ae98', '#a7936b', '#a37443', '#9f512c', '#8e2f39', '#6f1840', '#300818'];
const defaulter_rainfallColors = ['#081030', '#002060', '#003492', '#005a94', '#007592', '#1a8c93', '#67a29b', '#9bb7ac', '#b6b6b6', '#b0ae98', '#a7936b', '#a37443', '#9f512c', '#8e2f39', '#6f1840', '#300818'];
const defaultmjo_rainfallColors = ['#081030', '#002060', '#003492', '#005a94', '#007592', '#1a8c93', '#67a29b', '#9bb7ac', '#b6b6b6', '#b0ae98', '#a7936b', '#a37443', '#9f512c', '#8e2f39', '#6f1840', '#300818'];
const defaultomiolrColors = ['#081030', '#002060', '#003492', '#005a94', '#007592', '#1a8c93', '#67a29b', '#9bb7ac', '#b6b6b6', '#b0ae98', '#a7936b', '#a37443', '#9f512c', '#8e2f39', '#6f1840', '#300818'];
const defaultsea_surface_heighColors = ['#0000FF','#00FF00','#FF0000'];
const defaultkelvin_sea_surface_heighColors = ['#0000FF','#00FF00','#FF0000'];
const defaultrossby_sea_surface_heighColors = ['#0000FF','#00FF00','#FF0000'];
const defaultvorticityColors = ['#0000FF','#00FF00','#FF0000'];
const defaultkelvin_vorticityColors = ['#0000FF','#00FF00','#FF0000'];
const defaulter_vorticityColors = ['#0000FF','#00FF00','#FF0000'];
const defaultmrg_vorticityColors = ['#0000FF','#00FF00','#FF0000'];
const defaultmjo_vorticityColors = ['#0000FF','#00FF00','#FF0000'];
const defaultdivergenceColors = ['#0000FF','#00FF00','#FF0000'];
const defaultkelvin_divergenceColors = ['#0000FF','#00FF00','#FF0000'];
const defaulter_divergenceColors = ['#0000FF','#00FF00','#FF0000'];
const defaultmrg_divergenceColors = ['#0000FF','#00FF00','#FF0000'];
const defaultmjo_divergenceColors = ['#0000FF','#00FF00','#FF0000'];
const defaultmslpColors = ['#0000FF','#00FF00','#FF0000'];
const defaultsstColors = ['#0000FF','#00FF00','#FF0000'];
const defaulttcwvColors = ['#0000FF','#00FF00','#FF0000'];
const defaultvimdColors = ['#0000FF','#00FF00','#FF0000'];
const defaultvelocity_potential = ['#0000FF','#00FF00','#FF0000'];
const defaultkelvin_velocity_potential = ['#0000FF','#00FF00','#FF0000'];
const defaulter_velocity_potential = ['#0000FF','#00FF00','#FF0000'];
const defaultmrg_velocity_potential = ['#0000FF','#00FF00','#FF0000'];
const defaultmjo_velocity_potential = ['#0000FF','#00FF00','#FF0000'];
const defaultstream_function = ['#0000FF','#00FF00','#FF0000'];
const defaultkelvin_stream_function = ['#0000FF','#00FF00','#FF0000'];
const defaulter_stream_function = ['#0000FF','#00FF00','#FF0000'];
const defaultmrg_stream_function = ['#0000FF','#00FF00','#FF0000'];
const defaultmjo_stream_function = ['#0000FF','#00FF00','#FF0000'];




const availableGradients = {
    "wind" : new Gradient(defaultwindColors),
    "kelvin_wind" : new Gradient(defaultkelvin_windColors),
    "mrg_wind" : new Gradient(defaultmrg_windColors),
    "er_wind" : new Gradient(defaulter_windColors),
    "mjo_wind" : new Gradient(defaultmjo_windColors),
    "surface_currents" : new Gradient(defaultsurface_currentsColor),
    "kelvin_surface_currents" : new Gradient(defaultkelvin_surface_currentsColor),
    "rossby_surface_currents" : new Gradient(defaultrossby_surface_currentsColor),
    "viwvf" : new Gradient(defaultviwvfColors),
    "er_viwvf" : new Gradient(defaulter_viwvfColors),
    "kelvin_viwvf" : new Gradient(defaultkelvin_viwvfColors),
    "mjo_viwvf" : new Gradient(defaultmjo_viwvfColors),
    "mrg_viwvf" : new Gradient(defaultmrg_viwvfColors),
    "rainfall" : new Gradient(defaultrainfallColors),
    "kelvin_rainfall" : new Gradient(defaultkelvin_rainfallColors),
    "mrg_rainfall" : new Gradient(defaultmrg_rainfallColors),
    "er_rainfall" : new Gradient(defaulter_rainfallColors),
    "mjo_rainfall" : new Gradient(defaultmjo_rainfallColors),
    "omiolr" : new Gradient(defaultomiolrColors),
    "sea_surface_heigh" : new Gradient(defaultsea_surface_heighColors),
    "kelvin_sea_surface_heigh" : new Gradient(defaultkelvin_sea_surface_heighColors),
    "rossby_sea_surface_heigh" : new Gradient(defaultrossby_sea_surface_heighColors),
    "vorticity" : new Gradient(defaultvorticityColors),
    "kelvin_vorticity" : new Gradient(defaultkelvin_vorticityColors),
    "er_vorticity" : new Gradient(defaulter_vorticityColors),
    "mrg_vorticity" : new Gradient(defaultmrg_vorticityColors),
    "mjo_vorticity" : new Gradient(defaultmjo_vorticityColors),
    "divergency" : new Gradient(defaultdivergenceColors),
    "kelvin_divergency" : new Gradient(defaultkelvin_divergenceColors),
    "er_divergency" : new Gradient(defaulter_divergenceColors),
    "mrg_divergency" : new Gradient(defaultmrg_divergenceColors),
    "mjo_divergency" : new Gradient(defaultmjo_divergenceColors),
    "mslp" : new Gradient(defaultmslpColors),
    "sst" : new Gradient(defaultsstColors),
    "tcwv" : new Gradient(defaulttcwvColors),
    "vimd" : new Gradient(defaultvimdColors),
    "velocity_potential" : new Gradient(defaultvelocity_potential),
    "kelvin_velocity_potential" : new Gradient(defaultkelvin_velocity_potential),
    "er_velocity_potential" : new Gradient(defaulter_velocity_potential),
    "mrg_velocity_potential" : new Gradient(defaultmrg_velocity_potential),
    "mjo_velocity_potential" : new Gradient(defaultmjo_velocity_potential),
    "stream_function" : new Gradient(defaultstream_function),
    "kelvin_stream_function" : new Gradient(defaultkelvin_stream_function),
    "er_stream_function" : new Gradient(defaulter_stream_function),
    "mrg_stream_function" : new Gradient(defaultmrg_stream_function),
    "mjo_stream_function" : new Gradient(defaultmjo_stream_function)
}

export const ExampleGradients = [
    new Gradient(['#F7FBFF', '#DEEBF7', '#C6DBEF', '#9ECAE1', '#6BAED6', '#4292C6', '#2171B5', '#084594'], 'CB_Blues_08'),
    new Gradient(['#F7FBFF', '#DEEBF7', '#C6DBEF', '#9ECAE1', '#6BAED6', '#4292C6', '#2171B5', '#08519C', '#08306B'], 'CB_Blues_09'),
    new Gradient(['#8C510A', '#BF812D', '#DFC27D', '#F6E8C3', '#C7EAE5', '#80CDC1', '#35978F', '#01665E'], 'CB_BrBG_08'),
    new Gradient(['#8C510A', '#BF812D', '#DFC27D', '#F6E8C3', '#F5F5F5', '#C7EAE5', '#80CDC1', '#35978F', '#01665E'], 'CB_BrBG_09'),
    new Gradient(['#543005', '#8C510A', '#BF812D', '#DFC27D', '#F6E8C3', '#C7EAE5', '#80CDC1', '#35978F', '#01665E', '#003C30'], 'CB_BrBG_10'),
    new Gradient(['#543005', '#8C510A', '#BF812D', '#DFC27D', '#F6E8C3', '#F5F5F5', '#C7EAE5', '#80CDC1', '#35978F', '#01665E', '#003C30'], 'CB_BrBG_11'),
    new Gradient(['#F7FCFD', '#E5F5F9', '#CCECE6', '#99D8C9', '#66C2A4', '#41AE76', '#238B45', '#005824'], 'CB_BuGn_08'),
    new Gradient(['#F7FCFD', '#E5F5F9', '#CCECE6', '#99D8C9', '#66C2A4', '#41AE76', '#238B45', '#006D2C', '#00441B'], 'CB_BuGn_09'),
    new Gradient(['#F7FCFD', '#E0ECF4', '#BFD3E6', '#9EBCDA', '#8C96C6', '#8C6BB1', '#88419D', '#6E016B'], 'CB_BuPu_08'),
    new Gradient(['#F7FCFD', '#E0ECF4', '#BFD3E6', '#9EBCDA', '#8C96C6', '#8C6BB1', '#88419D', '#810F7C', '#4D004B'], 'CB_BuPu_09'),
    new Gradient(['#F7FCF0', '#E0F3DB', '#CCEBC5', '#A8DDB5', '#7BCCC4', '#4EB3D3', '#2B8CBE', '#08589E'], 'CB_GnBu_08'),
    new Gradient(['#F7FCF0', '#E0F3DB', '#CCEBC5', '#A8DDB5', '#7BCCC4', '#4EB3D3', '#2B8CBE', '#0868AC', '#084081'], 'CB_GnBu_09'),
    new Gradient(['#F7FCF5', '#E5F5E0', '#C7E9C0', '#A1D99B', '#74C476', '#41AB5D', '#238B45', '#005A32'], 'CB_Greens_08'),
    new Gradient(['#F7FCF5', '#E5F5E0', '#C7E9C0', '#A1D99B', '#74C476', '#41AB5D', '#238B45', '#006D2C', '#00441B'], 'CB_Greens_09'),
    new Gradient(['#FFFFFF', '#F0F0F0', '#D9D9D9', '#BDBDBD', '#969696', '#737373', '#525252', '#252525'], 'CB_Greys_08'),
    new Gradient(['#FFFFFF', '#F0F0F0', '#D9D9D9', '#BDBDBD', '#969696', '#737373', '#525252', '#252525', '#000000'], 'CB_Greys_09'),
    new Gradient(['#FFF5EB', '#FEE6CE', '#FDD0A2', '#FDAE6B', '#FD8D3C', '#F16913', '#D94801', '#8C2D04'], 'CB_Oranges_08'),
    new Gradient(['#FFF5EB', '#FEE6CE', '#FDD0A2', '#FDAE6B', '#FD8D3C', '#F16913', '#D94801', '#A63603', '#7F2704'], 'CB_Oranges_09'),
    new Gradient(['#FFF7EC', '#FEE8C8', '#FDD49E', '#FDBB84', '#FC8D59', '#EF6548', '#D7301F', '#990000'], 'CB_OrRd_08'),
    new Gradient(['#FFF7EC', '#FEE8C8', '#FDD49E', '#FDBB84', '#FC8D59', '#EF6548', '#D7301F', '#B30000', '#7F0000'], 'CB_OrRd_09'),
    new Gradient(['#C51B7D', '#DE77AE', '#F1B6DA', '#FDE0EF', '#E6F5D0', '#B8E186', '#7FBC41', '#4D9221'], 'CB_PiYG_08'),
    new Gradient(['#C51B7D', '#DE77AE', '#F1B6DA', '#FDE0EF', '#F7F7F7', '#E6F5D0', '#B8E186', '#7FBC41', '#4D9221'], 'CB_PiYG_09'),
    new Gradient(['#8E0152', '#C51B7D', '#DE77AE', '#F1B6DA', '#FDE0EF', '#E6F5D0', '#B8E186', '#7FBC41', '#4D9221', '#276419'], 'CB_PiYG_10'),
    new Gradient(['#762A83', '#9970AB', '#C2A5CF', '#E7D4E8', '#D9F0D3', '#A6DBA0', '#5AAE61', '#1B7837'], 'CB_PRGn_08'),
    new Gradient(['#762A83', '#9970AB', '#C2A5CF', '#E7D4E8', '#F7F7F7', '#D9F0D3', '#A6DBA0', '#5AAE61', '#1B7837'], 'CB_PRGn_09'),
    new Gradient(['#40004B', '#762A83', '#9970AB', '#C2A5CF', '#E7D4E8', '#D9F0D3', '#A6DBA0', '#5AAE61', '#1B7837', '#00441B'], 'CB_PRGn_10'),
    new Gradient(['#FFF7FB', '#ECE2F0', '#D0D1E6', '#A6BDDB', '#67A9CF', '#3690C0', '#02818A', '#016450'], 'CB_PuBuGn_08'),
    new Gradient(['#FFF7FB', '#ECE2F0', '#D0D1E6', '#A6BDDB', '#67A9CF', '#3690C0', '#02818A', '#016C59', '#014636'], 'CB_PuBuGn_09'),
    new Gradient(['#FFF7FB', '#ECE7F2', '#D0D1E6', '#A6BDDB', '#74A9CF', '#3690C0', '#0570B0', '#034E7B'], 'CB_PuBu_08'),
    new Gradient(['#FFF7FB', '#ECE7F2', '#D0D1E6', '#A6BDDB', '#74A9CF', '#3690C0', '#0570B0', '#045A8D', '#023858'], 'CB_PuBu_09'),
    new Gradient(['#B35806', '#E08214', '#FDB863', '#FEE0B6', '#D8DAEB', '#B2ABD2', '#8073AC', '#542788'], 'CB_PuOr_08'),
    new Gradient(['#B35806', '#E08214', '#FDB863', '#FEE0B6', '#F7F7F7', '#D8DAEB', '#B2ABD2', '#8073AC', '#542788'], 'CB_PuOr_09'),
    new Gradient(['#7F3B08', '#B35806', '#E08214', '#FDB863', '#FEE0B6', '#D8DAEB', '#B2ABD2', '#8073AC', '#542788', '#2D004B'], 'CB_PuOr_10'),
    new Gradient(['#F7F4F9', '#E7E1EF', '#D4B9DA', '#C994C7', '#DF65B0', '#E7298A', '#CE1256', '#91003F'], 'CB_PuRd_08'),
    new Gradient(['#F7F4F9', '#E7E1EF', '#D4B9DA', '#C994C7', '#DF65B0', '#E7298A', '#CE1256', '#980043', '#67001F'], 'CB_PuRd_09'),
    new Gradient(['#FCFBFD', '#EFEDF5', '#DADAEB', '#BCBDDC', '#9E9AC8', '#807DBA', '#6A51A3', '#4A1486'], 'CB_Purples_08'),
    new Gradient(['#FCFBFD', '#EFEDF5', '#DADAEB', '#BCBDDC', '#9E9AC8', '#807DBA', '#6A51A3', '#54278F', '#3F007D'], 'CB_Purples_09'),
    new Gradient(['#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#D1E5F0', '#92C5DE', '#4393C3', '#2166AC'], 'CB_RdBu_08'),
    new Gradient(['#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#F7F7F7', '#D1E5F0', '#92C5DE', '#4393C3', '#2166AC'], 'CB_RdBu_09'),
    new Gradient(['#67001F', '#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#D1E5F0', '#92C5DE', '#4393C3', '#2166AC', '#053061'], 'CB_RdBu_10'),
    new Gradient(['#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#E0E0E0', '#BABABA', '#878787', '#4D4D4D'], 'CB_RdGy_08'),
    new Gradient(['#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#FFFFFF', '#E0E0E0', '#BABABA', '#878787', '#4D4D4D'], 'CB_RdGy_09'),
    new Gradient(['#67001F', '#B2182B', '#D6604D', '#F4A582', '#FDDBC7', '#E0E0E0', '#BABABA', '#878787', '#4D4D4D', '#1A1A1A'], 'CB_RdGy_10'),
    new Gradient(['#FFF7F3', '#FDE0DD', '#FCC5C0', '#FA9FB5', '#F768A1', '#DD3497', '#AE017E', '#7A0177'], 'CB_RdPu_08'),
    new Gradient(['#FFF7F3', '#FDE0DD', '#FCC5C0', '#FA9FB5', '#F768A1', '#DD3497', '#AE017E', '#7A0177', '#49006A'], 'CB_RdPu_09'),
    new Gradient(['#D73027', '#F46D43', '#FDAE61', '#FEE090', '#E0F3F8', '#ABD9E9', '#74ADD1', '#4575B4'], 'CB_RdYlBu_08'),
    new Gradient(['#D73027', '#F46D43', '#FDAE61', '#FEE090', '#FFFFBF', '#E0F3F8', '#ABD9E9', '#74ADD1', '#4575B4'], 'CB_RdYlBu_09'),
    new Gradient(['#A50026', '#D73027', '#F46D43', '#FDAE61', '#FEE090', '#E0F3F8', '#ABD9E9', '#74ADD1', '#4575B4', '#313695'], 'CB_RdYlBu_10'),
    new Gradient(['#D73027', '#F46D43', '#FDAE61', '#FEE08B', '#D9EF8B', '#A6D96A', '#66BD63', '#1A9850'], 'CB_RdYlGn_08'),
    new Gradient(['#D73027', '#F46D43', '#FDAE61', '#FEE08B', '#FFFFBF', '#D9EF8B', '#A6D96A', '#66BD63', '#1A9850'], 'CB_RdYlGn_09'),
    new Gradient(['#A50026', '#D73027', '#F46D43', '#FDAE61', '#FEE08B', '#D9EF8B', '#A6D96A', '#66BD63', '#1A9850', '#006837'], 'CB_RdYlGn_10'),
    new Gradient(['#FFF5F0', '#FEE0D2', '#FCBBA1', '#FC9272', '#FB6A4A', '#EF3B2C', '#CB181D', '#99000D'], 'CB_Reds_08'),
    new Gradient(['#FFF5F0', '#FEE0D2', '#FCBBA1', '#FC9272', '#FB6A4A', '#EF3B2C', '#CB181D', '#A50F15', '#67000D'], 'CB_Reds_09'),
    new Gradient(['#FFFFD9', '#EDF8B1', '#C7E9B4', '#7FCDBB', '#41B6C4', '#1D91C0', '#225EA8', '#0C2C84'], 'CB_YlGnBu_08'),
    new Gradient(['#FFFFD9', '#EDF8B1', '#C7E9B4', '#7FCDBB', '#41B6C4', '#1D91C0', '#225EA8', '#253494', '#081D58'], 'CB_YlGnBu_09'),
    new Gradient(['#FFFFE5', '#F7FCB9', '#D9F0A3', '#ADDD8E', '#78C679', '#41AB5D', '#238443', '#005A32'], 'CB_YlGn_08'),
    new Gradient(['#FFFFE5', '#F7FCB9', '#D9F0A3', '#ADDD8E', '#78C679', '#41AB5D', '#238443', '#006837', '#004529'], 'CB_YlGn_09'),
    new Gradient(['#FFFFE5', '#FFF7BC', '#FEE391', '#FEC44F', '#FE9929', '#EC7014', '#CC4C02', '#8C2D04'], 'CB_YlOrBr_08'),
    new Gradient(['#FFFFE5', '#FFF7BC', '#FEE391', '#FEC44F', '#FE9929', '#EC7014', '#CC4C02', '#993404', '#662506'], 'CB_YlOrBr_09'),
    new Gradient(['#FFFFCC', '#FFEDA0', '#FED976', '#FEB24C', '#FD8D3C', '#FC4E2A', '#E31A1C', '#B10026'], 'CB_YlOrRd_08'),
    new Gradient(['#FFFFCC', '#FFEDA0', '#FED976', '#FEB24C', '#FD8D3C', '#FC4E2A', '#E31A1C', '#BD0026', '#800026'], 'CB_YlOrRd_09'),
    new Gradient(['#00FFFF', '#FF00FF'], 'GMT_cool'),
    new Gradient(['#000000', '#FF9F66', '#FFC880'], 'GMT_copper'),
    new Gradient(['#86612A', '#EEC764', '#B4EE87', '#32EEEB', '#0C78EE', '#2601B7', '#083371'], 'GMT_drywet'),
]


export const getCurrentGradientOffsets = (dataType) => {
    return availableGradients[dataType].ColorsOffets;
}

export const getCurrentGradient = (dataType) => {
    return availableGradients[dataType].Colors;
}

export const setCurrentGradient = (dataType, colorsList) => {
    availableGradients[dataType] = new Gradient(colorsList);
}

export const setDefaultGradients = () => {
	availableGradients["wind"] = new Gradient(defaultwindColors);
	availableGradients["kelvin_wind"] = new Gradient(defaultkelvin_windColors);
	availableGradients["mrg_wind"] = new Gradient(defaultmrg_windColors);
	availableGradients["er_wind"] = new Gradient(defaulter_windColors);
	availableGradients["mjo_wind"] = new Gradient(defaultmjo_windColors);
	availableGradients["surface_currents"] = new Gradient(defaultsurface_currentsColor);
	availableGradients["kelvin_surface_currents"] = new Gradient(defaultkelvin_surface_currentsColor);
	availableGradients["rossby_surface_currents"] = new Gradient(defaultrossby_surface_currentsColor);
	availableGradients["viwvf"] = new Gradient(defaultviwvfColors);
    availableGradients["er_viwvf"] = new Gradient(defaulter_viwvfColors);
    availableGradients["kelvin_viwvf"] = new Gradient(defaultkelvin_viwvfColors);
    availableGradients["mjo_viwvf"] = new Gradient(defaultmjo_viwvfColors);
    availableGradients["mrg_viwvf"] = new Gradient(defaultmrg_viwvfColors);
	availableGradients["rainfall"] = new Gradient(defaultrainfallColors);
	availableGradients["kelvin_rainfall"] = new Gradient(defaultkelvin_rainfallColors);
	availableGradients["mrg_rainfall"] = new Gradient(defaultmrg_rainfallColors);
	availableGradients["er_rainfall"] = new Gradient(defaulter_rainfallColors);
	availableGradients["mjo_rainfall"] = new Gradient(defaultmjo_rainfallColors);
	availableGradients["omiolr"] = new Gradient(defaultomiolrColors);
	availableGradients["sea_surface_heigh"] = new Gradient(defaultsea_surface_heighColors);
	availableGradients["kelvin_sea_surface_heigh"] = new Gradient(defaultkelvin_sea_surface_heighColors);
	availableGradients["rossby_sea_surface_heigh"] = new Gradient(defaultrossby_sea_surface_heighColors);
	availableGradients["vorticity"] = new Gradient(defaultvorticityColors);
    availableGradients["kelvin_vorticity"] = new Gradient(defaultkelvin_vorticityColors);
    availableGradients["er_vorticity"] = new Gradient(defaulter_vorticityColors);
    availableGradients["mrg_vorticity"] = new Gradient(defaultmrg_vorticityColors);
    availableGradients["mjo_vorticity"] = new Gradient(defaultmjo_vorticityColors);
	availableGradients["divergency"] = new Gradient(defaultdivergenceColors);
    availableGradients["kelvin_divergency"] = new Gradient(defaultkelvin_divergenceColors);
    availableGradients["er_divergency"] = new Gradient(defaulter_divergenceColors);
    availableGradients["mrg_divergency"] = new Gradient(defaultmrg_divergenceColors);
    availableGradients["mjo_divergency"] = new Gradient(defaultmjo_divergenceColors);
	availableGradients["mslp"] = new Gradient(defaultmslpColors);
	availableGradients["sst"] = new Gradient(defaultsstColors);
	availableGradients["tcwv"] = new Gradient(defaulttcwvColors);
	availableGradients["vimd"] = new Gradient(defaultvimdColors);
    availableGradients["velocity_potential"] = new Gradient(defaultvelocity_potential);
    availableGradients["kelvin_velocity_potential"] = new Gradient(defaultkelvin_velocity_potential);
    availableGradients["er_velocity_potential"] = new Gradient(defaulter_velocity_potential);
    availableGradients["mrg_velocity_potential"] = new Gradient(defaultmrg_velocity_potential);
    availableGradients["mjo_velocity_potential"] = new Gradient(defaultmjo_velocity_potential);
    availableGradients["stream_function"] = new Gradient(defaultstream_function);
    availableGradients["kelvin_stream_function"] = new Gradient(defaultkelvin_stream_function);
    availableGradients["er_stream_function"] = new Gradient(defaulter_stream_function);
    availableGradients["mrg_stream_function"] = new Gradient(defaultmrg_stream_function);
    availableGradients["mjo_stream_function"] = new Gradient(defaultmjo_stream_function);
}


export const  getColor = (v, a, dataType) => {
    var color = availableGradients[dataType].getColorBytesAt(v);      
    return [color.r, color.g, color.b, a];
}
