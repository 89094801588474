import React from "react";
import Map from "./../Map";
import Menu from "./../Menu/Menu";
import SideMenu from "./../SideMenu/SideMenu";
import SpectralWeatherCalendar from "./../Widgets/Calendar";
import InformationContainer from "./../Widgets/Information";
import { Micro } from "./../../services/EngineService";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

function Layout() {
  return (
    <Container
      id="mainLayout"
      disableGutters={true}
      maxWidth={false}
      sx={{ position: "relative" }}
    >
      <Map />
      <SideMenu sx={{
          display:{
            xs: 'none',
            sm: 'none',
            md: 'flex',
            lg: 'flex',
            xl: 'flex'
          },
          position: "absolute",
          alignItems: "center",
          right: "0%",
          height: "75vh",
          maxHeight: "75vh",
          top: "12.5vh",
          marginRight: 1,
        }} />
      <SpectralWeatherCalendar
        sx={{
          transform: 'translate(-50%, 0)',
          position: "absolute",
          left: '50%',
          bottom: {
            xs: 16,
            sm: 16,
            md: 24,
            lg: 24,
            xl: 24,
          },
          display: {
            xs: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
        }}
      />
      <Grid
        container
        width="100vw"
        height="100vh"
        sx={{
          textAlign: "center",
          backgroundColor: "black",
          overflowY: "hidden",
        }}
      >
        <Grid container item>
          <Grid
            item
            xs={12}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <InformationContainer
              sx={{
                marginX: {
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3,
                  xl: 3,
                },
                marginTop: {
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3,
                  xl: 3,
                },
                display: {
                  xs: "none",
                  sm: "none",
                  md: "inherit",
                  lg: "inherit",
                  xl: "inherit",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems="end">
          <Grid item xs={12}>
            <Menu
              sx={{
                marginX: {
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3,
                  xl: 3,
                },
                marginBottom: {
                  xs: 2,
                  sm: 2,
                  md: 3,
                  lg: 3,
                  xl: 3,
                },
                width: "auto",
              }}
              screenWidth={Micro.View.width}
              screenHeight={Micro.View.height}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Layout;
