import {BaseScalarProduct} from "./BaseScalarProduct"

export class BaseMeanSeaLevelPressureProduct extends BaseScalarProduct{

    constructor(micro, attr){
        super(micro);
        this.setProductAttributes(attr.date, attr.dataType, attr.level);

        this.type = "MSLP";
        this.description = this.localize({
            name: {en: "MSLP"},
        });
        this.endpoint = this.apiEndpoint("scalar", 1000);
        this.units = [
            {label: "hPa", conversion: function(x) { return x ; }, precision: 1},
        ];
    }
}