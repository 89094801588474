import {BaseVertIntMoistureDivergenceProduct} from "./baseProducts/BaseVertIntMoistureDivergenceProduct"
import {getColor, getCurrentGradientOffsets} from '@spectralweather/common/services/PaletesService'

export class VertIntMoistureDivergenceProduct extends BaseVertIntMoistureDivergenceProduct{

    constructor(micro, attr, boundsMin, boundsMax){
        super(micro, attr);

        this.scale ={
            getColors: () =>{return getCurrentGradientOffsets(this.productAttributes.dataType)},
            bounds: [boundsMin,boundsMax],
            gradient: function(v, a) {                
                return  getColor(this.µ.scaleToZeroOne(this.scale.bounds[0], this.scale.bounds[1], this.µ.getValueFromRange(this.scale.bounds[0], this.scale.bounds[1],v)),
                                a, 
                                this.productAttributes.dataType
                                );
            }.bind(this)
        };
    }
}