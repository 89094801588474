import * as d3 from 'd3'
import _ from 'lodash'


export class ProductDataAnimator{
    DataField = null;
    Micro = null;
    Bounds = null;
    Buckets = [];
    ColorStyles = null;
    Particles = []
    Canvas = null;
    IsCanceled = false;
    PARTICLE_LINE_WIDTH = 1.0;            // line width of a drawn particle
    MAX_PARTICLE_AGE = 100;               // max number of frames a particle is drawn before regeneration

    constructor(dataField, micro, bounds, colorStyles, particleCount){
        this.DataField = dataField;
        this.Micro = micro;
        this.Bounds = bounds;
        this.ColorStyles = colorStyles;
        this.Buckets = this.ColorStyles.map(function() { return []; });

        this.Particles = [];
        for (var i = 0; i < particleCount; i++) {
            this.Particles.push(this.DataField.randomize({age: _.random(0, this.MAX_PARTICLE_AGE)}));
        }

        this.Canvas = d3.select("#animation").node().getContext("2d");
        this.Canvas.lineWidth = this.PARTICLE_LINE_WIDTH;
        this.Canvas.fillStyle = this.Micro.isFF() ? "rgba(0, 0, 0, 0.95)" : "rgba(0, 0, 0, 0.97)";  // FF Mac alpha behaves oddly
    }

    stopAnimation(){
        this.IsCanceled = true;
    }

    evolve() {
        this.Buckets.forEach(function(bucket) { bucket.length = 0; });
        this.Particles.forEach(particle => {
            if (particle.age > this.MAX_PARTICLE_AGE) {
                this.DataField.randomize(particle).age = 0;
            }
            var x = particle.x;
            var y = particle.y;
            var v = this.DataField.field(x, y);  // vector at current position
            var m = v[2];
            if (m === null) {
                particle.age = this.MAX_PARTICLE_AGE;  // particle has escaped the grid, never to return...
            }
            else {
                var xt = x + v[0];
                var yt = y + v[1];
                if (this.DataField.isDefined(xt, yt)) {
                    // Path from (x,y) to (xt,yt) is visible, so add this particle to the appropriate draw bucket.
                    particle.xt = xt;
                    particle.yt = yt;
                    this.Buckets[this.ColorStyles.indexFor(m)].push(particle);
                }
                else {
                    // Particle isn't visible, but it still moves through the field.
                    particle.x = xt;
                    particle.y = yt;
                }
            }
            particle.age += 1;
        });
    }

    draw() {
        // Fade existing particle trails.
        var prev = this.Canvas.globalCompositeOperation;
        this.Canvas.globalCompositeOperation = "destination-in";
        this.Canvas.fillRect(this.Bounds.x, this.Bounds.y, this.Bounds.width, this.Bounds.height);
        this.Canvas.globalCompositeOperation = prev;
  
        // Draw new particle trails.
        this.Buckets.forEach((bucket, i) => {
            if (bucket.length > 0) {
                this.Canvas.beginPath();
                this.Canvas.strokeStyle = this.ColorStyles[i];
                bucket.forEach(particle => {
                    this.Canvas.moveTo(particle.x, particle.y);
                    this.Canvas.lineTo(particle.xt, particle.yt);
                    particle.x = particle.xt;
                    particle.y = particle.yt;
                });
                this.Canvas.stroke();
            }
        });
    }
}