import React from 'react';
import * as d3 from 'd3'
import { Micro } from '../../services/EngineService'
import { GlobeSubject } from '@spectralweather/common/services/SubjectsService'
import { Menu as ContextMenu, Item, useContextMenu } from 'react-contexify';
import Divider from '@mui/material/Divider';
import Globe from './../Globe'
import './Map.css'
import 'react-contexify/dist/ReactContexify.css';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {dateNoTimeToString} from '@spectralweather/common/utils/DateUtils';
import SpectralWeatherContainer from '@spectralweather/common/components/Containers/SpectralWeatherContainer';
import Typography from "@mui/material/Typography";
import {
  ExampleGradients,
} from "@spectralweather/common/services/PaletesService";
import { applyExampleGradient } from "../../services/EngineService"
import { makeStyles } from "@spectralweather/common/theme/theme";
import store from '../../store/Store'

const MAP_CONTEXT_MENU_ID = 'MapContextMenu';

const useStyles = makeStyles()((theme) => {
  return {
    exampleGradient: {
      alignSelf: "center",
      cursor: "pointer",
      width: "100%",
      height: "20px",
    },
  };
});

function Map() {
  const { classes } = useStyles();
  const [selectedLon, setSelectedLon] = React.useState(0);
  const [selectedLat, setSelectedLat] = React.useState(0);
  const [openExamplePalettes, setOpenExamplePalettes] = React.useState(false);
  const handleOpenModal = () => setOpenExamplePalettes(true);
  const handleCloseModal = () => setOpenExamplePalettes(false);
  
  const { show } = useContextMenu({
    id: MAP_CONTEXT_MENU_ID,
  });

  function handleContextMenu(event) {
    var bounds = GlobeSubject.value.bounds(Micro.View);
    let [x, y] = d3.pointer(('ontouchstart' in window && event.sourceEvent instanceof TouchEvent) ? event.sourceEvent.touches[0] : event);
    var lon, lat
    [lon, lat] = GlobeSubject.value.projection.invert([x, y]);
    lon = Number(lon);
    lat = Number(lat);
    if (x <= bounds.xMax && x >= bounds.x && y <= bounds.yMax && y >= bounds.y) {
      event.preventDefault();
      setSelectedLon(lon);
      setSelectedLat(lat);
      show(event);
    }
  }

  const showCoordinates = () => {
    store.dispatch({
      type: 'globe/setCoordinates',
      value: [selectedLon, selectedLat]
    });
    store.dispatch({
      type: "utility/setInfoTooltip",
      value: true,
    });
  };

  const showHovmollerDiagram = () => {
    const state = store.getState()
    var dataType = state.vector.vectorData;
    var pressure = state.vector.vectorPressure;
    var endDate = state.vector.vectorTime;
    var startDate = new Date(endDate);
    startDate.setDate(endDate.getDate()-14);
    var baseUrl = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/Hovmoller';
    // var baseUrl = 'https://localhost:3000/Hovmoller';
    var queryParams = `dataType=${dataType}&pressure=${pressure}&dateStart=${encodeURIComponent(dateNoTimeToString(startDate))}&dateEnd=${encodeURIComponent(dateNoTimeToString(endDate))}`;
    var url = `${baseUrl}?${queryParams}`;
    window.open(url, '_blank').focus();
  }

  const changeColorsPalette = () => {
    handleOpenModal();
  }

  const showGradient = (gradient, id) => {
    let gradientId = "" + Math.random().toString(36).substr(2, 9);
    return (
      <Box sx={{marginBottom:1, display: 'inline-flex'}}>
        <Typography sx={{width:200}}>{gradient.Name}</Typography>
        <svg
          onClick={() => {
            applyExampleGradient(id);
          }}
          className={classes.exampleGradient}
          key={id}
        >
          <defs>
            <linearGradient
              key={gradientId}
              id={gradientId}
              x1="0"
              y1="0.5"
              x2="1"
              y2="0.5"
            >
              {gradient.ColorsOffets.map(({ id, offset, color }) => (
                <stop key={id} offset={offset} style={{ stopColor: color }} />
              ))}
            </linearGradient>
          </defs>
          <rect
            x="0"
            y="0"
            width="100%"
            height="100%"
            fill={`url(#${gradientId})`}
          />
        </svg>
      </Box>

    );
  }

  return (
    <div onContextMenu={handleContextMenu}>
      <div id="display">
        <Globe />
        <canvas id="animation" className="fill-screen"></canvas>
        <canvas id="overlay" className="fill-screen"></canvas>
        <svg id="contours" className="fill-screen" xmlns="http://www.w3.org/2000/svg" version="1.1"></svg>
        <svg id="foreground" className="fill-screen" xmlns="http://www.w3.org/2000/svg" version="1.1"></svg>
      </div>
      <ContextMenu id={MAP_CONTEXT_MENU_ID}>
        <Item onClick={showCoordinates}>More information</Item>
        <Item onClick={showHovmollerDiagram}>Hovmöller diagram</Item>
        <Divider />
        <Item onClick={changeColorsPalette}>Colors palette</Item>
      </ContextMenu>
      <Modal
        open={openExamplePalettes}
        onClose={handleCloseModal}
      >
        <SpectralWeatherContainer onCloseClick={() => handleCloseModal()}
          containerStyle={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 750,
            height: 600,
            overflowY: "overlay",
          }}>
          <Grid container sx={{ p: 4 }}>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center", marginBottom:2 }} variant="h4" >Select favorite palette</Typography>
            </Grid>
            <Grid container spacing={2} item xs={12}>
              <Grid item xs={6}>
                {ExampleGradients.slice(0, Math.floor(ExampleGradients.length / 2)).map((gradient, id) => {
                  return showGradient(gradient, id);
                })}
              </Grid>
              <Grid item xs={6}>
                {ExampleGradients.slice(Math.floor(ExampleGradients.length / 2)).map((gradient, id) => {
                  return showGradient(gradient, Math.floor(ExampleGradients.length / 2) + id);
                })}
              </Grid>
            </Grid>
          </Grid>
        </SpectralWeatherContainer>
      </Modal>
    </div>);
}

export default Map;
