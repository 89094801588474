import React, { Component } from "react";
import ColumnLabel from "../ColumnLabel";
import ButtonsContainer from "../ButtonsContainer";
import Grid from "@mui/material/Grid"

class AllDataFieldsRow extends Component {
  render() {
    return (
      <Grid item container>
        <Grid item xs={2}>
          <ColumnLabel menuLabel="Data" />
        </Grid>
        <Grid item xs={10}>
          <ButtonsContainer groupContainer={this.props.buttonModels} />
        </Grid>
      </Grid>
    );
  }
}

export default AllDataFieldsRow;
