import React, { Component } from "react";
import ButtonsContainer from "../ButtonsContainer";
import Grid from "@mui/material/Grid";
import ColumnLabel from "../ColumnLabel";

class VectorFieldsRow extends Component {
  render() {
    return (
      <Grid item container>
        <Grid item xs={12} md={2}>
          <ColumnLabel sx={{alignText: {xs:"left", md:"center"}}} menuLabel="Vectors" />
        </Grid>
        <Grid item xs={12} md={10}>
          <ButtonsContainer groupContainer={this.props.buttonModels} />
        </Grid>
      </Grid>
    );
  }
}

export default VectorFieldsRow;
