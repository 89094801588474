import React from 'react';
import Box from "@mui/material/Box";
import { DecrementByHoursDate, Calendar, IncrementByHoursDate } from '../../Menu/DateRow/DateRow';


export default function SpectralWeatherCalendar(props) {
  return (
    <Box sx={{
      backgroundColor: 'sw.primaryBackground',
      ...props.sx
    }}>
      <DecrementByHoursDate actionType='global' fontSize={36} DecrementBy="24">-24H</DecrementByHoursDate>
      <DecrementByHoursDate actionType='global' fontSize={24} DecrementBy="6">-6H</DecrementByHoursDate>
      <Calendar actionType='global' fontSize={36} />
      <IncrementByHoursDate actionType='global' fontSize={24} IncrementBy="6">+6H</IncrementByHoursDate>
      <IncrementByHoursDate actionType='global' fontSize={36} IncrementBy="24">+24H</IncrementByHoursDate>
    </Box>)
}


