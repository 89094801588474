import React from 'react';
import {BaseTextButton, BaseButton} from '@spectralweather/common/components/Buttons/BaseButton/BaseButton';

export default function SelectButton({ children,onClick,isSelected, ...rest }) {
  if(isSelected === true || isSelected ==='true'){
    return <BaseButton {...rest} onClick={onClick}>{children}</BaseButton>
  }
  else{
    return <BaseTextButton {...rest} onClick={onClick}>{children}</BaseTextButton>   
  }
}

