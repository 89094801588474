import {BaseVectorProduct} from "./BaseVectorProduct"

export class BaseVertIntWaterVapourProduct extends BaseVectorProduct{

    constructor(micro, attr){
        super(micro);
        this.setProductAttributes(attr.date, attr.dataType, attr.level);

        this.type = "VIWVF";
        this.description = this.localize({
            name: {en: "VIWVF"},
        });
        this.endpoint = this.apiEndpoint("vector", 1000);
        this.units = [
            {label: "kg/(m*s)",  conversion: function(x) { return x; }, precision: 1},
        ];
    }

    

    

}