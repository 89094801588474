export class VectorProductBuilder{
    uData = null;
    vData = null;
    header = null;
    interpolate = null;

    constructor(file, interpolationMethod){
        this.uData = file[0].data;
        this.vData = file[1].data;
        this.header = file[0].header;

        this.interpolate = interpolationMethod;
    }

    data(i){
        return [this.uData[i], this.vData[i]];
    }

    dataValue(i){
        var data = this.data(i);
        var u = data[0];
        var v = data[1];
        return Math.sqrt(u * u + v * v);
    }
}