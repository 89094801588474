export class ScalarProductBuilder{
    scalarData = null;
    header = null;
    interpolate = null;

    constructor(file, interpolationMethod){
        var record = file[0];
        var scalingFactor = record.header["scalingFactor"];
        this.scalarData = scalingFactor ? record.data.map(x => x / scalingFactor) : record.data;
        this.header = file[0].header;

        this.interpolate = interpolationMethod;
    }

    data(i){
        return [this.scalarData[i]];
    }

    dataValue(i){
        return this.data(i)[0];
    }
}