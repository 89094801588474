import Button from "@mui/material/Button";
import React, { Component } from "react";
import { withStyles } from "@spectralweather/common/theme/theme";
import Icon from "@mui/material/Icon";

const ReactIconButton = withStyles(Button, (theme) => ({
  root: {
    minWidth: 10,
    margin: 0,
    color: theme.palette.sw.icon,
    padding: 0,
    border: "transparent",
    "&:hover": {
      color: theme.palette.sw.primaryText,
      backgroundColor: "transparent",
    },
  },
}));

const PressedIconButton = withStyles(Button, (theme) => ({
  root: {
    minWidth: 10,
    margin: 0,
    color: theme.palette.sw.primaryText,
    padding: 0,
  },
}));

const IconButton = (props) => {
  const { sx, ...otherProps } = props;
  return (
    <Button sx={{
      minWidth: '10px',
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: 0,
      color: 'sw.icon',
      padding: 0,
      border: "transparent",
      "&:hover": {
        color: 'sw.primaryText',
        backgroundColor: "transparent",
      },
      ...props.sx
    }} {...otherProps}>
      <Icon style={{ fontSize: props.fontSize }}>
        {props.children}
      </Icon>
    </Button>
  );
};

export class ToggleIconButton extends Component {
  constructor() {
    super();
    this.state = {
      isPressed: false,
    };
  }
  render() {
    const { fontSize, onClick, pressedIcon, releasedIcon } = this.props;
    if (this.state.isPressed) {
      return (
        <PressedIconButton fontSize={fontSize} onClick={onClick}>
          <Icon
            style={{ fontSize: this.props.fontSize }}
            onClick={() => this.setState({ isPressed: !this.state.isPressed })}
          >
            {pressedIcon}
          </Icon>
        </PressedIconButton>
      );
    }
    return (
      <ReactIconButton fontSize={fontSize} onClick={onClick}>
        <Icon
          style={{ fontSize: this.props.fontSize }}
          onClick={() => this.setState({ isPressed: !this.state.isPressed })}
        >
          {releasedIcon}
        </Icon>
      </ReactIconButton>
    );
  }
}

export default IconButton;
