import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import {makeStyles, withStyles} from '@spectralweather/common/theme/theme' 


export const BaseTextButton = withStyles(Button, (theme) => ({
  root: {
    minWidth: 20,
    color: theme.palette.sw.secondaryText,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 2,
    paddingLeft: 2,
    borderRadius: 2,
    textTransform: "none",
    letterSpacing: 0,
    fontWeight: 1,
    fontSize: "1rem",
    lineHeight: "inherit",
    backgroundColor: "transparent",
    '&:hover': {
      color: theme.palette.sw.primaryText,
      backgroundColor: theme.palette.sw.secondaryBackground,
    },
  },
}));

export const BaseButton = withStyles(BaseTextButton, (theme) => ({
  root: {
    color: theme.palette.sw.primaryText,
    borderStyle: "solid",
    borderColor: theme.palette.sw.primaryBorder,
    borderWidth: "1px",
    borderRadius: 0
  },
}));

export const BaseGreyButton = withStyles(BaseButton,(theme) => ({
  root: {
    color: theme.palette.sw.primaryText,
    backgroundColor: grey[700],
    '&:hover': {
      backgroundColor: grey[600],
    },
  },
}));

export default BaseButton