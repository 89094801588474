
import Typography from "@mui/material/Typography"
import {withStyles} from '@spectralweather/common/theme/theme' 

const LabelTypography = withStyles(Typography,(theme) => ({
  root: {
    color: theme.palette.sw.primaryText,
    padding: theme.spacing(0)
  },
}));


export default function ColumnLabel({ menuLabel, sx }) {
  return (
    <LabelTypography sx={{textAlign: {xs: "center", md:"left"}, fontWeight:{xs:800, md: 400}, ...sx}}>{menuLabel}</LabelTypography>
  )  
}



