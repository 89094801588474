import React, { useState, useEffect } from "react";
import SpectralWeatherContainer from "@spectralweather/common/components/Containers/SpectralWeatherContainer";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { Micro } from "./../../../services/EngineService";
import * as d3 from "d3";
import store from "../../../store/Store";

export default function InformationContainer(props) {
  const vectorTime = useSelector((state) => state.vector.vectorTime);
  const scalarTime = useSelector((state) => state.scalar.scalarTime);
  const contoursTime = useSelector((state) => state.contours.contoursTime);
  const contoursState = useSelector((state) => state.contours.contoursState);
  const coordinates = useSelector((state) => state.globe.coordinates);
  const [coordinatesLocation, setCoordinatesLocation] = useState("");
  const isOpen = useSelector((state) => state.utility.showInfoTooltip);
  const scalarValue = useSelector((state) => state.utility.scalarValue);
  const units = useSelector((state) => state.utility.units);
  const handleClose = () =>
    store.dispatch({
      type: "utility/setInfoTooltip",
      value: false,
    });

  useEffect(() => {
    if (!coordinates || coordinates.length < 2) {
      return;
    }
    d3.json(
      "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&location=" +
        coordinates[0] +
        "," +
        coordinates[1]
    )
      .then((result) => {
        var place = result["address"]["LongLabel"];
        setCoordinatesLocation(place);
      })
      .catch((reasone) => console.log(reasone));
  }, [coordinates]);

  return (
    <SpectralWeatherContainer
      containerStyle={{
        p: 2,
        width: "300px",
        ...props.sx,
        display: isOpen ? "inherit" : "none",
      }}
      onCloseClick={() => handleClose()}
    >
      {vectorTime.getTime() === scalarTime.getTime() &&
      scalarTime.getTime() === contoursTime.getTime() ? (
        <Typography sx={{ color: "sw.primaryText", textAlign: "left" }}>
          Date: {Micro.toUTCISO(vectorTime)}
        </Typography>
      ) : (
        <>
          <Typography sx={{ color: "sw.primaryText", textAlign: "left" }}>
            Vectors date: {Micro.toUTCISO(vectorTime)}
          </Typography>
          <Typography sx={{ color: "sw.primaryText", textAlign: "left" }}>
            Scalars date: {Micro.toUTCISO(scalarTime)}
          </Typography>{" "}
          {contoursState !== "hide" && (
            <Typography sx={{ color: "sw.primaryText", textAlign: "left" }}>
              Contours date: {Micro.toUTCISO(contoursTime)}
            </Typography>
          )}
        </>
      )}
      <br />

      {scalarValue != null && units != null && (
        <>
          <Typography sx={{ color: "sw.primaryText", textAlign: "left" }}>
            {Micro.formatScalar(scalarValue, units)}{' '}{units.label}
          </Typography>
        </>
      )}

      {coordinates && coordinates.length > 0 && (
        <>
          <Typography sx={{ color: "sw.primaryText", textAlign: "left" }}>
            {Micro.formatCoordinates(coordinates[0], coordinates[1])}
          </Typography>
          <Typography sx={{ color: "sw.primaryText", textAlign: "left" }}>
            {coordinatesLocation}
          </Typography>
        </>
      )}
    </SpectralWeatherContainer>
  );
}
