import React, { useState, useEffect } from "react";
import BaseButton from "@spectralweather/common/components/Buttons/BaseButton/BaseButton";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import { ContoursSettingsSubject } from "@spectralweather/common/services/SubjectsService";
import { filter } from "rxjs/operators";
import SettingsRow from "./SettingsRow";
import { BlockPicker } from "react-color";
import { makeStyles } from "@spectralweather/common/theme/theme";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";

const useStyles = makeStyles()((theme) => {
  return {
    colorPicker: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      borderRadius: "0px !important",
      "& > div:nth-of-type(2)": {
        height: "40px !important",
        borderRadius: "0px !important",
      },
      "& > div:nth-of-type(3)": {
        backgroundColor: theme.palette.sw.inputBackground,
        paddingBottom: "0px !important",
        "& > div:nth-of-type(2)": {
          contentVisibility: "hidden !important",
        },
      },
    },
  };
});

export default function ContoursSettings() {
  const { classes } = useStyles();
  const [numberOfContours, setNumberOfContours] = useState(20);
  const [contoursSettingsVisible, setContoursSettingsVisible] = useState(false);
  const [contoursPaletteVisible, setContoursPaletteVisible] = useState(false);
  const [contoursColor, setContoursColor] = useState("#b80000");

  useEffect(() => {
    var subscription = ContoursSettingsSubject.asObservable()
      .pipe(filter((val) => val))
      .subscribe((settings) => {
        setNumberOfContours(settings.maxLevels);
        setContoursColor(settings.color);
      });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const toggleContoursSettingsVis = () => {
    setContoursSettingsVisible(!contoursSettingsVisible);
  };

  const applyContoursSettings = () => {
    if (ContoursSettingsSubject.value) {
      ContoursSettingsSubject.value.maxLevels = Number(numberOfContours);
      ContoursSettingsSubject.value.color = contoursColor;
      ContoursSettingsSubject.next(ContoursSettingsSubject.value);
    }
  };

  const handleChange = (color) => {
    setContoursColor(color.hex);
  };

  return (
    <Grid
      container
      rowSpacing={0.5}
      sx={{ paddingTop: 1, paddingX: 2, paddingBottom: 1 }}
    >
      <Grid item xs={12}>
        <BaseButton
          style={{ width: "100%" }}
          onClick={() => toggleContoursSettingsVis()}
        >
          CONTOURS
        </BaseButton>
      </Grid>
      <Fade
        in={contoursSettingsVisible}
        style={{
          display: contoursSettingsVisible ? "block" : "none",
        }}
      >
        <Grid item xs={12}>
          <SettingsRow
            settingsVal={numberOfContours}
            menuLabel="Count"
            units={null}
            handleChange={(event) => setNumberOfContours(event.target.value)}
          />
          <SettingsRow
            settingsVal={contoursColor}
            menuLabel="Color"
            units={null}
            handleChange={(event) => setContoursColor(event.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <Icon onClick={() => setContoursPaletteVisible(!contoursPaletteVisible)} sx={{ color: contoursColor, "&:hover": { cursor: "pointer" } }}>
                  palette
                </Icon>
              </InputAdornment>
            }
          />
                <Fade
        in={contoursPaletteVisible}
        style={{
          display: contoursPaletteVisible ? "block" : "none",
        }}
      >
          <Grid item xs={12}>
            <BlockPicker
              color={contoursColor}
              onChangeComplete={handleChange}
              className={classes.colorPicker}
              triangle="hide"
              width="auto"
              colors={[
                "#B80000",
                "#DB3E00",
                "#FCCB00",
                "#008B02",
                "#006B76",
                "#1273DE",
                "#004DCF",
                "#5300EB",
                "#CCCCCC",
                "#B3B3B3",
                "#999999",
                "#808080",
                "#666666",
                "#4D4D4D",
                "#333333",
                "#000000"
              ]}
            />
          </Grid>
      </Fade>

          <Grid item xs={12}>
            <BaseButton
              style={{ width: "100%" }}
              onClick={applyContoursSettings}
            >
              APPLY
            </BaseButton>
          </Grid>
        </Grid>
      </Fade>
    </Grid>
  );
}
