export const InitialState = {
    projection: "orthographic",
    globeOrientation: {
        value: '',
        source: ''
    },
    coordinates: '',
}

export default function globeDataReducer(state = InitialState, action) {
    switch (action.type) {
        case 'globe/setProjection': {
            return {
                ...state,
                globeProjection: action.value
            }
        }
        case 'globe/setOrientation': {
            return {
                ...state,
                globeOrientation: {
                    value: action.value,
                    source: action.source
                }
            }
        }
        case 'globe/setCoordinates': {
            return {
                ...state,
                coordinates: action.value
            }
        }
        default:
            return state
    }
}
