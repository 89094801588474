import React from "react";
import Box from '@mui/material/Box'
import SplitButton from "../../Buttons/SplitButton";
import MenuSelectButton from "../../Buttons/MenuSelectButton";
import { makeStyles } from "@spectralweather/common/theme/theme";

const useStyles = makeStyles()((theme) => ({
  leftSided: {
    marginRight: theme.spacing(1)
  },
}));

function ButtonsContainer(props) {
  const {classes} = useStyles();
  const { groupContainer} = props;
  return (
    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: {xs: "center", md:"flex-start"}}}>
      {groupContainer.items.map((value, index) => {
        if (value.items.length > 1) {
          return (
            <SplitButton
              className={classes.leftSided}
              key={index}
              Items={value.items}
              actionType={props.groupContainer.code}
            />
          );
        } else {
          return (
            <Box className={classes.leftSided} key={index}>
              <MenuSelectButton
                isSelected={value.items[0].isSelected}
                actionType={groupContainer.code}
                itemCode={value.items[0].code}
              >
                {value.items[0].name}
              </MenuSelectButton>
            </Box>
          );
        }
      })}
    </Box>
  );
}

export default ButtonsContainer;
