import Typography from '@mui/material/Typography';
import {withStyles} from '@spectralweather/common/theme/theme' 


export const BaseText = withStyles(Typography, (theme) => ({
  root: {
    color: theme.palette.sw.primaryText,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 2,
    paddingLeft: 2,
    textTransform: "none",
    letterSpacing: 0,
    fontWeight: 1,
    fontSize: "1rem",
    lineHeight: "inherit",
    backgroundColor: null,
  },
}));

export const SecondaryText = withStyles(Typography, (theme) => ({
    root: {
      color: theme.palette.sw.secondaryText,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 2,
      paddingLeft: 2,
      textTransform: "none",
      letterSpacing: 0,
      fontWeight: 1,
      fontSize: "1rem",
      lineHeight: "inherit",
      backgroundColor: null,
      '&:hover': {
        color: theme.palette.sw.primaryText,
        backgroundColor: theme.palette.sw.secondaryBackground,
      },
    },
  }));

export default BaseText;