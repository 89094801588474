import {Micro as M} from "@spectralweather/common/utils/Micro"
import * as d3 from 'd3'


export class GlobeMask {
    Micro = null;
    Data = null;
    ImageData = null;

    constructor (globe, micro){
        this.Micro = micro || new M();
        this.Micro.Log.time("render mask");
  
        // Create a detached canvas, ask the model to define the mask polygon, then fill with an opaque color.
        var canvas = d3.select(document.createElement("canvas")).attr("width", this.Micro.View.width).attr("height", this.Micro.View.height).node();
        var context = globe.defineMask(canvas.getContext("2d"));
        context.fillStyle = "rgba(255, 0, 0, 1)";
        context.fill();
      
        this.ImageData = context.getImageData(0, 0, this.Micro.View.width, this.Micro.View.height);
        this.Data = this.ImageData.data;  // layout: [r, g, b, a, r, g, b, a, ...]
        this.Micro.Log.timeEnd("render mask");
    }

    isVisible(x, y) {
        var i = (y * this.Micro.View.width + x) * 4;
        return this.Data[i + 3] > 0;  // non-zero alpha means pixel is visible
    }

    set(x, y, rgba) {
        var i = (y * this.Micro.View.width + x) * 4;
        this.Data[i    ] = rgba[0];
        this.Data[i + 1] = rgba[1];
        this.Data[i + 2] = rgba[2];
        this.Data[i + 3] = rgba[3];
        return this;
    }
}
