import {Conrec} from "@spectralweather/common/utils/Conrec"
import {geoPath} from "d3-geo";
import * as d3 from 'd3'
import {contrastColor} from 'contrast-color'

export class ContouringUtils{
	getBoundingBox(singleContour) {
        let temp_min_x, temp_min_y, temp_max_x, temp_max_y;
		temp_min_x = temp_min_y = 400;
		temp_max_x = temp_max_y = 0;
		for (let i=0; i < singleContour.length; i++) {
			temp_min_x = Math.min(temp_min_x, singleContour[i].x)
			temp_min_y = Math.min(temp_min_y, singleContour[i].y)
			temp_max_x = Math.max(temp_max_x, singleContour[i].x)
			temp_max_y = Math.max(temp_max_y, singleContour[i].y)
		}
		return {
			'min': {'x': Math.round(temp_min_x), 'y': Math.round(temp_min_y)}, //Rounding to get the closest values
			'max': {'x': Math.round(temp_max_x), 'y': Math.round(temp_max_y)}  //Rounding to get the closest values
		}
	}

	getAllBoundingBoxes(contours) {
		let results_ar = []
			for (let x in contours) {
				let o = this.getBoundingBox(contours[x])
				results_ar.push(o)
			}
			return results_ar
	}

	getDistance(point1, point2) {
		let normal = Math.sqrt(Math.pow((point2.x - point1.x), 2) + Math.pow((point2.y - point1.y), 2));
		let higherY = Math.max(point2.y, point1.y );
		let lowerY = Math.min(point2.y, point1.y );

		let minus360 = Math.sqrt(Math.pow((point2.x - point1.x), 2) + Math.pow((lowerY - (higherY - 360)), 2));
		return Math.min(normal, minus360);
	}

    getDeg(nextPoint, currentPoint) {
        var deltaX = nextPoint.x - currentPoint.x;
        var deltaY = nextPoint.y - currentPoint.y;
        return Math.atan((deltaY) / (deltaX)) * 180 / 3.14;
    }

	toProjectedPointValues(temp_arr, globe){
		let full_array = [];
		for (let i = 0; i < temp_arr.length; i++) {
			let points_arr = [];
			for (let j = 0; j < temp_arr[i].length; j++) {
				let temp_x = temp_arr[i][j].x;
				let temp_y = temp_arr[i][j].y;
				let projected = globe.projection([temp_y, 30 - temp_x]);
				let point = {'x': projected[0], 'y': projected[1] , 'x_inv': temp_y ,'y_inv': temp_x}
				points_arr.push(point);
			}
			points_arr.level = temp_arr[i].level;
			full_array.push(points_arr);
		}
		return full_array
	}
}

export class ContourSettings{
    defaultInterval = 2;
    maxLevels = 20;
    color = '#FFFFE0';

    constructor(interval, levels, color){
        this.defaultInterval = interval;
        this.maxLevels = levels;
        this.color = color;
    }
}

export class ContourBuilder{
    /**
     * Contouring algorithm implementation
     */
	contouringObject = new Conrec();
	/**
     * Contouring metadata
     */
	metadata = {}
	/**
     * Contouring settings
     */
	globe = null;
	contouringSettings = {}
	dataArray = []
	contourLevels = null;
	utilities = new ContouringUtils();

	dimConf = {
        HL_markHeight: {
            min: 9,
            max: 40
        },
        HL_valueHeight: {
            min: 7,
            max: 20
        },
        HL_separatorHeight: {
            min: 7,
            max: 20
        },
        contourLabelHeight: {
            min: 5,
            max: 15,
        },
        contourLabelWidth: {
            min: 10,
            max: 35,
        },
        contourLabelText: {
            min: 4.8,
            max: 12,
        },
        contourLabelTextXMove: {
            min: 0.5,
            max: 2.5,
        },
        contourLabelTextYMove: {
            min: 4,
            max: 11,
        }
    };

	scalers = {};

	constructor(data, globe, metadata, settings){
        this.data = data;
		this.globe = globe;
		this.metadata = metadata;

		this.contouringSettings = settings; // Maybe it should be stored in product factory rather? It could be customized then
		this.dataArray = data;
		this.contourLevels = this.getContourLevels(this.contouringSettings, this.metadata);

		this.scalers = {
			markScaler: this._globeScaler().range([this.dimConf.HL_markHeight.min, this.dimConf.HL_markHeight.max]),
			valueScaler: this._globeScaler().range([this.dimConf.HL_valueHeight.min, this.dimConf.HL_valueHeight.max]),
			sepScaler: this._globeScaler().range([this.dimConf.HL_separatorHeight.min, this.dimConf.HL_separatorHeight.max]),
			labelHeight: this._globeScaler().range([this.dimConf.contourLabelHeight.min, this.dimConf.contourLabelHeight.max]),
			labelWidth: this._globeScaler().range([this.dimConf.contourLabelWidth.min, this.dimConf.contourLabelWidth.max]),
			labelText: this._globeScaler().range([this.dimConf.contourLabelText.min, this.dimConf.contourLabelText.max]),
			labelTextXMove: this._globeScaler().range([this.dimConf.contourLabelTextXMove.min, this.dimConf.contourLabelTextXMove.max]),
			labelTextYMove: this._globeScaler().range([this.dimConf.contourLabelTextYMove.min, this.dimConf.contourLabelTextYMove.max]),
		};
        this.addContoursToMap();
	}

    getContoursList(){
        return this.contouringObject.contourList();
    }


    getContourLevels(settings, metadata) {
        let minimumLevel = metadata.dataMin;
        let maximumLevel = metadata.dataMax;
        let interval = settings.defaultInterval
        let diff = Math.abs(metadata.dataMax - metadata.dataMin);

        if(diff < 0.01){
            interval = (diff / settings.maxLevels)
        }
        else if(diff < 5){
            interval = (diff / settings.maxLevels).toFixed(2)
        } 
        else{
            interval = (diff / settings.maxLevels).toFixed(0)
            minimumLevel = Math.ceil(metadata.dataMin);     // ceiling here, as under this threshold, markups will be added
            maximumLevel = Math.floor(metadata.dataMax);    // flooring here for same reason
        }
        // HACK: For some reason Conrec doesnt work well on grid where there are same values in neighborhood. Simple workaround is to omit this level. Thats why some small number is added.
        // Its not elegant and could fail for different data types
        // TODO: Consider improving it: https://debrief.github.io/tutorial/contouring_algorithm.html
        let negligibleVal = diff * 0.01; 
        let lvls = d3.range(minimumLevel + negligibleVal, maximumLevel, interval);
        
        return {
            levels: lvls, 
            nbOfLevel: lvls.length
        }
    };
    

    getGeoFeatureCollection(contours) {
        let ftCol = [];
        for (const singleContour in contours) {
            let geoJson = {
                "type": "Feature",
                "properties": {
                    "name": "Contour"
                },
                "geometry": {
                    "type": "LineString",
                    "coordinates": []
                }
            }
            let cur = geoJson;
            let coordinates = contours[singleContour];
            let demandedCoords = [];
            for(let coord in coordinates) {
                demandedCoords.push([coordinates[coord].y, (this.metadata.ny - 1)/2 - coordinates[coord].x])
            }
            cur.geometry.coordinates = demandedCoords
            cur.properties.level = coordinates.level
            ftCol.push(cur)
        }

        let full = {
            type: "FeatureCollection",
            features: ftCol
        }
        return full;
    }


    getLowestPoints(){
        let lowestLevelContours = this.contouringObject.contourGetLevel(0);
        let lowestLevelBoundingBoxes = this.utilities.getAllBoundingBoxes(lowestLevelContours);

        let lowest_points_arr = [];
        for (var bd in lowestLevelBoundingBoxes) {
            var lowest_point = {'x': lowestLevelBoundingBoxes[bd].min.x, 'y': lowestLevelBoundingBoxes[bd].min.y, 'value': this.dataArray[lowestLevelBoundingBoxes[bd].min.x][lowestLevelBoundingBoxes[bd].min.y]}
            for (var y=lowestLevelBoundingBoxes[bd].min.y; y < lowestLevelBoundingBoxes[bd].max.y; y++) {
                for (var x=lowestLevelBoundingBoxes[bd].min.x; x < lowestLevelBoundingBoxes[bd].max.x; x++) {
                    var cur_value = this.dataArray[x][y];
                    if ((lowest_point.value == null && cur_value != null) || cur_value < lowest_point.value) {
                        lowest_point.id = lowest_points_arr.length
                        lowest_point.x = x
                        lowest_point.y = y
                        lowest_point.value = cur_value
                    }
                }
            }
            lowest_points_arr.push(lowest_point)
        }
        return lowest_points_arr;
    }


    getHighestPoints(){
        let highestLevelContours = this.contouringObject.contourGetLevel(this.contourLevels.nbOfLevel - 1);
        let highestBoundingBoxes = this.utilities.getAllBoundingBoxes(highestLevelContours);

        let highest_points_arr = []
        for (var bd in highestBoundingBoxes) {
            var highest_point = {'x': highestBoundingBoxes[bd].min.x, 'y': highestBoundingBoxes[bd].min.y, 'value':this.dataArray[highestBoundingBoxes[bd].max.x][highestBoundingBoxes[bd].max.y]}
            for (var y=highestBoundingBoxes[bd].min.y; y < highestBoundingBoxes[bd].max.y; y++) {
                for (var x=highestBoundingBoxes[bd].min.x; x < highestBoundingBoxes[bd].max.x; x++) {
                    var cur_value = this.dataArray[x][y]
                    if ((highest_point.value == null && cur_value != null) || cur_value > highest_point.value) {
                        highest_point.id = highest_points_arr.length
                        highest_point.x = x
                        highest_point.y = y
                        highest_point.value = cur_value
                    }
                }
            }
            highest_points_arr.push(highest_point)
        }
        return highest_points_arr;
    }

    _globeScaler = () => {return d3.scaleLinear().domain(this.globe.scaleExtent())};


    addContoursToMap(){
        let rowCords = [...Array(this.metadata.ny).keys()]
        let colCords = [...Array(this.metadata.nx + 1).keys()]
        let levels = this.contourLevels;

        this.contouringObject.contour(this.dataArray, 0, 60, 0, 360, rowCords, colCords, levels.nbOfLevel, levels.levels);
    }
}


export class ContourDrawer{
    contourBuilder = null;
    globe = null;
    mikro = null;

    mainContoursSVG = null;
    contoursDom = null;
    geoGenerator = null;
    currentZoom = null;
    utilities = new ContouringUtils();

    constructor(contourBuilder, globe, mikro){
        this.contourBuilder = contourBuilder;
        this.globe = globe;
        this.mikro = mikro;

        this.mikro.removeChildren(d3.select("#contours").node());

        this.mainContoursSVG = d3.select("#contours");
        this.contoursDom = d3.select('#contours-path');

        this.geoGenerator = geoPath().projection(globe.projection);
        this.currentZoom = globe.orientation().split(',')[2];

        d3.select("#contours").append("path")
        .style("stroke", this.contourBuilder.contouringSettings.color)
        .style("fill", "none")
        .datum(this.contourBuilder.getGeoFeatureCollection(this.longerThan(this.contourBuilder.getContoursList(), 30))) // Change it to scale according to zoom
        .attr("d", this.geoGenerator)
        .attr("id", "contours-path")
        .attr("opacity", "50%");
    }


    _getScaled(callback) {
        return callback(this.currentZoom);
    };


    clearHLMarks(){
        d3.select('.lowest-pointer').remove(); 
        d3.select('.highest-pointer').remove();
    }


	longerThan(dataArray, minLength) {
		let res = []
		for (let list of dataArray) {
			if (list.length > minLength) {
				res.push(list);
			}
		}
		return res;
	}


    createLabelingInfo(singleContour) {
        let contourLabelWidth = this._getScaled(this.contourBuilder.scalers.labelWidth);
        singleContour['label_placement'] = {'slope': 999};

        for (var i = 1; i < singleContour.length; i++) {
            // We need to know, where label will end
            var currentPoint = singleContour[i];
            var labelEndingPoint;
            for (var nextI = i - 1; nextI >= 0; nextI--){
                var distanceToNext = this.utilities.getDistance(currentPoint, singleContour[nextI]);
                if (distanceToNext > contourLabelWidth) {
                    labelEndingPoint = singleContour[nextI];
                    break;
                }
            }
            if(!labelEndingPoint){
                continue;
            }

            singleContour[i]['slope'] = this.utilities.getDeg(currentPoint, labelEndingPoint);

            // Now we check, if we should place label here
            // Initial condition is, that slope is lower
            let curSlope = Math.abs(singleContour[i].slope);
            if (curSlope < Math.abs(singleContour.label_placement.slope)) {
                // TODO Now we need to check also, if some contours are visable from behind

                // Because svg marking, we neet to draw from the lesser x point
                if (currentPoint.x < labelEndingPoint.x) {
                    singleContour.label_placement = currentPoint
                } else {
                    singleContour.label_placement = labelEndingPoint
                    labelEndingPoint['slope'] = this.utilities.getDeg(currentPoint, labelEndingPoint)
                }
            }
            
            if (Math.abs(singleContour[i].slope) < Math.abs(singleContour.label_placement.slope)) {
                singleContour.label_placement = singleContour[i]
            }
        }
    }

    getProjectedContoursWithLabels(){
        let contours = this.longerThan(this.contourBuilder.getContoursList(), 30);
        let listOfContours = this.utilities.toProjectedPointValues(contours, this.globe);

        for (let x of listOfContours) {
            this.createLabelingInfo(x);
            // if (x.label_placement.y === "NaN") {
            //     console.log(x);
            // }
        };
        return listOfContours;
    }

    getVisibility(point){
        const visible = this.geoGenerator({type: 'Point', coordinates: point});
        return visible ? 'visible' : 'hidden';
    }

    distanceToClosest(pointsArray) {
        var minimumDistanceToShow = 25;
        for (var singlePoint of pointsArray) {
            singlePoint.distanceTo = {}
            singlePoint.anyClose = false
            var i = 0;
            for (var measurmentPoint of pointsArray) {
                if (singlePoint !== measurmentPoint) {
                    var dist = this.utilities.getDistance(singlePoint, measurmentPoint);
                    if (dist < minimumDistanceToShow) {
                        var curObj = {'point': measurmentPoint, 'distance': dist}
                        singlePoint.distanceTo[i] = curObj
                        singlePoint.anyClose = true
                    }
                }
                i++;
            }
        }
        return pointsArray;
    }

    getPointsToDraw(pointWithDistance, marker) {
        var ptd = [];
        var idToSkip = []
        for (let pt of pointWithDistance) {
            if (pt.anyClose === false) {
                ptd.push(pt)
            } else {
                if (!(idToSkip.includes(pt.id))) {
                    idToSkip.push(pt.id)
                    var pointToKeep = pt
                    for (var closePoint in pt.distanceTo) {
                        var currentPoint = pt.distanceTo[closePoint].point
                        idToSkip.push(currentPoint.id)
                        if (marker === 'H') {
                            if (currentPoint.value > pt.value) {
                                pointToKeep = currentPoint
                            }
                        } else if (marker === "L"){
                            if (currentPoint.value < pt.value) {
                                pointToKeep = currentPoint
                            }
                        }
                    }
                    ptd.push(pointToKeep)
                }
            }
        }
        return ptd;
    }

    createHLMarker(DOMToAdd, dataPoints, markerSign){
        var calculatedDistance = this.distanceToClosest(dataPoints);
        var pointsToDraw = this.getPointsToDraw(calculatedDistance, markerSign);

        var current_g = DOMToAdd.selectAll('g').data(pointsToDraw).enter().append('g');

        // Appending H or L marker
        current_g.append('text')
        .attr('x', (d) => {return this.globe.projection([d.y, 30 - d.x])[0];})
        .attr('y', (d) => {return this.globe.projection([d.y, 30 - d.x])[1];})
        .text(markerSign)
        .attr("font-family", "sans-serif")
        .attr("font-size", this._getScaled(this.contourBuilder.scalers.markScaler) + "px")
        .attr("fill", "lightgrey")
        .attr('visibility', (d) => {return this.getVisibility([d.y, 30-d.x])})
        .attr("text-align", "center")
        .attr('style', '-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;');


        // Append value under H or L marker
        current_g.append('text')
        .attr('x', (d) => {return this.globe.projection([d.y, 30 - d.x])[0];})
        .attr('y', (d) => {return (this.globe.projection([d.y, 30 - d.x])[1] + this._getScaled(this.contourBuilder.scalers.sepScaler));})
        .text((d) => {
            return d.value != null ? d.value.toFixed(2) : "null";
        })
        .attr("font-family", "sans-serif")
        .attr("font-size", this._getScaled(this.contourBuilder.scalers.valueScaler) + "px")
        .attr("fill", "pink")
        .attr('visibility', (d) => {return this.getVisibility([d.y, 30-d.x])})
        .attr("text-align", "center")
        .attr('style', '-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;');

    }

    addHighLowMarkersToMap(){
        this.clearHLMarks();

        var contoursLowestMarkups = d3.select("#contours").append('g').attr('class', 'lowest-pointer');
        var contoursHighestMarkups = d3.select("#contours").append('g').attr('class', 'highest-pointer');
        this.createHLMarker(contoursLowestMarkups, this.contourBuilder.getLowestPoints(), 'L');
        this.createHLMarker(contoursHighestMarkups, this.contourBuilder.getHighestPoints(), 'H');
    }

    addContourLabels() {
        var contourLabels = d3.select("#contours").append('g').attr('class', 'contour-labels');
        let ctrs = this.getProjectedContoursWithLabels();
        var current_g = contourLabels.selectAll('g').data(ctrs).enter();
        var svgPlus = 130;

        var newSvgg = current_g.append('svg')
            .attr('x', (d) => {
                    return d.label_placement.x - svgPlus;
                })
            .attr('y', (d) => {
                    return d.label_placement.y - (this._getScaled(this.contourBuilder.scalers.labelHeight) * 0.5) - svgPlus;
                });

        newSvgg.append('rect')
            .attr("width", this._getScaled(this.contourBuilder.scalers.labelWidth))
            .attr("height", this._getScaled(this.contourBuilder.scalers.labelHeight))
            .attr("x", svgPlus)
            .attr("y", svgPlus)
            .attr("rx", "3")
            .attr("fill", this.contourBuilder.contouringSettings.color)
            .attr('visibility', (d) => {
                return this.getVisibility([d.label_placement.x_inv, 30-d.label_placement.y_inv])
            })
            .attr("transform", d => "rotate(" + d.label_placement.slope + ")")
            .attr("transform-origin", d => svgPlus + "px " + svgPlus + "px");

        newSvgg.append('text')
            .text((d) => {return Math.floor(d.level);})
            .attr("y", this._getScaled(this.contourBuilder.scalers.labelTextYMove) + svgPlus)
            .attr("x", this._getScaled(this.contourBuilder.scalers.labelTextXMove) + svgPlus)
            .attr("font-family", "sans-serif")
            .attr("font-weight", "bold")
            .attr("font-size", this._getScaled(this.contourBuilder.scalers.labelText) + "px")
            .attr("fill", contrastColor({ bgColor: this.contourBuilder.contouringSettings.color }))
            .attr('visibility', (d) => {
                return this.getVisibility([d.label_placement.x_inv, 30-d.label_placement.y_inv])
            })
            .attr("text-align", "center")
            .attr("transform", d => "rotate(" + d.label_placement.slope + ")")
            .attr("transform-origin", d => svgPlus + "px " + svgPlus + "px")
            .attr('style', '-webkit-touch-callout: none;-webkit-user-select: none;-khtml-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;');

    }

    drawContours(){
        if(Number(this.currentZoom) > 200) {
            this.addHighLowMarkersToMap();
            if(Number(this.currentZoom) > 500) {
                this.addContourLabels();
            }
        }
    }

}