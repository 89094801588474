export class Gradient {
	Width;
	Colors;
	ColorsOffets;
	Pixels;
  
    constructor(stops, name='') {
	  this.Name = name;
	  this.Width = 8192;
	  this.Colors = [];
	  this.ColorsOffets = [];

	  var defaultStep = 1 / (stops.length - 1);
	  var canvas = document.createElement('canvas');

	  canvas.setAttribute('width',  this.Width.toString());
	  canvas.setAttribute('height', "1");
	  var ctx = canvas.getContext('2d');
	  var grd = ctx?.createLinearGradient(0, 0, this.Width, 1);

	  for (var i = 0; i < stops.length; ++i) {
		var parts = stops[i].split(':');
		this.Colors.push(parts[0]);
		parts[1] = parts[1] ? parts[1] : (i * defaultStep).toString();
		this.ColorsOffets.push({ offset: parseFloat(parts[1]), color: this.Colors[i] });
		grd?.addColorStop(parseFloat(parts[1]), parts[0]);
	  }

	  ctx.fillStyle = grd;
	  ctx.fillRect(0, 0, this.Width, 1);
	  this.Pixels = ctx?.getImageData(0, 0, this.Width, 1).data;
	}
	
	getColorBytesAt(value){
		var base = Math.floor(value * (this.Width - 1)) * 4;

		return {
		  r: this.Pixels[base],
		  g: this.Pixels[base + 1],
		  b: this.Pixels[base + 2],
		  a: this.Pixels[base + 3]
		};
	}

	getColorAt(value){
		var bytes = this.getColorBytesAt(value);

		return {
		  r: bytes.r / 255,
		  g: bytes.g / 255,
		  b: bytes.b / 255,
		  a: bytes.a / 255
		};
	}	

	getColorHexAt(value){
		var bytes = this.getColorBytesAt(value);
		var r = bytes.r.toString(16);
		var g = bytes.g.toString(16);
		var b = bytes.b.toString(16);

		r = r.length === 1 ? '0' + r : r;
		g = g.length === 1 ? '0' + g : g;
		b = b.length === 1 ? '0' + b : b;

		return '#' + r + g + b;
	}	

	getColorRGBAStringAt(value){
		var bytes = this.getColorBytesAt(value);

		return 'rgba(' + bytes.r + ',' + bytes.g + ',' + bytes.b + ',' + bytes.a / 255 + ')';
	}	

}

