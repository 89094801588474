import React, { Component } from "react";
import Scale from "../Scale/Scale";
import IconButton from "@spectralweather/common/components/Buttons/IconButton";
import Fade from "@mui/material/Fade";
import { filter } from "rxjs/operators";
import {
  SelectedColorStopSubject,
  ContoursSettingsSubject
} from "@spectralweather/common/services/SubjectsService";
import SpectralWeatherContainer from "@spectralweather/common/components/Containers";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import ScaleSettings from "../ScaleSettings";
import styles from './SideMenuStyles'
import StreamletsSettings from './StreamletsSettings'
import ContoursSettings from './ContoursSettings'

class SideMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      colorStopsVisible: false,
      selectedColorStopColor: {},
    };
  }

  handleCloseCollape() {
    this.setState({ ...this.state, open: false, colorStopsVisible: false });
  }

  handleOpenCollape() {
    this.setState({ ...this.state, open: false });
  }

  toggleColorStops(val) {
    this.setState({ ...this.state, colorStopsVisible: val });
  }

  componentDidMount() {
    this.selectedColorSubscription = SelectedColorStopSubject.asObservable()
      .pipe(filter((val) => val))
      .subscribe((color) => {
        this.setState({ ...this.state, selectedColorStopColor: color });
      });
  }

  componentWillUnmount() {
    // unsubscribe to ensure no memory leaks
    this.selectedColorSubscription.unsubscribe();
  }

  render() {
    return (
      <Box id="SideMenuContainer" sx={this.props.sx}>
        <Box
          sx={{
            backgroundColor: this.state.open
              ? "transparent"
              : "sw.primaryBackground",
            display: "flex",
            alignItems: "center",
            height: this.state.open ? "auto" : "25%",
          }}
        >
          <IconButton
            disableRipple={true}
            onClick={() =>
              this.setState((prevState) => ({
                ...prevState,
                open: !prevState.open,
                colorStopsVisible: false,
              }))
            }
            aria-expanded={this.state.open}
            fontSize={this.state.open ? 35 : "large"}
          >
            arrow_left
          </IconButton>
        </Box>
        <Scale
          selectedStopColor={this.state.selectedColorStopColor}
          colorStopsVisible={this.state.colorStopsVisible}
        />
        <Fade
          in={this.state.open}
          style={{
            display: !this.state.open ? "none" : "block",
            height: "100%",
          }}
        >
          <Box>
            <SpectralWeatherContainer
              height="100%"
              maxWidth="300px"
              minWidth="120px"
              onCloseClick={() => this.handleCloseCollape()}
              id="SpectralWeatherSideMenu"
              style={{ overflowY: "overlay" }}
            >
              <StreamletsSettings />
              <Divider sx={styles.menuDivider} />
              <ContoursSettings />
              <Divider sx={styles.menuDivider} />
              <ScaleSettings
                settingsOpened={this.state.open}
                onColorsStopsVisChange={this.toggleColorStops.bind(this)}
              />
              
            </SpectralWeatherContainer>
          </Box>
        </Fade>
      </Box>
    );
  }
}

export default SideMenu;
