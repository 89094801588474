import React, { useMemo, useState } from 'react';
import { sortPalette } from '../../lib';
import { PALETTE_PROP_TYPES } from '../propTypes';
import ReactTooltip from 'react-tooltip';
import * as d3 from 'd3'

const generateGradientId = () => '' + Math.random().toString(36).substr(2, 9);

const clamp = (x, low, high) => Math.max(low, Math.min(x, high));
    
const spread = (p, low, high) =>  p * (high - low) + low;

const formatScalar = (value, units)  =>   units.conversion(value).toFixed(units.precision);


const Palette = ({ palette, width, height, scaleBounds }) => {
	const sortedPalette = sortPalette(palette);
	const gradientId = useMemo(generateGradientId, [palette.length]);
	const [tooltipText, settooltipText] = useState("Scale not available");
	const setTooltipValue = (event) =>{
		var svgHeight = event.currentTarget.clientHeight;
		var y = d3.pointer(('ontouchstart' in window && event.sourceEvent instanceof TouchEvent) ? event.sourceEvent.touches[0] : event)[1];
		if(y < 0 || y > svgHeight || !scaleBounds || !scaleBounds.unit){
			return;
		}

		var pct = clamp((Math.round(svgHeight-y))/(svgHeight), 0, 1);
		var value = spread(pct, scaleBounds.min, scaleBounds.max);
		var tooltipText = formatScalar(value, scaleBounds.unit) + " " + scaleBounds.unit.label;
		settooltipText(tooltipText);
	};


	return <>
		<div className="palette" style={{ width, height }}>			
			<svg data-tip onMouseMove={setTooltipValue} data-for='svgTooltip' width="100%" height="100%">
				<defs>
					<linearGradient id={gradientId} x1="0.5" y1="1" x2="0.5" y2="0"> {
						sortedPalette.map(({ id, offset, color }) =>
							<stop key={id} offset={offset} style={{ stopColor: color}}/>
						)}
					</linearGradient>
				</defs>
				<rect x="0" y="0" width="100%" height="100%" fill={`url(#${gradientId})`}/>
			</svg>
		</div>
		<ReactTooltip place="left" id='svgTooltip'>{tooltipText}</ReactTooltip>
	</>;
};

Palette.propTypes = PALETTE_PROP_TYPES;

export default Palette;
