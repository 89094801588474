import {Micro} from "@spectralweather/common/utils/Micro"


export class ProductDataBuilder {
    µ = null;
    grid = null;
    ni = 0;
    nj = 0;
    λ0 = 0
    λ1 = 0;
    φ0 = 0;
    φ1 = 0;
    Δλ = 0;
    Δφ = 0;
    product = null;
    productBuilder = null;

    constructor(product, file){
        this.product = product;
        this.µ = this.product.µ || new Micro();
        
        this.productBuilder = product.getBuilder(file)

        var header = this.productBuilder.header;

        this.λ0 = header.lo1;
        this.φ0 = header.la1; 
        this.λ1 = header.lo2
        this.φ1 = header.la2;
        
        this.ni = header.nx;    // number of grid points W-E and N-S (e.g., 144 x 73)
        this.nj = header.ny;    // number of grid points W-E and N-S (e.g., 144 x 73)

        this.Δλ = header.dx;
        this.Δφ = header.dy;    // distance between grid points (e.g., 2.5 deg lon, 2.5 deg lat)

        // Scan mode 0 assumed. Longitude increases from λ0, and latitude decreases from φ0.
        // http://www.nco.ncep.noaa.gov/pmb/docs/grib2/grib2_table3-4.shtml
        this.grid = []
        var p = 0;
        var isContinuous = Math.floor(this.ni * this.Δλ) >= 360;
        for (var j = 0; j < this.nj; j++) {
            var row = [];
            for (var i = 0; i < this.ni; i++, p++) {
                row[i] = this.productBuilder.data(p);
            }
            if (isContinuous) {
                // For wrapped grids, duplicate first column as last column to simplify interpolation logic
                row.push(row[0]);
            }
            this.grid[j] = row;
        }
    }
    
    forEachPoint(cb) {
        for (var j = 0; j < this.nj; j++) {
            var row = this.grid[j] || [];
            for (var i = 0; i < this.ni; i++) {
                cb(this.µ.floorMod(180 + this.λ0 + i * this.Δλ, 360) - 180, this.φ0 - j * this.Δφ, row[i]);
            }
        }
    }
    
    interpolate(λ, φ) {
        if(φ > this.φ0 || φ < this.φ1){
            return null
        }

        var i = this.µ.floorMod(λ - this.λ0, 360) / this.Δλ;  // calculate longitude index in wrapped range [0, 360)
        var j = (this.φ0 - φ) / this.Δφ;                      // calculate latitude index in direction +90 to -90

        //         1      2           After converting λ and φ to fractional grid indexes i and j, we find the
        //        fi  i   ci          four points "G" that enclose point (i, j). These points are at the four
        //         | =1.4 |           corners specified by the floor and ceiling of i and j. For example, given
        //      ---G--|---G--- fj 8   i = 1.4 and j = 8.3, the four surrounding grid points are (1, 8), (2, 8),
        //    j ___|_ .   |           (1, 9) and (2, 9).
        //  =8.3   |      |
        //      ---G------G--- cj 9   Note that for wrapped grids, the first column is duplicated as the last
        //         |      |           column, so the index ci can be used without taking a modulo.

        var fi = Math.floor(i), ci = fi + 1;
        var fj = Math.floor(j), cj = fj + 1;

        var row;
        if ((row = this.grid[fj])) {
            var g00 = row[fi];
            var g10 = row[ci];
            if (this.µ.isValue(g00) && this.µ.isValue(g10) && (row = this.grid[cj])) {
                var g01 = row[fi];
                var g11 = row[ci];
                if (this.µ.isValue(g01) && this.µ.isValue(g11)) {
                    // All four points found, so interpolate the value.
                    return this.productBuilder.interpolate(i - fi, j - fj, g00, g10, g01, g11);
                }
            }
        }
        return null;
    }
}