import * as d3 from "d3";
import _ from "lodash";
import { Micro } from "@spectralweather/common/utils/Micro";
import Backbone from "backbone";
import {geoPath} from "d3-geo";

/**
 * The input controller is an object that translates move operations (drag and/or zoom) into mutations of the
 * current globe's projection, and emits events so other page components can react to these move operations.
 *
 * D3's built-in Zoom behavior is used to bind to the document's drag/zoom events, and the input controller
 * interprets D3's events as move operations on the globe. This method is complicated due to the complex
 * event behavior that occurs during drag and zoom.
 *
 * D3 move operations usually occur as "zoomstart" -> ("zoom")* -> "zoomend" event chain. During "zoom" events
 * the scale and mouse may change, implying a zoom or drag operation accordingly. These operations are quite
 * noisy. What should otherwise be one smooth continuous zoom is usually comprised of several "zoomstart" ->
 * "zoom" -> "zoomend" event chains. A debouncer is used to eliminate the noise by waiting a short period of
 * time to ensure the user has finished the move operation.
 *
 * The "zoom" events may not occur; a simple click operation occurs as: "zoomstart" -> "zoomend". There is
 * additional logic for other corner cases, such as spurious drags which move the globe just a few pixels
 * (most likely unintentional), and the tendency for some touch devices to issue events out of order:
 * "zoom" -> "zoomstart" -> "zoomend".
 *
 * This object emits clean "moveStart" -> ("move")* -> "moveEnd" events for move operations, and "click" events
 * for normal clicks. Spurious moves emit no events.
 */
export class InputController {
  Globe = null;
  Op = null;
  Zoom = null;
  Micro = null;
  Store = null;
  MIN_MOVE = 4; // slack before a drag operation beings (pixels)
  MOVE_END_WAIT = 1000; // time to wait for a move operation to be considered done (millis)
  Dispatch = null;
  signalEndDebounced = null;

  constructor(globe, micro, store) {
    this.Globe = globe;
    this.Micro = micro || new Micro();
    this.Store = store;

    this.Zoom = this.defineZoomBehaviour();

    this.signalEndDebounced = _.debounce(function () {
      if (!this.Op || (this.Op.type !== "drag" && this.Op.type !== "zoom")) {
        this.Store.dispatch({
          type: "globe/setOrientation",
          value: this.Globe.orientation(),
          source: "moveEnd",
        });
      }
    }, this.MOVE_END_WAIT); // wait for a bit to decide if user has stopped moving the globe

    d3.select("#display").call(this.Zoom);

    this.Dispatch = _.extend(
      {
        globe: function (_) {
          if (_) {
            this.Globe = _;
            this.Zoom.scaleExtent(this.Globe.scaleExtent());
            this.reorient();
          }
          return _ ? this : this.Globe;
        }.bind(this),
      },
      Backbone.Events
    );

    this.Store.subscribe(() => {
      var states = this.Store.getState();
      var action = states.lastAction.type;
      if (action === "globe/setOrientation") {
        this.reorient();
      }
      if (action === "globe/setCoordinates") {
        var mark = d3.select(".location-mark");
        if (!mark.node()) {
          mark = d3
            .select("#foreground")
            .append("path")
            .attr("class", "location-mark");
        }
        var path = geoPath().projection(this.Globe.projection).pointRadius(7)
        mark.datum({ type: "Point", coordinates: states.globe.coordinates }).attr("d", path);
      }
      if(action === "utility/setInfoTooltip"){
        if(!states.utility.showInfoTooltip){
            d3.select(".location-mark").remove();
        }
      }
    });
  }
  /**
   * @returns {Object} an object to represent the state for one move operation.
   */
  newOp(startMouse, startScale) {
    return {
      type: "click", // initially assumed to be a click operation
      startMouse: startMouse,
      startScale: startScale,
      manipulator: this.Globe.manipulator(startMouse, startScale),
    };
  }

  defineZoomBehaviour() {
    return d3
      .zoom()
      .on("start", (event) => {
        this.Op =
          this.Op ||
          this.newOp(
            d3.pointer(
              "ontouchstart" in window &&
                event.sourceEvent instanceof TouchEvent
                ? event.sourceEvent.touches[0]
                : event
            ),
            event.transform.k
          ); // a new operation begins
      })
      .on("zoom", (event) => {
        var currentMouse = d3.pointer(
          "ontouchstart" in window && event.sourceEvent instanceof TouchEvent
            ? event.sourceEvent.touches[0]
            : event
        );
        var currentScale = event.transform.k;
        this.Op = this.Op || this.newOp(currentMouse, 1); // Fix bug on some browsers where zoomstart fires out of order.
        if (this.Op.type === "click" || this.Op.type === "spurious") {
          var distanceMoved = this.Micro.distance(
            currentMouse,
            this.Op.startMouse
          );
          if (
            currentScale === this.Op.startScale &&
            distanceMoved < this.MIN_MOVE
          ) {
            // to reduce annoyance, ignore op if mouse has barely moved and no zoom is occurring
            this.Op.type = distanceMoved > 0 ? "click" : "spurious";
            return;
          }
          this.Dispatch.trigger("moveStart");
          this.Op.type = "drag";
        }
        if (currentScale !== this.Op.startScale) {
          this.Op.type = "zoom"; // whenever a scale change is detected, (stickily) switch to a zoom operation
        }

        // when zooming, ignore whatever the mouse is doing--really cleans up behavior on touch devices
        this.Op.manipulator.move(
          this.Op.type === "zoom" ? null : currentMouse,
          currentScale
        );
        this.Dispatch.trigger("move");
      })
      .on("end", (event) => {
        this.Op.manipulator.end();
        if (this.Op.type === "click") {
          this.Dispatch.trigger(
            "click",
            this.Op.startMouse,
            this.Globe.projection.invert(this.Op.startMouse) || []
          );
        } else if (this.Op.type !== "spurious") {
          this.signalEndDebounced();
        }
        this.Op = null; // the drag/zoom/click operation is over
      });
  }

  recalculateScreenDimensions(){
    this.Micro.View = this.Micro.view();
    d3.selectAll(".fill-screen").attr("width", this.Micro.View.width).attr("height", this.Micro.View.height);
  }

  reorient() {
    var options = arguments[3] || {};
    const globe = this.Store.getState().globe;

    if (!this.Globe || options.source === "moveEnd") {
      // reorientation occurred because the user just finished a move operation, so globe is already
      // oriented correctly.
      return;
    }
    this.Dispatch.trigger("moveStart");

    this.Globe.orientation(globe.globeOrientation.value, this.Micro.view());

    //TODO: Creating new zoom all the time is not the best idea :/
    d3.select("#display").call(
      d3.zoom().transform,
      d3.zoomIdentity.scale(this.Globe.projection.scale())
    );

    this.Dispatch.trigger("moveEnd");
  }
}
