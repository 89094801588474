import React, { Component, createRef } from "react";
import Collapse from '@mui/material/Collapse';
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { BaseTextButton } from "@spectralweather/common/components/Buttons/BaseButton/BaseButton";
import { SecondaryText } from "@spectralweather/common/components/Typography/Typography";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { connect } from "react-redux";
import "./Menu.css";
import { withStyles } from '@spectralweather/common/theme/theme'
import DateRow from "./DateRow";
import MenuList from "@mui/material/MenuList";
import VectorFieldsRow from "./VectorFieldsRow";
import ScalarFieldsRow from "./ScalarFieldsRow";
import PressureRow from "./PressureRow/PressureRow";
import ContoursRow from "./ContoursRow/ContoursRow";
import AllDataFieldsRow from "./AllDataFieldsRow/AllDataFieldsRow";
import SpectralWeatherContainer from "@spectralweather/common/components/Containers";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';


import {
  getContoursItems,
  getPressureItems,
  getVectorItems,
  getScalarItems,
  getMenuTypeItems,
  getAllDataItems,
} from "@spectralweather/common/factories/MenuDataFactory";
import Grid from "@mui/material/Grid";
import Draggable from "react-draggable"; // The default
import IconButton, { ToggleIconButton } from "@spectralweather/common/components/Buttons/IconButton/IconButton";

/*
Description of CSS used:
container - used for defining grid
gap-3 - used for definining spacing between items in grid
row - used for defining row in grid. 
col - used for defining column in grid
float-left - align item to left side
rounded-0 - remove rounding from item
shadow-none - remove shadow effect from item
p2 - add padding to item
no-gutters remove spacing between items in grid
*/

const Accordion = withStyles(MuiAccordion, {
  root: {
    boxShadow: "none",
  },
});

const MenuPaper = withStyles(Paper, (theme) => ({
  root: {
    backgroundColor: theme.palette.sw.primaryBackground,
    color: theme.palette.sw.primaryText,
    borderStyle: "solid",
    borderColor: theme.palette.sw.primaryBorder,
    borderWidth: "1px",
    borderRadius: 0,
  },
}));

const OpenMenuButton = withStyles(Button, (theme) => ({
  root: {
    backgroundColor: theme.palette.sw.primaryBackground,
    boxShadow: "none",
    padding: 0,
    borderRadius: 0,
    fontSize: "1.3rem",
    "&:hover": {
      backgroundColor: theme.palette.sw.secondaryBackground,
    },
  },
}));

const MenuButtonGroup = withStyles(ButtonGroup, (theme) => ({
  root: {
    float: "left",
  },
  grouped: {
    borderColor: theme.palette.grey[700] + " !important",
  },
}));

const AccordionSummary = withStyles(MuiAccordionSummary, (theme) => ({
  root: {
    color: theme.palette.sw.primaryText,
    backgroundColor: theme.palette.sw.secondaryBackground,
    minHeight: 46,
  }
}));

const AccordionDetails = withStyles(MuiAccordionDetails, (theme) => ({
  root: {
    color: theme.palette.sw.primaryText,
    display: "block",
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.sw.primaryBackground,
  },
}));

class Menu extends Component {
  constructor(props) {
    super(props);

    this.MenuTypes = getMenuTypeItems();
    this.menuTypeRef = createRef();
    this.state = {
      open: false,
      menuTypePopperOpen: false,
      isSimpleMenuSelected: true,
      draggableMenuPosition: { x: 0, y: 0 },
      topDraggableBound: -props.screenHeight,
      rightDraggableBound: props.screenWidth,
      isDraggingDisabled: true,
    };
  }

  handleMenuItemClick(event, code) {
    if (code === "simple") {
      this.setState({
        open: true,
        menuTypePopperOpen: false,
        isSimpleMenuSelected: true,
      });
    } else {
      this.setState({
        open: true,
        menuTypePopperOpen: false,
        isSimpleMenuSelected: false,
      });
    }
  }

  openHovMoller() {
    var url = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/Hovmoller';
    window.open(url, '_blank').focus();
  }

  handleCloseCollape() {
    this.setState({ open: false, draggableMenuPosition: { x: 0, y: 0 } });
  }

  handleOpenCollape() {
    this.setState({ open: false });
  }

  onControlledDrag(e, position) {
    const { x, y } = position;
    this.setState({
      draggableMenuPosition: { x: x, y: y },
    });
  }

  onDraggingStarted(event, data) {
    var menuElement = document.getElementById("SpectralWeatherMenu");
    var buttonElement = document.getElementById("SpectralWeatherMenuButton");
    var sideMenuElement = document.getElementById("SideMenuContainer");
    var buttonPosition = buttonElement.getBoundingClientRect();
    var sideMenuPosition = sideMenuElement.getBoundingClientRect();
    var heightOffset =
      menuElement.clientHeight -
      this.props.screenHeight +
      (this.props.screenHeight - buttonPosition.top) +
      20; // 20 for margins
    var widthOffset =
      this.props.screenWidth -
      menuElement.clientWidth -
      buttonPosition.left -
      (this.props.screenWidth - sideMenuPosition.left) -
      10; // 10 for margins
    this.setState({
      topDraggableBound: heightOffset,
      rightDraggableBound: widthOffset,
    });
  }

  handleCloseMenuTypePopper(event) {
    if (
      this.menuTypeRef.current &&
      this.menuTypeRef.current.contains(event.target)
    ) {
      return;
    }
    this.setState({ menuTypePopperOpen: false });
  }

  toggleDragging() {
    this.setState((prevState) => ({
      isDraggingDisabled: !prevState.isDraggingDisabled,
    }));
  }

  simpleMenu() {
    return (
      <SpectralWeatherContainer
        additionalIcon={
          <Box>
            <ToggleIconButton
              fontSize={18}
              pressedIcon="pan_tool"
              releasedIcon="pan_tool"
              onClick={this.toggleDragging.bind(this)}
            />
            <IconButton
              sx={{marginLeft: '2px'}}
              fontSize={19}
              onClick={() => window.open('./about', '_blank').focus()}
            >
              help_outline
            </IconButton>
          </Box>

        }
        onCloseClick={() => this.handleCloseCollape()}
      >
        <Grid container rowSpacing={0.5} sx={{ padding: 1 }}>
          <DateRow dateTime={this.props.globalTime} actionType="global" />
          <VectorFieldsRow buttonModels={this.props.VectorItems} />
          <ScalarFieldsRow buttonModels={this.props.ScalarAllDataItems} />
          <PressureRow buttonModels={this.props.GlobalPressureItems} />
          <ContoursRow buttonModels={this.props.ContoursItems} />
        </Grid>
      </SpectralWeatherContainer>
    );
  }

  advancedMenu() {
    return (
      <SpectralWeatherContainer
        additionalIcon={
          <Box>
            <ToggleIconButton
              fontSize={18}
              pressedIcon="pan_tool"
              releasedIcon="pan_tool"
              onClick={this.toggleDragging.bind(this)}
            />
            <IconButton
              sx={{marginLeft: '2px'}}
              fontSize={19}
              onClick={this.toggleDragging.bind(this)}
            >
              help_outline
            </IconButton>
          </Box>
        }
        onCloseClick={() => this.handleCloseCollape()}
      >
        <div>
          <Accordion square>
            <AccordionSummary>
              <Typography>Vectors Data</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <DateRow
                  dateTime={this.props.vectorTime}
                  actionType="vector"
                />
                <VectorFieldsRow buttonModels={this.props.VectorItems} />
                <PressureRow buttonModels={this.props.VectorPressureItems} />
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion square>
            <AccordionSummary>
              <Typography>Scalars Data</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <DateRow
                  dateTime={this.props.scalarTime}
                  actionType="scalar"
                />
                <AllDataFieldsRow
                  buttonModels={this.props.ScalarAllDataItems}
                />
                <PressureRow buttonModels={this.props.ScalarPressureItems} />
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion square>
            <AccordionSummary>
              <Typography>Contours Data</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <DateRow
                  dateTime={this.props.contoursTime}
                  actionType="contours"
                />
                <AllDataFieldsRow
                  buttonModels={this.props.ContoursAllDataItems}
                />
                <PressureRow buttonModels={this.props.ContoursPressureItems} />
                <ContoursRow buttonModels={this.props.ContoursItems} />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      </SpectralWeatherContainer>
    );
  }

  onDraggingStopped(e){
    const { target, type } = e;
    if (type === 'touchend') target.click();
    return true;
  };

  render() {
    return (
      <Grid id="MenuContainer" container sx={this.props.sx}
      >
        <Grid item xs={12}>
          <Draggable
            bounds={{
              left: 0,
              right: this.state.rightDraggableBound,
              bottom: 0,
              top: this.state.topDraggableBound,
            }}
            position={this.state.draggableMenuPosition}
            onDrag={this.onControlledDrag.bind(this)}
            onStart={this.onDraggingStarted.bind(this)}
            onStop={this.onDraggingStopped.bind(this)}
            disabled={this.state.isDraggingDisabled}
          >
            <Collapse in={this.state.open}>
              <Box id="SpectralWeatherMenu" sx={{
                maxWidth: {
                  xs: '100%',
                  sm: '100%',
                  md: '550px'
                }
              }} >
                {this.state.isSimpleMenuSelected
                  ? this.simpleMenu()
                  : this.advancedMenu()}
              </Box>
            </Collapse>
          </Draggable>
        </Grid>
        <Grid item xs={12}>
          <MenuButtonGroup disableRipple={true} variant="contained"
            sx={{
              width: {
                xs: '100%',
                sm: '100%',
                md: 'auto',
                lg: 'auto',
                xl: 'auto'
              }
            }}>
            <OpenMenuButton
              sx={{
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: 120,
                  lg: 120,
                  xl: 120
                }
              }}
              id="SpectralWeatherMenuButton"
              onClick={() =>
                this.setState((prevState) => ({
                  open: !prevState.open,
                  menuTypePopperOpen: false,
                  draggableMenuPosition: { x: 0, y: 0 },
                }))
              }
              aria-expanded={this.state.open}
            >
              Menu
            </OpenMenuButton>
            <OpenMenuButton
              sx={{
                display: {
                  xs: 'none',
                  sm: 'none',
                  md: 'flex',
                  lg: 'flex',
                  xl: 'flex'
                }
              }}
              onClick={() =>
                this.setState((prevState) => ({
                  menuTypePopperOpen: !prevState.menuTypePopperOpen,
                }))
              }
              ref={this.menuTypeRef}
            >
              <Icon>
                {this.state.open ? "expand_more" : "expand_less"}
              </Icon>
            </OpenMenuButton>
            <Popper
              style={{ zIndex: 99999 }}
              placement="top-end"
              open={this.state.menuTypePopperOpen}
              anchorEl={this.menuTypeRef.current}
              disablePortal={true}
            >
              <MenuPaper variant="outlined">
                <ClickAwayListener
                  onClickAway={(event) => this.handleCloseMenuTypePopper(event)}
                >
                  <MenuList sx={{ overflow: "auto" }} id="split-button-menu">
                    <MenuItem onClick={() => this.openHovMoller()}>
                      <SecondaryText>Hovmöller diagram</SecondaryText>
                    </MenuItem>
                    <Divider variant="middle" sx={{ borderColor: (theme) => theme.palette.sw.divider, marginTop: 0, marginBottom: 0 }} />
                    {this.MenuTypes.map((option, index) => (
                      <MenuItem
                        key={option.name}
                        onClick={(event) =>
                          this.handleMenuItemClick(event, option.code)
                        }
                      >
                        <BaseTextButton>{option.name}</BaseTextButton>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </MenuPaper>
            </Popper>
          </MenuButtonGroup>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    globalTime: state.global.globalTime,
    scalarTime: state.scalar.scalarTime,
    vectorTime: state.vector.vectorTime,
    contoursTime: state.contours.contoursTime,
    ScalarAllDataItems: getAllDataItems(state.scalar.scalarData, "scalar/setData"),
    ContoursAllDataItems: getAllDataItems(
      state.contours.contoursData,
      "contours/setData"
    ),
    VectorItems: getVectorItems(state.vector.vectorData),
    ScalarItems: getScalarItems(state.scalar.scalarData),
    GlobalPressureItems: getPressureItems(
      state.global.globalPressure,
      "global/setPressure"
    ),
    VectorPressureItems: getPressureItems(
      state.vector.vectorPressure,
      "vector/setPressure"
    ),
    ScalarPressureItems: getPressureItems(
      state.scalar.scalarPressure,
      "scalar/setPressure"
    ),
    ContoursPressureItems: getPressureItems(
      state.contours.contoursPressure,
      "contours/setPressure"
    ),
    ContoursItems: getContoursItems(state.contours.contoursState),
  };
};

export default connect(mapStateToProps, {})(Menu);
