import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import ColumnLabel from "../ColumnLabel";
import ButtonsContainer from "../ButtonsContainer";

class ContoursRow extends Component {
  render() {
    return (
      <Grid item container>
        <Grid item xs={12} md={2}>
          <ColumnLabel sx={{alignText: {xs:"left", md:"center"}}} menuLabel="Contours" />
        </Grid>
        <Grid item xs={12} md={10}>
          <ButtonsContainer groupContainer={this.props.buttonModels} />
        </Grid>
      </Grid>
    );
  }
}

export default ContoursRow;
