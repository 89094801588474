/*
Used as a high level container for all items in particular menu row

name - name of the container. For example: Scalar, Vector, Pressure
items - group items. For example: Wind, Rainfall
subGroups - name of the sub groups (aggregates). Used to determine data source
*/
export class GroupContainer {
    name = null;
    code = null
    items = null;

    constructor (name, code, items){
        this.name = name;
        this.code = code;
        this.items = items;
    }
}