/**
 * Returns an object that mutates this globe's current projection during a drag/zoom operation.
 * Each drag/zoom event invokes the move() method, and when the move is complete, the end() method
 * is invoked.
 */
export class GlobeManipulator{
    /**
     * globe's current D3 projection.
     */
    projection = null;

    /**
     * starting mouse position.
     */    
    startMouse = null;

    /**
     * starting scale.
     */    
    startScale = null;

    /**
     * mouse sensitivity.
     */      
    sensitivity = null;
    rotation = null;
    original = null;
    
    constructor(projection, startMouse, startScale){
        this.projection = projection;
        this.startMouse = startMouse;
        this.startScale = startScale;
        this.sensitivity = 60 / startScale;  // seems to provide a good drag scaling factor

        this.rotation = [this.projection.rotate()[0] / this.sensitivity, -this.projection.rotate()[1] / this.sensitivity];
        this.original = this.projection.precision();
        this.projection.precision(this.original * 10);
    }

    move(mouse, scale){
        if (mouse) {
            var xd = mouse[0] - this.startMouse[0] + this.rotation[0];
            var yd = mouse[1] - this.startMouse[1] + this.rotation[1];
            this.projection.rotate([xd * this.sensitivity, -yd * this.sensitivity, this.projection.rotate()[2]]);
        }
        this.projection.scale(scale);
    }

    end(){
        this.projection.precision(this.original)
    }
}
    