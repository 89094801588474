import { createTheme, useTheme } from '@mui/material/styles'
import { grey } from '@mui/material/colors';

import { createMakeStyles, createWithStyles } from 'tss-react'
export const makeStyles = createMakeStyles({ useTheme }).makeStyles
export const withStyles = createWithStyles({ useTheme }).withStyles

export const MyTheme = createTheme({
    palette: {
        sw: {
            primaryBackground: grey[900],
            secondaryBackground: grey[800],
            inputBackground: grey[700],

            primaryText: "white",
            secondaryText: grey[600],

            icon: grey.A400,

            primaryBorder: "white",
            divider: grey[600]
        }
    }
});

