import React, { useState, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import store from "../../../store/Store";
import ColumnLabel from "../ColumnLabel";
import IconButton from "@spectralweather/common/components/Buttons/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DatePicker from "react-datepicker";
import { Micro } from "./../../../services/EngineService";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@spectralweather/common/theme/theme";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";

const selectGlobalDate = (state) => state.global.globalTime;
const selectVectorDate = (state) => state.vector.vectorTime;
const selectScalarlDate = (state) => state.scalar.scalarTime;
const selectContourslDate = (state) => state.contours.contoursTime;

function selectDate(actionType) {
  switch (actionType) {
    case "global":
      return selectGlobalDate;
    case "scalar":
      return selectScalarlDate;
    case "vector":
      return selectVectorDate;
    case "contours":
      return selectContourslDate;
    default:
      return selectGlobalDate;
  }
}

function selectDateMin(actionType) {
  switch (actionType) {
    case "global":
    case "vector":
    default:
      return (state) => {
        return state.metadata.dataTypes &&
          state.metadata.dataTypes[state.vector.vectorData]
          ? state.metadata.dataTypes[state.vector.vectorData].min
          : "2010-01-01 00:00:00";
      };
    case "scalar":
      return (state) => {
        return state.metadata.dataTypes &&
          state.metadata.dataTypes[state.scalar.scalarData]
          ? state.metadata.dataTypes[state.scalar.scalarData].min
          : "2010-01-01 00:00:00";
      };
    case "contours":
      return (state) => {
        return state.metadata.dataTypes &&
          state.metadata.dataTypes[state.contours.contoursData]
          ? state.metadata.dataTypes[state.contours.contoursData].min
          : "2010-01-01 00:00:00";
      };
  }
}

function selectDateMax(actionType) {
  switch (actionType) {
    case "global":
    case "vector":
    default:
      return (state) => {
        return state.metadata.dataTypes &&
          state.metadata.dataTypes[state.vector.vectorData]
          ? state.metadata.dataTypes[state.vector.vectorData].max
          : "2030-01-01 00:00:00";
      };
    case "scalar":
      return (state) => {
        return state.metadata.dataTypes &&
          state.metadata.dataTypes[state.scalar.scalarData]
          ? state.metadata.dataTypes[state.scalar.scalarData].max
          : "2030-01-01 00:00:00";
      };
    case "contours":
      return (state) => {
        return state.metadata.dataTypes &&
          state.metadata.dataTypes[state.contours.contoursData]
          ? state.metadata.dataTypes[state.contours.contoursData].max
          : "2030-01-01 00:00:00";
      };
  }
}

function HandleDecrement(actionType, hours) {
  store.dispatch({
    type: actionType + "/decrementDate",
    value: hours,
  });
}
function ChooseDate(actionType, date) {
  store.dispatch({
    type: actionType + "/chooseDate",
    value: date,
  });
}

function HandleIncrement(actionType, hours) {
  store.dispatch({
    type: actionType + "/incrementDate",
    value: hours,
  });
}

export function DecrementByHoursDate(props) {
  const debounceClick = useCallback(
    _.debounce(
      (actionType, decrementBy) => {
        HandleDecrement(actionType, decrementBy);
      },
      1000,
      { leading: true, trailing: false, maxWait: 1000 }
    ),
    []
  );

  return (
    <Tooltip
      enterDelay={750}
      title={<Typography>-{props.DecrementBy}H</Typography>}
      placement="top"
      arrow
    >
      <Box sx={{ display: "inline-flex" }}>
        <IconButton
          fontSize={props.fontSize}
          onClick={() => debounceClick(props.actionType, props.DecrementBy)}
        >
          navigate_before
        </IconButton>
      </Box>
    </Tooltip>
  );
}

export function IncrementByHoursDate(props) {
  const debounceClick = useCallback(
    _.debounce(
      (actionType, incrementBy) => {
        HandleIncrement(actionType, incrementBy);
      },
      1000,
      { leading: true, trailing: false, maxWait: 1000 }
    ),
    []
  );

  return (
    <Tooltip
      enterDelay={750}
      title={<Typography>+{props.IncrementBy}H</Typography>}
      placement="top"
      arrow
    >
      <Box sx={{ display: "inline-flex" }}>
        <IconButton
          fontSize={props.fontSize}
          onClick={() => debounceClick(props.actionType, props.IncrementBy)}
        >
          navigate_next
        </IconButton>
      </Box>
    </Tooltip>
  );
}

const useStyles = makeStyles()((theme) => {
  return {
    calendarWrapper: {
      width: "auto",
    },
  };
});

export function Calendar(props) {
  const { classes } = useStyles();
  const cd = useSelector(selectDate(props.actionType));
  const dateMin = useSelector(selectDateMin(props.actionType));
  const dateMax = useSelector(selectDateMax(props.actionType));
  const [isOpen, setIsOpen] = useState(false);
  const tooltipText =
    dateMin && dateMax ? `Data available from ${dateMin} to ${dateMax}` : "";
  const CalendarIcon = React.forwardRef(({ value, onClick }, ref) => (
    <Tooltip
      enterDelay={750}
      title={<Typography>{tooltipText}</Typography>}
      placement="top"
      arrow
    >
      <Box sx={{ display: "inline-flex" }}>
        <IconButton fontSize={props.fontSize} onClick={onClick} ref={ref}>
          event
        </IconButton>
      </Box>
    </Tooltip>
  ));

  return (
    <DatePicker
      onInputClick={() => setIsOpen(true)}
      onClickOutside={() => setIsOpen(false)}
      open={isOpen}
      wrapperClassName={classes.calendarWrapper}
      selected={cd}
      onChange={(date) => {
        setIsOpen(false);
        ChooseDate(props.actionType, date);
      }}
      timeFormat="HH:mm"
      timeIntervals={360}
      minDate={new Date(dateMin.replace(" ", "T"))}
      maxDate={new Date(dateMax.replace(" ", "T"))}
      dateFormat="MMMM d, yyyy h:mm aa"
      customInput={<CalendarIcon />}
      showMonthDropdown
      showYearDropdown
      scrollableYearDropdown
      dropdownMode="select"
      fixedHeight
    />
  );
}

export default function DateRow(props) {
  return (
    <Grid container>
      <Grid item xs={12} md={2}>
        <ColumnLabel
          sx={{ alignText: { xs: "left", md: "center" } }}
          menuLabel="Time"
        />
      </Grid>
      <Grid item xs={12} md={10}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <Typography component="span" sx={{ width: "auto" }}>
            {Micro.toUTCISO(props.dateTime)}
          </Typography>
          <Box sx={{ paddingLeft: 2, display: "flex" }}>
            <DecrementByHoursDate
              actionType={props.actionType}
              DecrementBy="24"
            >
              -24H
            </DecrementByHoursDate>
            <DecrementByHoursDate
              actionType={props.actionType}
              fontSize={24}
              DecrementBy="6"
            >
              -6H
            </DecrementByHoursDate>
            <Calendar actionType={props.actionType} />
            <IncrementByHoursDate
              actionType={props.actionType}
              fontSize={24}
              IncrementBy="6"
            >
              +6H
            </IncrementByHoursDate>
            <IncrementByHoursDate
              actionType={props.actionType}
              IncrementBy="24"
            >
              +24H
            </IncrementByHoursDate>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
