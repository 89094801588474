export const InitialState = {
    contoursTime: new Date('2019-12-30T00:00:00'),
    contoursData: "wind",
    contoursPressure: "850",
    contoursState: "hide"
}

export default function contoursDataReducer(state = InitialState, action) {
    switch (action.type) {
        case 'contours/setData': {
            return {
                ...state,
                contoursData: action.value
            }
        }
        case 'contours/incrementDate': {
            let timeSpanIncrement = parseInt(action.value) || 8
            const contoursTime = new Date(state.contoursTime);
            contoursTime.setHours(contoursTime.getHours() + timeSpanIncrement);
            return {
                ...state,
                contoursTime: contoursTime
            }
        }
        case 'contours/decrementDate': {
            let timeSpanDecrement = parseInt(action.value) || 8
            const contoursTime = new Date(state.contoursTime);
            contoursTime.setHours(contoursTime.getHours() - timeSpanDecrement);
            return {
                ...state,
                contoursTime: contoursTime
            }
        }
        case 'contours/chooseDate': {
            return {
                ...state,
                contoursTime: new Date(action.value)
            }
        }
        case 'contours/setPressure': {
            return {
                ...state,
                contoursPressure: action.value
            }
        }
        case 'contours/setState': {
            return {
                ...state,
                contoursState: action.value
            }
        }
        default:
            return state
    }
}
