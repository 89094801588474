import {BaseScalarProduct} from "./BaseScalarProduct"

export class BaseDivergenceProduct extends BaseScalarProduct{

    constructor(micro, attr){
        super(micro);
        this.setProductAttributes(attr.date, attr.dataType, attr.level);

        this.type = "divergence";
        this.description = this.localize({
            name: {en: "Divergence"},
        });
        this.endpoint = this.apiEndpoint("scalar", this.productAttributes.level);
        this.units = [
            {label: "1/s", conversion: function(x) { return x ; }, precision: 6},
        ];
    }
}