import React, { createContext } from 'react'
import InfoIcon from './Icons/InfoIcon'
import SuccessIcon from './Icons/SuccessIcon'
import ErrorIcon from './Icons/ErrorIcon'
import CloseIcon from './Icons/CloseIcon'
import { transitions, positions } from 'react-alert'
import {makeStyles} from '@spectralweather/common/theme/theme' 
import Typography from "@mui/material/Typography"

// optional configuration
export const ErrorAlertContext = createContext();
export const ErrorAlertOptions = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '24px',
  transition: transitions.FADE,
  containerStyle: {
      zIndex: 999
    }
};

// optional configuration
export const LocationAlertContext = createContext();
export const LocationAlertOptions = {
  position: positions.TOP_CENTER,
  timeout: 0,
  offset: '24px',
  transition: transitions.FADE,
  containerStyle: {
      zIndex: 999
    }
};

const useStyles = makeStyles()(theme => {
  return ({
    alertContainer: {
      color: theme.palette.sw.primaryText,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '375px',
      boxSizing: 'border-box',
      borderStyle: "solid",
      borderColor: theme.palette.sw.primaryBorder,
      borderWidth: "1px",
      backgroundColor: '#ff0f0f',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2) 
    },
    coordinatesContainer: {
      color: theme.palette.sw.primaryText,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '300px',
      boxSizing: 'border-box',
      borderStyle: "solid",
      borderColor: theme.palette.sw.primaryBorder,
      borderWidth: "1px",
      backgroundColor: theme.palette.sw.primaryBackground,
      padding: theme.spacing(2) 
    },
    messageContainer: {
      whiteSpace: "break-space"
    },
    buttonStyle:{
      marginLeft: '8px',
      border: 'none',
      cursor: 'pointer',
      backgroundColor: 'transparent',
    }
  })
});

const AlertTemplate = ({ message, options, style, close }) => {
  const {classes} = useStyles();
  return (
    <div className={classes.alertContainer} style={{...style }}>
      <Typography sx={{color: 'sw.primaryText'}}>{message}</Typography>
      <button onClick={close} className={classes.buttonStyle}>
        <CloseIcon />
      </button>
    </div>
  )
}

export const ShowCoordinatesTemplate = ({ message, style, close }) => {
  const {classes} = useStyles();
  return (
    <div className={classes.coordinatesContainer} style={{...style }}>
      <div className={classes.messageContainer}>{message}</div>
      <button onClick={close} className={classes.buttonStyle}>
        <CloseIcon />
      </button>
    </div>
  )
}

export default AlertTemplate