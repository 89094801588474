import React from 'react'
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import store from './store/Store';
import AlertTemplate, { ShowCoordinatesTemplate, ErrorAlertContext, ErrorAlertOptions, LocationAlertContext, LocationAlertOptions } from '@spectralweather/common/components/Alerts/AlertTemplate';
import { Provider as AlertProvider } from 'react-alert'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CacheProvider } from "@emotion/react";
import { MyTheme } from "@spectralweather/common/theme/theme"
import createCache from "@emotion/cache"; //Or "@emotion/cache"
// Importing the Bootstrap CSS

export const muiCache = createCache({
    "key": "mui",
    "prepend": true,
});

ReactDOM.render(
    <StyledEngineProvider injectFirst>
        <CacheProvider value={muiCache}>
            <ThemeProvider theme={MyTheme}>
                <AlertProvider template={AlertTemplate} context={ErrorAlertContext} {...ErrorAlertOptions}>
                    <AlertProvider template={ShowCoordinatesTemplate} context={LocationAlertContext} {...LocationAlertOptions}>
                        <Provider store={store}>
                            <App />
                        </Provider>
                    </AlertProvider>
                </AlertProvider>
            </ThemeProvider>
        </CacheProvider>
    </StyledEngineProvider>,
    document.getElementById('root')
);

