import React, { useState, useEffect } from "react";
import BaseButton from "@spectralweather/common/components/Buttons/BaseButton/BaseButton";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import {
  ProductDataSubject,
  CancelSubject,
  ProductDataFieldSubject,
} from "@spectralweather/common/services/SubjectsService";
import SettingsRow from './SettingsRow'

export default function StreamletsSettings() {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [speed, setSpeed] = useState(0);
  const [density, setDensity] = useState(0);
  const [units, setUnits] = useState("");
  const [streamletsSettingsVisible, setStreamletsSettingsVisible] =
    useState(false);

  useEffect(() => {
    var subscription = ProductDataSubject.asObservable().subscribe(
      (productData) => {
        if (productData) {
          var vectorDataBuilder = productData.vectorDataBuilder;
          var scalarDataBuilder = productData.scalarDataBuilder;
          var unit = scalarDataBuilder.product.units[0].label;
          setUnits(unit);
          setMin(scalarDataBuilder.product.scale.bounds[0]);
          setMax(scalarDataBuilder.product.scale.bounds[1]);
          setSpeed(vectorDataBuilder.product.particles.velocityScale);
          setDensity(vectorDataBuilder.product.particles.maxIntensity);
        }
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const toggleStreamletsSettingsVis = () => {
    setStreamletsSettingsVisible(!streamletsSettingsVisible);
  };

  const applySettings = () => {
    if (
      ProductDataSubject.value &&
      ProductDataSubject.value.vectorDataBuilder &&
      ProductDataSubject.value.scalarDataBuilder
    ) {
      ProductDataSubject.value.scalarDataBuilder.product.scale.bounds[0] =
        Number(min);
      ProductDataSubject.value.scalarDataBuilder.product.scale.bounds[1] =
        Number(max);
      ProductDataSubject.value.vectorDataBuilder.product.particles.velocityScale =
        Number(speed);
      ProductDataSubject.value.vectorDataBuilder.product.particles.maxIntensity =
        Number(density);
      CancelSubject.next(true);
      while (ProductDataFieldSubject.value != null) {}
      ProductDataSubject.next(ProductDataSubject.value);
    }
  };
  return (
    <Grid
      container
      rowSpacing={0.5}
      sx={{ paddingTop: 3, paddingX: 2, paddingBottom: 1 }}
    >
      <Grid item xs={12}>
        <BaseButton
          style={{ width: "100%" }}
          onClick={() => toggleStreamletsSettingsVis()}
        >
          STREAMLETS
        </BaseButton>
      </Grid>
      <Fade
        in={streamletsSettingsVisible}
        style={{
          display: streamletsSettingsVisible ? "block" : "none",
        }}
      >
        <Grid item xs={12}>
          <SettingsRow settingsVal={min} menuLabel="Min" units={units} handleChange={(event) => setMin(event.target.value)}  /> 
          <SettingsRow settingsVal={max} menuLabel="Max" units={units} handleChange={(event) => setMax(event.target.value)}  /> 
          <SettingsRow settingsVal={speed} menuLabel="Speed" units={null} handleChange={(event) => setSpeed(event.target.value)}  /> 
          <SettingsRow settingsVal={density} menuLabel="Density" units={null} handleChange={(event) => setDensity(event.target.value)}  /> 
          <Grid item xs={12}>
            <BaseButton style={{ width: "100%" }} onClick={applySettings}>
              APPLY
            </BaseButton>
          </Grid>
        </Grid>
      </Fade>
    </Grid>
  );
}
