import React, { Component } from 'react';
import {feature} from 'topojson'
import * as d3 from 'd3'
import './Globe.css'
import {Micro, getInputController} from '../../services/EngineService'
import {MeshSubject, ReportStatusSubject} from '@spectralweather/common/services/SubjectsService'

class Globe extends Component {

  constructor(props) {
    super(props); 
    this.Micro = Micro;
  }
  
  render() {
    return <svg id="map" className="fill-screen" xmlns="http://www.w3.org/2000/svg" version="1.1"></svg>;
  }

  
  async componentDidMount(){    
    const controller = getInputController();
    controller.recalculateScreenDimensions();
    MeshSubject.next(await this.buildMesh(this.Micro.isMobile() ? "/geo_data/earth-topo-mobile.json?v2" : "/geo_data/earth-topo.json?v2"))
  }


  /**
   * @param resource the GeoJSON resource's URL
   * @returns {Object} a promise for GeoJSON topology features: {boundaryLo:, boundaryHi:}
  */
  buildMesh(resource) {
    ReportStatusSubject.next("Downloading...");
    return this.Micro.loadJson(resource).then(function(topo) {
      Micro.Log.time("building meshes");
      var o = topo.objects;
      var coastLo = feature(topo, this.Micro.isMobile() ? o.coastline_tiny : o.coastline_110m);
      var coastHi = feature(topo, this.Micro.isMobile() ? o.coastline_110m : o.coastline_50m);
      var lakesLo = feature(topo, this.Micro.isMobile() ? o.lakes_tiny : o.lakes_110m);
      var lakesHi = feature(topo, this.Micro.isMobile() ? o.lakes_110m : o.lakes_50m);
      Micro.Log.timeEnd("building meshes");
      return {
        coastLo: coastLo,
        coastHi: coastHi,
        lakesLo: lakesLo,
        lakesHi: lakesHi
      };
    }.bind(this));
  }
}

export default Globe;
