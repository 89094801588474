import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducers/reducers-container";
import ReduxQuerySync from "@spectralweather/common/utils/ReduxQuerySync";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignored because its a datetime and it produces warning because datetime is mutable, while Redux state should contains only immutable state
        ignoredActions: ["vector/chooseDate", "scalar/chooseDate", "contours/chooseDate", "pressure/chooseDate"],
        ignoredPaths: ["vector.vectorTime", "scalar.scalarTime", "contours.contoursTime", "global.globalTime"]
      },
    }),
});

// Sync between URL and Store
ReduxQuerySync({
  store,
  params: {
    vectorData: {
      selector: (state) => state.vector.vectorData,
      action: (value) => ({ type: "vector/setData", value: value }),
      defaultValue: "wind",
    },
    vectorPressure: {
      selector: (state) => state.vector.vectorPressure,
      action: (value) => ({ type: "vector/setPressure", value: value }),
      defaultValue: "850",
    },
    scalarData: {
      selector: (state) => state.scalar.scalarData,
      action: (value) => ({ type: "scalar/setData", value: value }),
      defaultValue: "wind",
    },
    scalarPressure: {
      selector: (state) => state.scalar.scalarPressure,
      action: (value) => ({ type: "scalar/setPressure", value: value }),
      defaultValue: "850",
    },
    contoursData: {
      selector: (state) => state.contours.contoursData,
      action: (value) => ({ type: "contours/setData", value: value }),
      defaultValue: "wind",
    },
    contoursPressure: {
      selector: (state) => state.contours.contoursPressure,
      action: (value) => ({ type: "contours/setPressure", value: value }),
      defaultValue: "850",
    },
    contoursState: {
      selector: (state) => state.contours.contoursState,
      action: (value) => ({ type: "contours/setState", value: value }),
      defaultValue: "hide",
    },
    vectorDate: {
      selector: (state) => state.vector.vectorTime,
      action: (value) => ({ type: "vector/chooseDate", value: value }),
      stringToValue: (string) => new Date(string),
      valueToString: (value) =>
        value.getFullYear() +
        "-" +
        (value.getMonth() + 1) +
        "-" +
        value.getDate() +
        "T" +
        String(value.getHours()).padStart(2, "0") +
        ":00:00",
      defaultValue: new Date("2019-12-30T00:00:00"),
    },
    scalarDate: {
      selector: (state) => state.scalar.scalarTime,
      action: (value) => ({ type: "scalar/chooseDate", value: value }),
      stringToValue: (string) => new Date(string),
      valueToString: (value) =>
        value.getFullYear() +
        "-" +
        (value.getMonth() + 1) +
        "-" +
        value.getDate() +
        "T" +
        String(value.getHours()).padStart(2, "0") +
        ":00:00",
      defaultValue: new Date("2019-12-30T00:00:00"),
    },
    contoursDate: {
      selector: (state) => state.contours.contoursTime,
      action: (value) => ({ type: "pressure/chooseDate", value: value }),
      stringToValue: (string) => new Date(string),
      valueToString: (value) =>
        value.getFullYear() +
        "-" +
        (value.getMonth() + 1) +
        "-" +
        value.getDate() +
        "T" +
        String(value.getHours()).padStart(2, "0") +
        ":00:00",
      defaultValue: new Date("2019-12-30T00:00:00"),
    },
    projection: {
      selector: (state) => state.globe.projection,
      action: (value) => ({ type: "globe/setProjecton", value: value }),
      defaultValue: "orthographic",
    },
    orientation: {
      selector: (state) => state.globe.globeOrientation.value,
      action: (value) => ({
        type: "globe/setOrientation",
        value: value,
        source: "",
      }),
      defaultValue: "",
    },
    coordinates: {
      selector: (state) => state.globe.coordinates,
      action: (value) => ({ type: "globe/setCoordinates", value: value }),
      defaultValue: [],
      stringToValue: (string) => 
      {
        let array = string.split(",");
        return [Number(array[0]), Number(array[1])] 
      },
      valueToString: (array) => array.join(",")
    },
    showInfoTooltip: {
      selector: (state) => state.utility.showInfoTooltip,
      action: (value) => ({
        type: "utility/setInfoTooltip",
        value: value,
      }),
      defaultValue: true,
      stringToValue: (string) => string === 'true',
    },
  },
  initialTruth: "location",
  replaceState: true,
});

export default store;
